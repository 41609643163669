import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';

import organisationsAPI from '@/api/organisations';
import profilesAPI from '@/api/profiles';
import { Alert, ErrorAlert, SuccessAlert } from '@/components/Alert';
import { EditViaCSV } from '@/components/CsvFileUploadSteps/EditViaCSVButton';
import DesignBar from '@/components/DesignBar';
import Filter, {
  DEFAULT_GROUP_OPTION,
  GroupFilterOption,
} from '@/components/Filter';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import InputCheckbox from '@/components/InputCheckbox';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import Pagination from '@/components/Pagination';
import {
  ProfileListRow,
  ProfileListRowMobile,
} from '@/components/ProfileListItem';
import { ProfilesPageFooter } from '@/components/ProfilesListPage/Footer';
import { ProfilesGroupsPseudoTabs, PseudoTabs } from '@/components/PseudoTabs';
import Search from '@/components/Search';
import Sort, { ISortOption } from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import useAppState from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import { IProfileBase } from '@/types/IProfile';

const sortOptions: ISortOption[] = [
  {
    sort: 'date',
    order: 'desc',
    label: 'Newest first',
  },
  {
    sort: 'date',
    order: 'asc',
    label: 'Oldest first',
  },
  {
    sort: 'first_name',
    order: 'asc',
    label: 'Name (A-Z)',
  },
  {
    sort: 'first_name',
    order: 'desc',
    label: 'Name (Z-A)',
  },
];

export default function ProfileListPage() {
  const location = useLocation();
  const { orgID } = useAuth();
  const { selectProfiles: selectProfilesGlobal } = useAppState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [search, setSearch] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [order, setOrder] = useState('desc');
  const [sort, setSort] = useState('date');
  const [selectedProfiles, setSelectedProfiles] = useState(
    new Set<IProfileBase>(),
  );
  const [selectedGroupFilter, setSelectedGroupFilter] =
    useState<GroupFilterOption>(DEFAULT_GROUP_OPTION);

  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const [actionResult, setActionResult] = useState<Alert | undefined>(
    location?.state?.success
      ? {
          outcome: 'success',
          message: location.state.success,
        }
      : undefined,
  );

  const { data: profiles, refetch: refetchProfiles } = useQuery({
    queryKey: [
      'listProfilesInGroup',
      page,
      pageSize,
      search,
      sort,
      order,
      selectedGroupFilter,
    ],
    queryFn: () => listProfilesInGroup({ page, pageSize, search, sort, order }),
    enabled: orgID !== undefined,
  });
  const { data: groups } = useQuery({
    queryKey: ['listGroups', orgID],
    queryFn: listGroups,
    enabled: orgID !== undefined,
  });

  async function listGroups() {
    if (orgID === undefined) {
      return;
    }

    const res = await organisationsAPI.listOrganisationGroups({
      orgID,
      pageSize: 100,
    });

    return res.data;
  }

  async function listProfilesInGroup({
    page,
    pageSize,
    search,
    order,
    sort,
  }: {
    page: number;
    pageSize: number;
    search: string;
    order: string;
    sort: string;
  }) {
    const res = await profilesAPI.listProfilesInGroup({
      orgID,
      page,
      pageSize,
      sort,
      order,
      search,
      groupID: selectedGroupFilter.id,
    });

    return res.data;
  }

  async function checkItem(profile: IProfileBase) {
    let arr = Array.from(selectedProfiles);

    if (selectedProfiles.has(profile)) {
      arr = arr.filter(e => e !== profile);
    } else {
      arr = [...selectedProfiles, profile];
    }

    setSelectedProfiles(new Set(arr));
    selectProfilesGlobal(arr.map(({ id }) => id));
  }

  const groupFilterOptions =
    groups && groups.data
      ? [
          DEFAULT_GROUP_OPTION,
          ...groups.data.map(({ id, name }) => ({
            id,
            name,
          })),
        ]
      : undefined;

  return (
    <Layout pageName="My Profiles" className="bg-gray-50">
      <PseudoTabs tabs={ProfilesGroupsPseudoTabs} activeTab="profiles" />
      <div
        className={clsx('space-y-8 pt-8', {
          'pb-20': selectedProfiles.size > 1,
        })}
      >
        {actionResult &&
          (actionResult.outcome === 'success' ? (
            <SuccessAlert
              message={actionResult.message}
              actionButton={actionResult.action}
            />
          ) : (
            <ErrorAlert
              message={actionResult.message}
              actionButton={actionResult.action}
            />
          ))}
        <div className="flex flex-col xl:flex-row items-end xl:items-center space-y-4 xl:space-y-0 justify-between">
          <DesignBar short />
          <div className="flex flex-wrap items-end space-y-4 xl:space-y-0 justify-end gap-3 w-full">
            <Search
              id={`profile-list-search-${search}-${page}-${pageSize}-${order}`}
              search={search}
              setSearch={setSearch}
              fetchQuery={searchQuery =>
                listProfilesInGroup({
                  page,
                  pageSize,
                  search: searchQuery,
                  sort,
                  order,
                })
              }
            />
            {groupFilterOptions ? (
              <Filter
                id="profile-page-filter"
                filter="groups"
                selected={selectedGroupFilter}
                setSelected={setSelectedGroupFilter}
                data={groupFilterOptions}
              />
            ) : (
              <Skeleton width={130} height={38} />
            )}
            <Sort
              id={`profile-page-sort-${page}-${pageSize}-${search}-${order}-${sort}`}
              sort={sort}
              setSort={setSort}
              order={order}
              setOrder={setOrder}
              options={sortOptions}
              fetchQuery={(sortQuery, orderQuery) =>
                listProfilesInGroup({
                  page,
                  pageSize,
                  search,
                  sort: sortQuery,
                  order: orderQuery,
                })
              }
            />
            <div className="hidden xl:block">
              <EditViaCSV />
            </div>
          </div>
        </div>
        {profiles ? (
          profiles.data.length > 0 ? (
            <>
              <div className="hidden xl:block relative border border-solid rounded-lg">
                <table
                  className={clsx('w-full max-w-full table-fixed', {
                    'opacity-40': isPaginationLoading,
                  })}
                >
                  <thead className="bg-gray-100 border-b border-gray-200">
                    <tr className="uppercase text-gray-900">
                      <th scope="col" className="py-3 px-4 w-[5%]">
                        <InputCheckbox
                          id="select-all-profiles-page"
                          value={selectAll}
                          // label has a margin -_-, which causes problems when there is no label
                          labelClassName=""
                          onChange={value => {
                            setSelectAll(value);

                            if (value) {
                              const arr = profiles.data.map(({ id }) => id);
                              setSelectedProfiles(new Set(profiles.data));
                              selectProfilesGlobal(arr);
                            } else {
                              setSelectedProfiles(new Set());
                              selectProfilesGlobal([]);
                            }
                          }}
                        />
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-left text-sm w-1/4"
                      >
                        Name &amp; profile id
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-left w-1/5 text-sm"
                      >
                        Contact information
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-left w-[15%] text-sm"
                      >
                        Group
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-sm w-[11%]"
                      >
                        App
                      </th>
                      <th scope="col" className="font-medium p-3 text-sm">
                        Edit
                      </th>
                      <th scope="col" className="font-medium p-3 text-sm">
                        View
                      </th>
                      <th scope="col" className="font-medium py-3 px-4 text-sm">
                        Other
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {profiles.data.map((profile, index) => (
                      <ProfileListRow
                        key={index}
                        profile={profile}
                        setActionResult={setActionResult}
                        selected={selectedProfiles.has(profile)}
                        checkRow={() => checkItem(profile)}
                        refreshProfiles={refetchProfiles}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="block xl:hidden space-y-4">
                <div className="bg-gray-100 flex xl:hidden flex-row border border-gray-300 rounded-md divide-x divide-gray-300">
                  <div className="pl-6 md:px-6 py-4">
                    <InputCheckbox
                      id="select-all"
                      value={selectAll}
                      onChange={value => {
                        setSelectAll(value);
                        if (value) {
                          const arr = profiles.data.map(({ id }) => id);
                          setSelectedProfiles(new Set(profiles.data));
                          selectProfilesGlobal(arr);
                        } else {
                          setSelectedProfiles(new Set());
                          selectProfilesGlobal([]);
                        }
                      }}
                    />
                  </div>
                  <div className="font-medium pl-4 md:px-6 py-4 uppercase">
                    Name, profile id, app access, contact information &amp;
                    group
                  </div>
                </div>
                {profiles.data.map((profile, index) => (
                  <ProfileListRowMobile
                    key={index}
                    profile={profile}
                    setActionResult={setActionResult}
                    selected={selectedProfiles.has(profile)}
                    checkRow={() => checkItem(profile)}
                    refreshProfiles={refetchProfiles}
                  />
                ))}
              </div>
              {isPaginationLoading && (
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                </div>
              )}
            </>
          ) : (
            <div className="py-32">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.profile.list.empty.heading}
              </h3>
              <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.profile.list.empty.description}
              </p>
            </div>
          )
        ) : (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
          </div>
        )}
        <Pagination
          id={`profiles-list-${page}-${pageSize}-${search}-${selectedGroupFilter.id}`}
          className="bg-gray-50"
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setIsLoading={setIsPaginationLoading}
          fetchQuery={(pageQuery, pageSizeQuery) =>
            listProfilesInGroup({
              page: pageQuery,
              pageSize: pageSizeQuery,
              search,
              sort,
              order,
            })
          }
        />
        <InfoPanelFooter
          className={selectedProfiles.size > 1 ? 'pl-4 pr-8' : '!-bottom-56'}
        >
          <ProfilesPageFooter
            selectedProfiles={Array.from(selectedProfiles)}
            setActionResult={setActionResult}
            refreshProfiles={refetchProfiles}
            onActionSuccess={() => {
              setSelectedProfiles(new Set());
              selectProfilesGlobal([]);
              if (selectAll) {
                setSelectAll(false);
              }
            }}
          />
        </InfoPanelFooter>
      </div>
    </Layout>
  );
}
