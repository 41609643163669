import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';

import profilesAPI from '@/api/profiles';
import { EditProfileForm } from '@/components/EditProfilePage/Form';
import {
  WorkingProfileForm,
  WorkingProfileFormSchema,
} from '@/components/EditProfilePage/schema';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import COLORS from '@/constants/colors';
import useAuth from '@/hooks/useAuth';

export default function EditProfilePage() {
  const location = useLocation();
  const [, , profileID] = location.pathname.split('/');

  const { orgID } = useAuth();

  const { data: profile } = useQuery({
    queryKey: ['showProfile', profileID],
    queryFn: showProfile,
    enabled: orgID !== undefined,
    refetchOnWindowFocus: false,
  });

  const methods = useForm<WorkingProfileForm>({
    resolver: zodResolver(WorkingProfileFormSchema),
  });

  useEffect(() => {
    if (profile) {
      methods.reset({
        city: profile.city,
        company_name: profile.company_name,
        company_phone_number: profile.company_phone_number,
        company_website: profile.company_website,
        country: profile.country,
        contact_exchange_enabled: profile.contact_exchange_enabled,
        email: profile.email,
        extra_emails: profile.extra_emails,
        extra_mobile_numbers: profile.extra_mobile_numbers,
        extra_company_phone_numbers: profile.extra_company_phone_numbers,
        first_name: profile.first_name,
        job_title: profile.job_title,
        last_name: profile.last_name,
        middle_name: profile.middle_name,
        mobile_number: profile.mobile_number,
        note: profile.note,
        photo: profile.photo,
        post_code: profile.post_code,
        profile_file_links: profile.profile_file_links.map(link => ({
          status: 'complete',
          ...link,
        })),
        profile_quick_links: profile.profile_quick_links,
        profile_social_links: profile.profile_social_links,
        pronouns: profile.pronouns,
        settings: profile.settings ?? {
          logo: null,
          logo_file_id: null,
          bg_color: COLORS.organisation.defaultProfile.backgroundColor,
          text_color: COLORS.organisation.defaultProfile.textColor,
          button_bg_color:
            COLORS.organisation.defaultProfile.buttonBackgroundColor,
          button_text_color: COLORS.organisation.defaultProfile.buttonTextColor,
        },
        settings_level: profile.settings_level,
        state: profile.state,
        street_address: profile.street_address,
      });
    }
  }, [profile]);

  async function showProfile() {
    if (orgID === undefined) {
      return;
    }

    const { data: profile } = await profilesAPI.showProfile(
      orgID,
      parseInt(profileID),
    );

    return profile.data;
  }

  if (profile === undefined) {
    return (
      <Layout pageName="Edit Profile">
        <div className="flex items-center justify-center h-full">
          <LoadingAnimation className="w-16 h-16 mx-auto" />
        </div>
      </Layout>
    );
  }

  return (
    <FormProvider {...methods}>
      <Layout pageName="Edit Profile" className="bg-gray-50">
        <EditProfileForm />
      </Layout>
    </FormProvider>
  );
}
