import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import Cookies from 'js-cookie';

import accountsAPI from '@/api/accounts';
import analyticsAPI from '@/api/analytics';
import { IPlatformSubscriptionFull } from '@/types/IOrganisation';
import IUser from '@/types/IUser';
import SubscriptionStatus from '@/types/SubscriptionStatus';

interface ILogin {
  email: string;
  password: string;
  rememberMe?: boolean;
  activationKey?: string;
  activationKeyFromLogin?: string;
}
interface AuthContextType {
  user?: IUser;
  userScope?: string;
  userRole?: string;
  orgID?: number;
  isUserViewForAdmin?: boolean;
  loading: boolean;
  authError?: boolean;
  platformSubscriptionStatus: string;
  platformSubscription?: IPlatformSubscriptionFull | null;
  totalProfiles: number;
  features?: string[];
  adminRole?: string | null;
  getCurrentUser: () => void;
  refreshCurrentUser: () => Promise<void>;
  setScopeAndOrg: (
    scope?: string,
    ID?: number,
    userViewForAdmin?: boolean,
    userRole?: string,
    features?: string[],
    adminRole?: string | null,
    platformSubscriptionStatus?: string,
  ) => void;
  login: ({
    email,
    password,
    rememberMe,
    activationKeyFromLogin,
  }: ILogin) => void;
  signUp: (
    email: string,
    firstName: string,
    lastName: string,
    organisationName: string,
    password: string,
    passwordConfirmation: string,
    termsAccepted: boolean,
    activationKey?: string,
  ) => void;
  verifyMfaCode: (mfa_token: string, code: string) => void;
  logout: () => void;
  hasFeature: (feature: string) => boolean;
  setAuthError: (error: boolean) => void;
  shopifyLogin: ({ email, password, rememberMe }: ILogin) => void;
  redirectToShopify: () => void;
  ssoLogin: (token: string) => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const {
    update: intercomUpdate,
    shutdown: intercomShutdown,
    boot: intercomBoot,
  } = useIntercom();

  const [user, setUser] = useState<IUser>();
  const [userScope, setUserScope] = useState<string>();
  const [userRole, setUserRole] = useState<string>();
  const [platformSubscriptionStatus, setPlatformSubscriptionStatus] =
    useState<string>(SubscriptionStatus.FREE);
  const [platformSubscription, setPlatformSubscription] =
    useState<IPlatformSubscriptionFull | null>();
  const [totalProfiles, setTotalProfiles] = useState<number>(0);

  const [features, setFeatures] = useState<string[]>([]);
  const [adminRole, setAdminRole] = useState<string | null>();
  const [orgID, setOrgID] = useState<number>();
  const [isUserViewForAdmin, setIsUserViewForAdmin] = useState<boolean>();
  const [authError, setAuthError] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  const setScopeAndOrg = (
    scope?: string,
    ID?: number,
    userViewForAdmin?: boolean,
    userRole?: string,
    features?: string[],
    adminRole?: string | null,
    platformSubscriptionStatus?: string,
  ) => {
    setUserScope(scope);
    setAdminRole(adminRole);
    setOrgID(ID);
    setIsUserViewForAdmin(userViewForAdmin);
    setUserRole(userRole);
    setFeatures(features ?? []);

    const status = platformSubscriptionStatus ?? SubscriptionStatus.FREE;
    setPlatformSubscriptionStatus(status);

    if (status === SubscriptionStatus.ACTIVE) {
      getPlatformSubscription(ID);
    }
  };

  const refreshCurrentUser = () => {
    setLoading(true);

    return accountsAPI
      .getCurrentUser()
      .then(res => res.data.data)
      .then(data => {
        setUser(data);
        setScopeAndOrg(
          data.scope,
          data.organisation_id,
          false,
          data.organisation_role,
          data.features,
          data.role,
          data.platform_subscription_status,
        );
      })
      .finally(() => setLoading(false));
  };

  const getCurrentUser = () => {
    setLoading(true);

    return accountsAPI
      .getCurrentUser()
      .then(res => {
        trackLoggedInUserWithIntercom(res.data.data);
        return res.data.data;
      })
      .then(data => {
        setUser(data);
        if (data.organisation_id !== null)
          analyticsAPI.recordLogin(data.organisation_id);
        setScopeAndOrg(
          data.scope,
          data.organisation_id,
          false,
          data.organisation_role,
          data.features,
          data.role,
          data.platform_subscription_status,
        );
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (loadingInitial) {
      getCurrentUser().finally(() => setLoadingInitial(false));
    }
  }, [loadingInitial]);

  const trackLoggedInUserWithIntercom = (loggedInUser: IUser) => {
    const name = `${loggedInUser.first_name} ${loggedInUser.last_name}`.trim();
    const insertedAt = new Date(loggedInUser.inserted_at);

    intercomUpdate({
      email: loggedInUser.email,
      name,
      createdAt: Math.floor(insertedAt.getTime() / 1000),
      userHash: loggedInUser.user_hash,
    });
  };

  const login = useCallback(
    ({
      email,
      password,
      rememberMe,
      activationKey,
      activationKeyFromLogin,
    }: ILogin) => {
      setLoading(true);
      accountsAPI
        .login({ email, password })
        .then(res => {
          setAuthError(false);
          const { authentication_type, access_token, refresh_token } =
            res.data.data;

          if (authentication_type === 'password') {
            localStorage.setItem('access_token', access_token);
            if (rememberMe && refresh_token) {
              localStorage.setItem('refresh_token', refresh_token);
            }

            const shopDomain =
              (import.meta.env.VITE_SHOP_DOMAIN as string) || 'tapt.test';
            const env = (import.meta.env.VITE_SHOP_ENV as string) || 'local';
            Cookies.set(`${env}_access_token`, access_token, {
              domain: shopDomain,
            });
            if (refresh_token) {
              Cookies.set(`${env}_refresh_token`, refresh_token, {
                domain: shopDomain,
              });
            }
          }

          if (authentication_type === 'mfa_email') {
            localStorage.setItem('mfa_email', email);
            localStorage.setItem('mfa_token', access_token);
            if (activationKeyFromLogin) {
              localStorage.setItem(
                'mfa_activation_key_from_login',
                activationKeyFromLogin,
              );
            }
            if (rememberMe) {
              localStorage.setItem('mfa_remember_me', rememberMe.toString());
            }
          }

          return authentication_type;
        })
        .then(authentication_type => {
          if (authentication_type === 'password') {
            getCurrentUser().then(() => {
              navigate('/', {
                state: {
                  activationKey,
                  activationKeyFromLogin,
                },
              });
            });
          }

          if (authentication_type === 'mfa_email') {
            navigate('/verify-mfa');
          }
        })
        .catch(e => {
          setAuthError(true);
        })
        .finally(() => setLoading(false));
    },
    [history, getCurrentUser],
  );

  const verifyMfaCode = useCallback(
    (mfa_token: string, otp_code: string) => {
      setLoading(true);

      accountsAPI
        .verifyMfaToken(mfa_token, otp_code)
        .then(res => {
          const { access_token, refresh_token } = res.data.data;
          localStorage.setItem('access_token', access_token);
          localStorage.removeItem('mfa_token');
          localStorage.removeItem('mfa_email');

          const shopDomain =
            (import.meta.env.VITE_SHOP_DOMAIN as string) || 'tapt.test';
          const env = (import.meta.env.VITE_SHOP_ENV as string) || 'test';
          Cookies.set(`${env}_access_token`, access_token, {
            domain: shopDomain,
          });
          if (refresh_token) {
            Cookies.set(`${env}_refresh_token`, refresh_token, {
              domain: shopDomain,
            });
          }

          if (localStorage.getItem('mfa_remember_me')) {
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.removeItem('mfa_remember_me');
          }
        })
        .then(() => getCurrentUser())
        .catch(() => setAuthError(true))
        .finally(() => setLoading(false));
    },
    [history, getCurrentUser],
  );

  const signUp = useCallback(
    (
      email: string,
      firstName: string,
      lastName: string,
      organisationName: string,
      password: string,
      passwordConfirmation: string,
      termsAccepted: boolean,
      activationKey?: string,
    ) => {
      setLoading(true);

      accountsAPI
        .signup({
          email,
          first_name: firstName,
          last_name: lastName,
          organisation_name: organisationName,
          password,
          password_confirmation: passwordConfirmation,
          terms_accepted: termsAccepted,
        })
        .then(() => {
          login({ email, password, activationKey });
        })
        .catch(() => setAuthError(true))
        .finally(() => setLoading(false));
    },
    [login],
  );

  function logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setUser(undefined);
    setOrgID(undefined);
    intercomShutdown();
    intercomBoot();
  }

  function hasFeature(feature: string): boolean {
    return features.includes(feature);
  }

  const redirectToShopify = () => {
    accountsAPI.getShopifySsoLink().then(res => {
      const ssoRedirect = encodeURIComponent(
        '?redirect_endpoint=/collections/all-products',
      );
      window.location.href = `${res.data.data.url}${ssoRedirect}`;
    });
  };

  const shopifyLogin = useCallback(
    ({ email, password, rememberMe }: ILogin) => {
      accountsAPI
        .login({ email, password })
        .then(res => {
          setAuthError(false);
          const { authentication_type, access_token, refresh_token } =
            res.data.data;
          if (authentication_type === 'password') {
            localStorage.setItem('access_token', access_token);
            if (rememberMe && refresh_token) {
              localStorage.setItem('refresh_token', refresh_token);
            }
            const shopDomain =
              (import.meta.env.VITE_SHOP_DOMAIN as string) || 'tapt.test';
            const env = (import.meta.env.VITE_SHOP_ENV as string) || 'local';
            Cookies.set(`${env}_access_token`, access_token, {
              domain: shopDomain,
            });
            if (refresh_token) {
              Cookies.set(`${env}_refresh_token`, refresh_token, {
                domain: shopDomain,
              });
            }
          }

          if (authentication_type === 'mfa_email') {
            localStorage.setItem('mfa_email', email);
            localStorage.setItem('mfa_token', access_token);
            if (rememberMe) {
              localStorage.setItem('mfa_remember_me', rememberMe.toString());
            }
          }

          return authentication_type;
        })
        .then(authentication_type => {
          if (authentication_type === 'password') {
            getCurrentUser().then(() => redirectToShopify());
          }

          if (authentication_type === 'mfa_email') {
            navigate('/shopify/verify-mfa');
          }
        })
        .catch(e => {
          setAuthError(true);
        })
        .finally(() => setLoading(false));
    },
    [history, getCurrentUser],
  );

  const ssoLogin = useCallback(
    (access_token: string) => {
      localStorage.setItem('access_token', access_token);
      const env = (import.meta.env.VITE_SHOP_ENV as string) || 'test';
      const shopDomain =
        (import.meta.env.VITE_SHOP_DOMAIN as string) || 'tapt.test';
      Cookies.set(`${env}_access_token`, access_token, {
        domain: shopDomain,
      });

      getCurrentUser().then(() => {
        navigate('/');
      });
    },
    [history, getCurrentUser],
  );

  const getPlatformSubscription = async (orgID?: number) => {
    if (!orgID) return;

    try {
      const res = await accountsAPI.getSubscription(orgID);
      setPlatformSubscription(res.data.subscription);
      setTotalProfiles(res.data.total_profiles);
    } catch (error) {
      setPlatformSubscription(null);
      setTotalProfiles(0);
    }
  };

  const memoedValue = useMemo(
    () => ({
      user,
      userScope,
      userRole,
      orgID,
      isUserViewForAdmin,
      loading,
      authError,
      platformSubscriptionStatus,
      platformSubscription,
      totalProfiles,
      features,
      adminRole,
      getCurrentUser,
      setScopeAndOrg,
      refreshCurrentUser,
      login,
      verifyMfaCode,
      signUp,
      logout,
      hasFeature,
      setAuthError,
      shopifyLogin,
      redirectToShopify,
      ssoLogin,
    }),
    [
      getCurrentUser,
      setScopeAndOrg,
      refreshCurrentUser,
      login,
      logout,
      signUp,
      verifyMfaCode,
      hasFeature,
      setAuthError,
      shopifyLogin,
      redirectToShopify,
      ssoLogin,
      user,
      userScope,
      userRole,
      orgID,
      isUserViewForAdmin,
      loading,
      authError,
      platformSubscriptionStatus,
      platformSubscription,
      totalProfiles,
      features,
      adminRole,
    ],
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
