import { Fragment, Ref } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { GlobeAltIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/20/solid';
import {
  ArrowsRightLeftIcon,
  ArrowDownTrayIcon as DownloadIcon,
  ArrowTopRightOnSquareIcon as ExternalLinkIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useWindowSize } from 'usehooks-ts';

import DefaultProfilePicture from '@/assets/images/DefaultProfilePicture.png';
import { ReactComponent as DefaultHeaderImage } from '@/assets/svg/ProfilePageDefaultHeaderImage.svg';
import { isFileLink } from '@/helpers/file';
import {
  formatPhoneNumber,
  getFullCompanyAddress,
  getFullName,
  hexToRgba,
} from '@/helpers/strings';
import IFile from '@/types/IFile';
import {
  IProfileFileLink,
  IProfileQuickLink,
  IProfileSocialLink,
} from '@/types/IProfile';

import { ProgressiveImage } from './Image';
import { mockProfileData } from './ProfilePage/data';
import { ProfileSocialLinks } from './SocialLinks';

type ProfileContent = {
  first_name?: string | null;
  middle_name?: string | null;
  last_name?: string | null;
  pronouns?: string | null;
  email?: string | null;
  mobile_number?: string | null;
  job_title?: string | null;
  company_name?: string | null;
  company_website?: string | null;
  company_phone_number?: string | null;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  post_code?: string | null;
  profile_social_links?: IProfileSocialLink[];
  profile_file_links?: IProfileFileLink[];
  profile_quick_links?: IProfileQuickLink[];
  photo?: IFile | null;
  note?: string | null;
};

type ProfilePreviewModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  settings: {
    bgColor: string;
    textColor: string;
    buttonBgColor: string;
    buttonTextColor: string;
    companyLogo: IFile | null;
  };
  content?: ProfileContent;
};

export function ProfilePreviewModal({
  isOpen,
  setIsOpen,
  settings,
  content,
}: ProfilePreviewModalProps) {
  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Transition appear show={isOpen}>
        <div
          className="flex flex-col space-y-2 items-center justify-center"
          style={{
            height: '100dvh',
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-in duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />
          </Transition.Child>

          <div className="w-[320px] md:w-[356px] flex justify-end">
            <button
              type="button"
              className="rounded-full bg-white p-1 w-min self-end z-10"
              onClick={() => setIsOpen(false)}
            >
              <XMarkIcon className="w-8 h-8 text-gray-500" />
            </button>
          </div>
          <Transition.Child
            as={Fragment}
            enter="ease-in duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-out duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <ProfilePreview settings={settings} content={content} />
          </Transition.Child>
        </div>
      </Transition>
    </Dialog>
  );
}

type ProfilePreviewProps = {
  settings: {
    bgColor: string;
    textColor: string;
    buttonBgColor: string;
    buttonTextColor: string;
    companyLogo: IFile | null;
  };
  content?: ProfileContent;
  ref?: Ref<HTMLDivElement>;
};

export function ProfilePreview({
  settings,
  content,
  ref,
}: ProfilePreviewProps) {
  const { height } = useWindowSize();

  const { bgColor, textColor, buttonBgColor, buttonTextColor, companyLogo } =
    settings;
  const {
    first_name: firstName,
    last_name: lastName,
    job_title: jobTitle,
    mobile_number: mobileNumber,
    middle_name: middleName,
    email,
    profile_social_links: profileSocialLinks,
    profile_file_links: profileFileLinks,
    profile_quick_links: profileQuickLinks,
    company_name: companyName,
    company_phone_number: companyPhoneNumber,
    company_website: companyWebsite,
    street_address: streetAddress,
    city,
    state,
    country,
    post_code: postCode,
    photo,
    note,
  } = content ? content : mockProfileData;

  const fullName = getFullName({ firstName, middleName, lastName });
  const formattedPhoneNumber = formatPhoneNumber(mobileNumber);
  const links = [
    ...(profileFileLinks || []),
    ...(profileQuickLinks || []),
  ].sort((a, b) => a.order - b.order);
  const sortedSocialLinks = [...(profileSocialLinks || [])].sort(
    (a, b) => a.order - b.order,
  );

  const hasSocials = sortedSocialLinks.length > 0;
  const hasLinks = links.length > 0;
  const hasCompanyPhoneNumber = !!companyPhoneNumber;
  const hasCompanyWebsite = !!companyWebsite;
  const hasCompanyAddress =
    !!streetAddress || !!city || !!state || !!postCode || !!country;
  const hasCompanyInformation =
    hasCompanyPhoneNumber || hasCompanyWebsite || hasCompanyAddress;

  return (
    <div
      ref={ref}
      className="transition-all text-2xl transform shadow-xl rounded-4xl text-left overflow-scroll hide-scrollbar w-full max-w-[320px] md:max-w-[356px] max-h-[625px] md:max-h-[695px]"
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div className="flex-1 pb-4">
        <div
          className="w-full absolute max-h-[250px] overflow-hidden"
          style={{ height: height * 0.45 }}
        >
          {companyLogo ? (
            <img
              src={companyLogo.small_url}
              className="w-full h-full"
              alt="Cover image"
            />
          ) : (
            <div>
              <DefaultHeaderImage className="w-full h-full" />
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <div
            className="w-full max-h-[250px]"
            style={{ height: height * 0.45 }}
          />
          <div
            className="rounded-tr-4xl p-4 -mt-8 relative"
            style={{ color: textColor, backgroundColor: bgColor }}
          >
            <div className="-mt-16">
              <div
                className="rounded-full w-[88px] h-[88px] flex items-center justify-center overflow-hidden relative"
                style={{
                  backgroundColor: bgColor,
                }}
              >
                {photo ? (
                  <ProgressiveImage
                    source={photo.thumb_url}
                    blurHash={photo.blur_hash}
                    alt="Profile photo"
                  />
                ) : (
                  <img src={DefaultProfilePicture} alt="Profile photo" />
                )}
              </div>
              {fullName && (
                <div className="font-semibold text-2xl pt-2 truncate">
                  {fullName}
                </div>
              )}
              {jobTitle && (
                <div
                  className="text-base text-opacity-80"
                  style={{ color: hexToRgba(textColor, 0.8) }}
                >
                  {jobTitle}
                </div>
              )}
              <div className="h-[8px]" />
              {formattedPhoneNumber && (
                <div className="font-medium text-base">
                  {formattedPhoneNumber}
                </div>
              )}
              <div className="space-y-6">
                {email && (
                  <div className="font-medium text-base truncate">{email}</div>
                )}
                {hasSocials && (
                  <ProfileSocialLinks
                    links={sortedSocialLinks}
                    textColor={textColor}
                  />
                )}
                {hasLinks && (
                  <div className="space-y-3">
                    <span className="text-lg font-medium">Links</span>
                    {links.map((link, index) => {
                      const { title } = link;

                      const Icon = isFileLink(link)
                        ? DownloadIcon
                        : ExternalLinkIcon;

                      return (
                        <div
                          key={index}
                          className="border rounded-full flex items-center justify-between text-sm px-5 py-3"
                          style={{
                            borderColor: hexToRgba(textColor, 0.5),
                            color: textColor,
                          }}
                        >
                          <span className="w-5/6 font-medium truncate">
                            {title}
                          </span>
                          <Icon width={20} height={20} />
                        </div>
                      );
                    })}
                  </div>
                )}
                {hasCompanyInformation && (
                  <div className="w-full">
                    <div
                      className="font-medium text-lg pb-2"
                      style={{
                        color: textColor,
                      }}
                    >
                      {companyName || 'Company Information'}
                    </div>
                    {hasCompanyPhoneNumber && (
                      <div
                        className="flex items-center py-2 text-sm gap-x-3 border-b font-medium"
                        style={{
                          color: textColor,
                          borderColor: hexToRgba(textColor, 0.1),
                        }}
                      >
                        <PhoneIcon
                          className="w-5 h-5 flex-shrink-0"
                          color={hexToRgba(textColor, 0.35)}
                        />
                        {companyPhoneNumber}
                      </div>
                    )}
                    {hasCompanyWebsite && (
                      <div
                        className="flex items-center py-2 text-sm gap-x-3 border-b font-medium"
                        style={{
                          color: textColor,
                          borderColor: hexToRgba(textColor, 0.1),
                        }}
                      >
                        <GlobeAltIcon
                          className="w-5 h-5 flex-shrink-0"
                          color={hexToRgba(textColor, 0.35)}
                        />
                        {companyWebsite}
                      </div>
                    )}
                    {hasCompanyAddress && (
                      <div
                        className="flex items-center py-2 text-sm gap-x-3 font-medium"
                        style={{
                          color: textColor,
                          borderColor: hexToRgba(textColor, 0.1),
                        }}
                      >
                        <MapPinIcon
                          className="w-5 h-5 flex-shrink-0"
                          color={hexToRgba(textColor, 0.35)}
                        />
                        {getFullCompanyAddress({
                          streetAddress,
                          city,
                          state,
                          postCode,
                          country,
                        })}
                      </div>
                    )}
                  </div>
                )}
                {note && (
                  <div className="flex flex-col space-y-3">
                    <span className="text-lg font-medium">Note</span>
                    <p
                      className="whitespace-pre-wrap text-base"
                      style={{ color: hexToRgba(textColor, 0.8) }}
                    >
                      {note}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sticky bottom-4 mx-4 h-12 rounded-full flex items-center justify-center gap-1 text-base"
        style={{
          backgroundColor: buttonBgColor,
          color: buttonTextColor,
        }}
      >
        Save contact
        <ArrowsRightLeftIcon width={20} height={20} strokeWidth={2} />
      </div>
    </div>
  );
}
