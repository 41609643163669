import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Menu, Transition } from '@headlessui/react';
import {
  AtSymbolIcon,
  DevicePhoneMobileIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
  QrCodeIcon,
  UserGroupIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';

import profilesAPI from '@/api/profiles';
import { Alert } from '@/components/Alert';
import { BUTTON_KIND } from '@/components/Button';
import Modal from '@/components/Modals/Modal';
import { AddProfilesToGroupModal } from '@/components/ProfilesListPage/AddProfilesToGroupModal';
import { QRCodeFileSelectionModal } from '@/components/ProfilesListPage/QRCode';
import { SendPassModal } from '@/components/Templates/DigitalWallet';
import { SendEmailSignatureModal } from '@/components/Templates/ShareWithTeamButton';
import MESSAGES from '@/constants/messages-en';
import { classNames } from '@/helpers/strings';
import useAuth from '@/hooks/useAuth';
import { IProfileAppAccess, IProfileBase } from '@/types/IProfile';

type ProfilesPageFooterProps = {
  selectedProfiles: IProfileBase[];
  setActionResult: React.Dispatch<React.SetStateAction<Alert | undefined>>;
  onActionSuccess?: () => void;
  refreshProfiles: () => void;
  hideEditSharedInfo?: boolean;
};

export function ProfilesPageFooter({
  selectedProfiles,
  setActionResult,
  onActionSuccess,
  refreshProfiles,
  hideEditSharedInfo = false,
}: ProfilesPageFooterProps) {
  const navigate = useNavigate();
  const { orgID, userRole } = useAuth();

  const [isAddToGroupModalOpen, setIsAddToGroupModalOpen] = useState(false);
  const [isAppInviteOpen, setIsAppInviteOpen] = useState(false);
  const [isResendAppInviteOpen, setIsResendAppInviteOpen] = useState(false);
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [isEditSharedOpen, setIsEditSharedOpen] = useState(false);
  const [isSendPassModalOpen, setIsSendPassModalOpen] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [isSendEmailSignatureModalOpen, setIsSendEmailSignatureModalOpen] =
    useState(false);

  const MAX_INVITATION_COUNT = 50;

  const noOfInvitableAccounts = selectedProfiles.filter(
    i => i.app_access !== IProfileAppAccess.connected,
  ).length;
  const hasPendingAppInvite = selectedProfiles.some(
    i => i.app_access === IProfileAppAccess.pending,
  );
  const hasProfileWithoutEmail = selectedProfiles.some(p => !p.email);

  async function inviteToApp() {
    if (orgID === undefined) {
      return;
    }

    try {
      if (selectedProfiles.length > MAX_INVITATION_COUNT) {
        setActionResult({
          outcome: 'error',
          message: `You can only send up to ${MAX_INVITATION_COUNT} invitations at once.`,
        });
        return;
      }

      if (hasProfileWithoutEmail) {
        setActionResult({
          outcome: 'error',
          message: MESSAGES.error.appInviteError,
        });
        return;
      }

      setIsInviting(true);

      await profilesAPI.inviteUsers(
        orgID,
        selectedProfiles
          .filter(p => p.app_access !== IProfileAppAccess.connected)
          .map(({ id }) => id),
      );

      setActionResult({
        outcome: 'success',
        message: 'App invite successfully sent',
      });
      refreshProfiles();

      onActionSuccess?.();
    } catch (error: unknown) {
      setActionResult({ outcome: 'error', message: MESSAGES.error.generic });
    } finally {
      setIsResendAppInviteOpen(false);
      setIsAppInviteOpen(false);
      setIsInviting(false);
    }
  }

  const menuOptions = [
    {
      title: 'Add to group',
      icon: UserGroupIcon,
      onClick: () => setIsAddToGroupModalOpen(true),
      visibility: userRole === 'org_admin',
    },
    {
      title: 'Invite to app',
      icon: DevicePhoneMobileIcon,
      onClick: hasPendingAppInvite
        ? () => setIsResendAppInviteOpen(true)
        : () => setIsAppInviteOpen(true),
      visibility: true,
      disabled: noOfInvitableAccounts === 0,
    },
    {
      title: 'Export QR codes',
      icon: QrCodeIcon,
      onClick: () => setIsQRCodeModalOpen(true),
      visibility: true,
    },
    {
      title: 'Share digital wallet card',
      icon: WalletIcon,
      onClick: () => setIsSendPassModalOpen(true),
      visibility: true,
    },
    {
      title: 'Share email signatures',
      icon: AtSymbolIcon,
      onClick: () => setIsSendEmailSignatureModalOpen(true),
      visibility: true,
    },
  ];

  return (
    <div className="flex items-center justify-end gap-4">
      <span className="text-sm leading-5 text-gray-500">
        {selectedProfiles.length} selected
      </span>
      {!hideEditSharedInfo && (
        <Modal
          isOpen={isEditSharedOpen}
          setIsOpen={setIsEditSharedOpen}
          buttonTitle="Edit shared info"
          dialogTitle="Editing shared profile information"
          dialogDescription={
            <>
              This feature enables you to add information to multiple profiles
              at once.
              <br />
              <br />
              <span className="font-medium">Important:</span> Changes you make
              will overwrite existing information on the selected profiles.
            </>
          }
          buttonKind={BUTTON_KIND.WHITE}
          icon={<PencilIcon />}
          onSuccess={() => navigate('/edit-shared-profile')}
          successButtonText="Ok got it"
        />
      )}
      <Menu as="div" className="relative inline-block items-center">
        <div className="flex items-center">
          <Menu.Button
            onClick={e => e.stopPropagation()}
            className="appearance-none rounded-md border border-gray-300 p-2"
          >
            <EllipsisHorizontalIcon className="w-5 h-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-90 scale-95"
        >
          <Menu.Items className="absolute right-0 bottom-12 w-max rounded-md shadow-lg bg-white border border-gray-300 ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden">
            {menuOptions.map(({ icon: Icon, ...option }, index) => (
              <Menu.Item key={index}>
                <button
                  type="button"
                  className={classNames(
                    'text-gray-700 text-right flex-1 w-full px-4 py-2 hover:bg-gray-50 gap-x-3 text-sm',
                    option.visibility ? 'flex' : 'hidden',
                  )}
                  onClick={e => {
                    e.stopPropagation();
                    option.onClick();
                  }}
                >
                  <Icon className="w-5 h-5 text-gray-400" strokeWidth={2} />
                  {option.title}
                </button>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
      <AddProfilesToGroupModal
        isOpen={isAddToGroupModalOpen}
        setIsOpen={setIsAddToGroupModalOpen}
        checkedProfiles={selectedProfiles}
        onSuccessCallback={() => {
          setActionResult({
            outcome: 'success',
            message: 'Profiles were added to group successfully.',
          });

          refreshProfiles();
          onActionSuccess?.();
        }}
      />
      <Modal
        isOpen={isAppInviteOpen}
        setIsOpen={setIsAppInviteOpen}
        dialogTitle={MESSAGES.profile.appInvite.send.heading}
        dialogDescription={MESSAGES.profile.appInvite.send.description}
        successButtonText="Send invite"
        onSuccess={inviteToApp}
        isLoading={isInviting}
      />
      <Modal
        isOpen={isResendAppInviteOpen}
        setIsOpen={setIsResendAppInviteOpen}
        dialogTitle={MESSAGES.profile.appInvite.resend.heading}
        dialogDescription={MESSAGES.profile.appInvite.resend.description}
        successButtonText="Send invite"
        onSuccess={inviteToApp}
        isLoading={isInviting}
      />
      <QRCodeFileSelectionModal
        isOpen={isQRCodeModalOpen}
        setIsOpen={setIsQRCodeModalOpen}
        checkedProfiles={selectedProfiles.map(({ id }) => id)}
        onSuccessCallback={onActionSuccess}
      />
      <SendPassModal
        isOpen={isSendPassModalOpen}
        setIsOpen={setIsSendPassModalOpen}
        checkedProfiles={selectedProfiles.map(({ id }) => id)}
        onSuccessCallback={() => {
          setActionResult({
            outcome: 'success',
            message: 'Wallet passes was sent successfully',
          });
        }}
      />
      <SendEmailSignatureModal
        isOpen={isSendEmailSignatureModalOpen}
        setIsOpen={setIsSendEmailSignatureModalOpen}
        checkedProfiles={selectedProfiles.map(({ id }) => id)}
        onSuccessCallback={() => {
          setActionResult({
            outcome: 'success',
            message: 'Email signature was sent successfully',
          });
        }}
      />
    </div>
  );
}
