import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import {
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';

import PrivacyPolicy from '@/assets/documents/EU-Privacy-Policy-Tapt-By-Hatch.pdf';
import TandCs from '@/assets/documents/Website Terms and Conditions - Tapt By Hatch.pdf';
import Logo from '@/assets/svg/logos/primary.svg';

interface ILayoutStandard {
  heading: string;
  description?: string;
  children: React.ReactNode;
}

const LayoutStandard: React.FC<ILayoutStandard> = props => {
  const { children, heading, description } = props;

  return (
    <div className="min-h-screen bg-white flex flex-col justify-between py-6 sm:px-6 lg:px-8">
      <div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <img
              className="mx-auto h-24 w-24 mt-10 mb-10"
              src={Logo}
              alt="Tapt"
            />
          </Link>
          <h2 className="mt-6 mb-4 text-center text-2xl leading-8 font-medium text-gray-900">
            {heading}
            {import.meta.env.VITE_DISPLAY_ENV
              ? ` (${import.meta.env.VITE_DISPLAY_ENV})`
              : ``}
          </h2>
          {description && (
            <p className="mt-4 px-4 text-center leading-6 text-gray-900">
              {description}
            </p>
          )}
        </div>
        {children}
      </div>
      <div className="hidden lg:block">
        <ul className="mt-6 flex justify-center flex-wrap">
          <li className="inline-flex">
            <a
              className="py-0 px-5 text-base leading-6 font-normal text-gray-500 hover:text-gray-700"
              href="https://help.tapt.io"
              target="_blank"
              rel="noreferrer"
            >
              Help
            </a>
          </li>
          <li className="inline-flex">
            <a
              className="py-0 px-5 text-base leading-6 font-normal text-gray-500 hover:text-gray-700"
              href={PrivacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li className="inline-flex">
            <a
              className="py-0 px-5 text-base leading-6 font-normal text-gray-500 hover:text-gray-700"
              href={TandCs}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
          </li>
        </ul>
      </div>
      <div className="lg:hidden sticky bottom-2">
        <ul className="mt-6 flex justify-center flex-wrap bg-white">
          <li className="inline-flex">
            <a
              className="py-0 px-5 mb-0 text-xs leading-6 font-normal text-gray-500 hover:text-gray-700"
              href="https://help.tapt.io"
              target="_blank"
              rel="noreferrer"
            >
              Help
            </a>
          </li>
          <li className="inline-flex">
            <a
              className="py-0 px-5 mb-0 text-xs leading-6 font-normal text-gray-500 hover:text-gray-700"
              href={PrivacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li className="inline-flex">
            <a
              className="py-0 px-5 mb-0 text-xs leading-6 font-normal text-gray-500 hover:text-gray-700"
              href={TandCs}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { LayoutStandard as default };

type LayoutStandardWithSidebarProps = {
  heading: string;
} & PropsWithChildren;

export function LayoutStandardWithSidebar({
  children,
  heading,
}: LayoutStandardWithSidebarProps) {
  const bottom = [
    {
      name: 'Help',
      href: 'https://help.tapt.io/en',
      icon: <QuestionMarkCircleIcon className="w-5 h-5" />,
    },
    {
      name: 'Privacy Policy',
      href: 'https://tapt.io/policies/privacy-policy',
      icon: <ShieldCheckIcon className="w-5 h-5" />,
    },
    {
      name: 'Terms',
      href: 'https://tapt.io/policies/terms-of-service',
      icon: <DocumentTextIcon className="w-5 h-5" />,
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="min-h-[100dvh] flex overflow-hidden bg-white">
        <div className="hidden xl:flex flex-shrink-0 border-r border-gray-200">
          <div className="relative flex-1 flex flex-col w-[235px] h-[100dvh] bg-white">
            <div className="flex-shrink-0 inline-flex items-center h-20 px-3">
              <Link to="/">
                <img className="h-8 w-auto" src={Logo} alt="Tapt" />
              </Link>
              {!!import.meta.env.VITE_DISPLAY_ENV &&
                `(${import.meta.env.VITE_DISPLAY_ENV})`}
            </div>
            <div className="flex-1" />
            <div className="divide-y divide-gray-300 border-y border-gray-300">
              <div className="p-3">
                {bottom.map((item, index) => (
                  <Link
                    key={index}
                    to={item.href}
                    className="flex items-center space-x-2 pl-3 py-2 text-gray-500 group"
                  >
                    <span className="">{item.icon}</span>
                    <span className="text-xs group-hover:text-brand-500">
                      {item.name}
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-8 py-12 w-full">
          <h2 className="text-4xl font-medium text-gray-900">{heading}</h2>
          {children}
        </div>
      </div>
      <div className="xl:hidden flex justify-center space-x-4 bg-gray-50 py-4">
        {bottom.map((item, index) => (
          <a
            key={index}
            className="text-gray-500 leading-6 hover:text-gray-700 font-normal"
            href={item.href}
          >
            {item.name}
          </a>
        ))}
      </div>
    </div>
  );
}
