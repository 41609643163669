import { useNavigate } from 'react-router-dom';

import { ArrowRightIcon, ClockIcon } from '@heroicons/react/24/outline';

import useAppState, { SettingsTab } from '@/hooks/useAppState';

interface RenewalUpcomingBannerProps {
  daysUntilRenewal: number;
}

function RenewalUpcomingBanner({ daysUntilRenewal }: RenewalUpcomingBannerProps) {
  const navigate = useNavigate();
  const { selectSettingsTab } = useAppState();
  return (
    <>
      <div className="flex p-3 bg-amber-50 rounded-tl-2xl rounded-br-2xl rounded-tr-sm rounded-bl-sm space-x-3">
        <ClockIcon className="w-4 h-4 text-amber-400 mt-2" strokeWidth={2} />
        <div className="flex flex-col">
          <div className="text-sm md:text-xs text-amber-800 pt-2 pb-2">
            {daysUntilRenewal} {daysUntilRenewal > 1 ? 'Days' : 'Day'} until platform fee renewal.
          </div>
          <button
            className="flex text-sm md:text-xs items-center text-amber-400 hover:text-amber-500"
            onClick={e => {
              e.preventDefault();
              selectSettingsTab(SettingsTab.MANAGE_PLAN);
              navigate(`/settings`);
            }}
          >
            Manage&nbsp;
            <ArrowRightIcon className="w-4 h-4" strokeWidth={2} />
          </button>
        </div>
      </div>
    </>
  );
}

export default RenewalUpcomingBanner;
