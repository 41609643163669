import { JSX, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ChevronRightIcon, CodeBracketIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';

import profilesAPI from '@/api/profiles';
import { ReactComponent as GmailLogo } from '@/assets/svg/email-signature/gmail.svg';
import { ReactComponent as OutlookLogo } from '@/assets/svg/email-signature/outlook.svg';
import Copy from '@/components/Icons/Copy';
import { LayoutStandardWithSidebar } from '@/components/LayoutStandard';
import {
  DetailedEmailSignaturePreview,
  SimplifiedEmailSignaturePreview,
} from '@/components/Templates/EmailSignature/Preview';
import clipboardService from '@/helpers/clipboardService';
import { classNames } from '@/helpers/strings';
import { isValidHashId } from '@/helpers/validate';
import { SupportedClient, supportedClients } from '@/types/IOrganisation';

import NotFoundPage from './NotFoundPage';

type ClientSettings = {
  name: string;
  heading: string;
  instructions: JSX.Element[];
  additionalInfo?: { heading: string; instruction: string }[];
};
type ClientOption = Record<SupportedClient, ClientSettings>;

export default function EmailSignaturePreviewPage() {
  const { profile_hash_id } = useParams<{ profile_hash_id: string }>();

  if (!profile_hash_id) return <NotFoundPage />;
  if (!isValidHashId(profile_hash_id)) return <NotFoundPage />;

  const signatureRef = useRef<HTMLDivElement>(null);

  const [selectedClient, setSelectedClient] =
    useState<SupportedClient>('gmail');
  const [isCopied, setIsCopied] = useState(false);

  const { data, isFetching, isError } = useQuery({
    queryKey: ['fetchSignature', profile_hash_id],
    queryFn: getEmailSignature,
    enabled: !!profile_hash_id,
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (isError) {
    return <NotFoundPage />;
  }

  async function getEmailSignature() {
    if (!profile_hash_id) {
      return;
    }

    const res = await profilesAPI.getEmailSignature(profile_hash_id);
    return res.data.data;
  }

  const handleCopyToClipboard = async () => {
    if (!signatureRef.current) return;

    try {
      clipboardService
        .writeToClipboard(signatureRef.current.innerHTML)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 3000);
        });
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleCopyHtmlToClipboard = async () => {
    if (!signatureRef.current) return;

    try {
      // Try modern Clipboard API first
      const htmlContent = signatureRef.current.innerHTML;
      clipboardService.writeHtmlToClipboard(htmlContent).then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      });
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const clientNames: {
    key: SupportedClient;
    icon: JSX.Element;
    name: string;
  }[] = [
    {
      key: 'gmail',
      icon: <GmailLogo />,
      name: 'Gmail',
    },
    {
      key: 'outlook',
      icon: <OutlookLogo />,
      name: 'Outlook',
    },
    {
      key: 'source-code',
      icon: (
        <CodeBracketIcon className="w-6 h-6 text-brand-500" strokeWidth={2} />
      ),
      name: 'Source code',
    },
  ];

  const clientOptions: ClientOption = {
    gmail: {
      name: 'Gmail',
      heading: 'Gmail Installation Instructions',
      instructions: [
        <>
          Click&nbsp;
          <button
            type="button"
            className="underline font-medium"
            onClick={handleCopyHtmlToClipboard}
          >
            Copy to clipboard
          </button>
          &nbsp;in the preview container (right side).
        </>,
        <>
          Open&nbsp;
          <a
            href="https://mail.google.com"
            className="underline text-gray-500 font-medium"
            target="_blank"
          >
            Gmail
          </a>
          .
        </>,
        <span className="flex items-center flex-wrap gap-x-1">
          In the top right, click Settings
          <ChevronRightIcon className="w-4 h-4" strokeWidth={2} />
          General
          <ChevronRightIcon className="w-4 h-4" strokeWidth={2} />
          Signature Settings.
        </span>,
        <>
          Paste your signature (Ctrl+V or Command+V) into the &quot;
          <span className="font-medium">Signature</span>&quot; section.
        </>,
        <>
          At the bottom of the page, click{' '}
          <span className="font-medium">Save changes</span>.
        </>,
      ],
    },
    outlook: {
      name: 'Outlook',
      heading: 'Outlook Installation Instructions',
      instructions: [
        <>
          Click&nbsp;
          <button
            type="button"
            className="underline font-medium"
            onClick={handleCopyHtmlToClipboard}
          >
            Copy to clipboard
          </button>
          &nbsp;in the preview container (right side).
        </>,
        <>Go to your Outlook and compose a New Email.</>,
        <span className="flex items-center flex-wrap gap-x-1">
          Go to your Message Tab and click on Signature
          <ChevronRightIcon className="w-4 h-4" strokeWidth={2} />
          Signatures.
        </span>,
        <>Click New and name your signature.</>,
        <>Click the + icon to add a new signature.</>,
      ],
      additionalInfo: [
        {
          heading: 'Mac',
          instruction:
            'Paste your signature into the Signature box (Ctrl+V or Command+V), click on the clipboard that pops up, select the Keep Source Formatting paste option. Confirm with OK.',
        },
        {
          heading: 'Windows',
          instruction:
            'Right click the Edit Signature box, choose the Keep Source Formatting paste option, confirm with OK.',
        },
      ],
    },
    'source-code': {
      name: 'Source code',
      heading:
        'Some email clients require signature as an HTML file or as source code.',
      instructions: [
        <>
          Click&nbsp;
          <button type="button" onClick={handleCopyHtmlToClipboard}>
            Copy to clipboard
          </button>
          &nbsp; in the preview container (right side) to copy the source code.
        </>,
      ],
    },
  };

  return (
    <LayoutStandardWithSidebar heading="Your custom email signature">
      <div className="flex flex-col items-center xl:max-w-screen-xl mx-auto py-6 space-y-10">
        <div className="flex flex-col-reverse xl:flex-row gap-4 w-full overflow-auto xl:place-items-start md:place-items-center">
          <div className="flex-1 bg-white rounded-lg shadow-sm p-6 space-y-6 border border-gray-200 ">
            <div className="flex space-x-4">
              {clientNames.map((item, index) => (
                <button
                  key={index}
                  className={classNames(
                    'flex items-center gap-x-2 text-base pb-1 border-b-2',
                    selectedClient === item.key
                      ? 'border-gray-900 text-gray-900'
                      : 'border-transparent text-gray-500',
                  )}
                  style={
                    selectedClient === item.key
                      ? {
                          // to avoid layout shift
                          textShadow: '0 0 0.3px currentColor',
                        }
                      : undefined
                  }
                  onClick={() => setSelectedClient(supportedClients[index])}
                >
                  {item.icon}
                  {item.name}
                </button>
              ))}
            </div>
            <div className="space-y-8">
              <div className="space-y-2">
                <span className="text-sm font-medium text-gray-900">
                  {clientOptions[selectedClient].heading}
                </span>
                <ol className="list-decimal pl-5">
                  {clientOptions[selectedClient].instructions.map(
                    (instruction, index) => (
                      <li key={index} className="text-sm text-gray-500">
                        {instruction}
                      </li>
                    ),
                  )}
                </ol>
              </div>
              {clientOptions[selectedClient].additionalInfo?.map(
                (info, index) => (
                  <div key={index} className="flex flex-col text-sm space-y-2">
                    <span className="font-medium">{info.heading}</span>
                    <span className="text-gray-500">{info.instruction}</span>
                  </div>
                ),
              )}
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            <div className="mb-3 flex grow justify-end">
              <button
                onClick={
                  selectedClient === 'source-code'
                    ? handleCopyToClipboard
                    : handleCopyHtmlToClipboard
                }
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isCopied ? (
                  'Copied!'
                ) : (
                  <>
                    <Copy className="h-4 w-4 mr-1" />
                    Copy signature
                  </>
                )}
              </button>
            </div>
            <div
              ref={signatureRef}
              className="p-3 border border-gray-200 shadow-xl rounded-lg overflow-x-auto hide-scrollbar"
            >
              {data === undefined || isFetching ? (
                <div className="text-xl font-medium">Loading...</div>
              ) : data.signature.type === 'compact' ? (
                <SimplifiedEmailSignaturePreview
                  signature={data.signature}
                  profile={data.profile}
                  assets={data.assets}
                  clientType={selectedClient}
                />
              ) : (
                <DetailedEmailSignaturePreview
                  signature={data.signature}
                  profile={data.profile}
                  assets={data.assets}
                  clientType={selectedClient}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </LayoutStandardWithSidebar>
  );
}
