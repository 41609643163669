import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

import { CSVImportErrorDetail } from '.';
import ErrorIcon from '../Icons/ErrorIcon';
import WarningIcon from '../Icons/WarningIcon';
import InputSelect from '../InputSelect';
import data from './data';

export type FieldMapping = {
  taptField: string;
  csvField: string;
  isError?: boolean;
};

interface CsvFieldsRowProps {
  exampleEntry: Record<string, unknown>;
  field: string;
  errors: Set<CSVImportErrorDetail> | undefined;
  onChange: (value: string) => void;
  fieldMapping?: FieldMapping;
  csvFieldMapping?: FieldMapping[];
  index: number;
}

const renderFieldMappingStatus = (fieldMapping?: FieldMapping) => {
  const defaultIcon = (
    <CheckIcon className="mx-auto w-5 h-5 text-emerald-500" />
  );

  return (
    {
      select_field: <WarningIcon className="mx-auto" />,
      no_map: <XMarkIcon className="mx-auto w-5 h-5 [&>path]:stroke-red-450" />,
    }[fieldMapping?.taptField ?? ''] ?? defaultIcon
  );
};

const CsvFieldsRow = ({
  exampleEntry,
  field,
  onChange,
  errors,
  fieldMapping,
  csvFieldMapping,
  index,
}: CsvFieldsRowProps) => {
  return (
    <>
      <tr
        className={clsx('table table-fixed w-full', {
          'bg-gray-50': index % 2 !== 0,
          '!border-none': errors,
        })}
      >
        <th scope="row" className="px-6 py-4">
          <div className="text-sm font-medium leading-5 text-gray-900">
            {field}
          </div>
          {!!exampleEntry?.[field] && (
            <div className="text-sm font-normal leading-5 text-gray-500">
              e.g. {exampleEntry[field] as string}
            </div>
          )}
        </th>

        <td className="px-6 py-4">
          {fieldMapping?.isError ? (
            <span className="rounded-full bg-red-100 text-red-800 text-center text-xs font-medium leading-4 px-[10px] py-1 block">
              Import error
            </span>
          ) : (
            <CheckIcon className="mx-auto w-5 h-5 text-emerald-500" />
          )}
        </td>
        <td
          className={clsx(
            fieldMapping?.taptField === 'select_field' &&
              '[&>div>div>select]:border-[#FF6D6D] [&>div>div>select]:text-red-700 [&>div>div>select]:bg-[url("/images/error-arrow.png")] [&>div>div>select:focus]:!ring-0 [&>div>div>select:focus]:!border-[#FF6D6D]',
            'px-6 py-4',
          )}
        >
          <InputSelect
            id="page-size"
            label=""
            value={fieldMapping?.taptField ?? 'select_field'}
            onChange={onChange}
            options={[
              {
                label: 'Select Field',
                value: 'select_field',
                hidden: false,
              },
              { label: "Don't map this field", value: 'no_map' },
              ...data.profileTaptFields
                .filter(
                  i =>
                    !csvFieldMapping
                      ?.filter(j => j.csvField !== field)
                      ?.map(j => j.taptField)
                      .includes(i.key),
                )
                .sort((a, b) => a.title.localeCompare(b.title))
                .map(i => ({
                  label: i.title,
                  value: i.key,
                })),
            ]}
            tableInputSelect
          />
        </td>
        <td className="px-6 py-4">{renderFieldMappingStatus(fieldMapping)}</td>
      </tr>
      {errors && (
        <tr
          className={clsx('table w-full table-fixed', {
            'bg-gray-50': index % 2 !== 0,
          })}
        >
          <td className="px-6 py-4 !bg-red-50 text-red-800 flex flex-row my-3 mx-6 rounded-md">
            <span className="pr-2">
              <ErrorIcon className="w-4 h-4 flex-shrink-0 mt-1" />
            </span>
            <span className="flex flex-col">
              <span className="font-medium">
                The following rows have import errors
              </span>
              <div className="!w-full">
                <ul className="list-disc list-inside">
                  {Array.from(errors).map((error, index) => (
                    <li key={index} className="pl-2">
                      Row {error.rowNumber} - <code>{error.cause}</code>
                    </li>
                  ))}
                </ul>
              </div>
            </span>
          </td>
        </tr>
      )}
    </>
  );
};

export default CsvFieldsRow;
