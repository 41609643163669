import { AxiosError, AxiosResponse } from 'axios';
import { z } from 'zod';

import IFile, { FileSchema } from './IFile';
import { IProfileSocialLink } from './IProfile';

export enum SubscriptionTypes {
  SEAL = 'seal',
  PREPAID = 'prepaid',
}

export default interface IOrganisation {
  domain_name: string;
  id: number;
  name: string;
  email?: string;
  no_of_accounts: number;
  no_of_active_profiles: number;
  no_of_editing_profiles: number;
  no_of_profiles: number;
  no_of_unprinted_profiles: number;
  inserted_at: string;
  last_login: string | null;
}

export interface IOrganisationData {
  data: IOrganisation[];
}

export interface IOrganisationUser {
  id: number;
  organisation_id: number;
  role: 'org_admin' | 'org_editor';
  user: {
    email: string;
    first_name: string;
    id: number;
    last_name: string;
  };
  groups: IOrganisationGroup[];
}

export interface IOrganisationGroup {
  id: number;
  group_hash: string;
  name: string;
  description?: string | null;
  organisation_id: number;
  organisation_name: string;
  group_logo_file_id?: number | null;
  group_logo?: IFile;
  bg_color: string;
  button_bg_color: string;
  button_text_color: string;
  text_color: string;
  editors: IOrganisationUser[];
  profiles_count: number;
}

export interface IOrganisationEditor {
  id: number;
  organisation_id: number;
  role_id: number;
  user: IOrganisationUser;
}

export interface IOrganisationSettingsContactExchange {
  company_name: boolean;
  email: boolean;
  job_title: boolean;
  mobile_number: boolean;
  name: boolean;
  company_consent: boolean;
}

export interface IOrganisationSettings {
  bg_color: string;
  button_bg_color: string;
  button_text_color: string;
  colour_settings?: string;
  is_contact_exchange_sms_enabled: boolean;
  is_cardholder_editing_enabled: boolean;
  company_logo?: IFile;
  company_consent_file?: IFile;
  company_consent_message?: string;
  company_logo_default?: boolean;
  company_logo_file_id?: number | null;
  company_consent_file_id?: number | null;
  contact_exchange: IOrganisationSettingsContactExchange;
  font?: string;
  id?: number;
  text_color: string;
  group_id?: number;
  payment_terms?: boolean;
}

// to be used for edit profile pages
export const OrganisationSettingsSchema = z.object({
  bg_color: z.string(),
  text_color: z.string(),
  button_bg_color: z.string(),
  button_text_color: z.string(),
  company_logo: FileSchema.nullable(),
});

export interface IOrganisationUserList {
  data: IOrganisationUser[];
}

export interface IOrganisationDeleteErrorResponse extends AxiosResponse {
  data: {
    errors: string[];
  };
}

export interface IOrganisationDeleteError extends AxiosError {
  response: IOrganisationDeleteErrorResponse;
}

export interface IPilot {
  id: number;
  name: string;
  saas_enabled: boolean;
  inserted_at: string;
  subscription: IPlatformSubscription | null;
  total_profiles: number;
  platform_subscription_status: string;
}

export interface IBillingAddress {
  city: string;
  country: string;
  customer_id: string;
  first_name: string;
  last_name: string;
  line1: string;
  line2: string;
  organisation_id: number;
  state: string;
  zip: string;
}

export interface IOrganisationInvite {
  id: number;
  email: string;
  role: string;
  group_ids: number[];
}

export enum IOrganisationUpdateEvent {
  view_contact = 'view:contact',
  received_contact = 'receive:contact',
  sent_contact = 'send:contact',
  edit_profile = 'edit:profile',
  details_exchange = 'details:exchange',
}

export interface IOrganisationProfile {
  count: number;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  photo: IOrganisationProfilePhoto;
}

export interface IOrganisationProfilePhoto {
  id: number;
  file_size: number;
  name: string;
  original_url: string;
}

export interface IOrganisationUpdatesProfile {
  first_name: string;
  id: number;
  last_name: string | null;
}

export interface IOrganisationUpdates {
  event: IOrganisationUpdateEvent;
  inserted_at: string;
  profile: IOrganisationUpdatesProfile;
  sender?: IOrganisationUpdatesProfile;
  receiver?: IOrganisationUpdatesProfile;
}

export interface IOrganisationUpdate {
  top_profile: IOrganisationProfile | null;
  updates: IOrganisationUpdates[];
}

export interface IBrandFetchOrganisation {
  bg_color: string;
  text_color: string;
  button_bg_color: string;
  button_text_color: string;
  banner_image_file: IFile | null;
}

export interface IPlatformSubscription {
  id: number;
  email: string;
  type: 'prepaid' | 'seal';
  order_placed: string;
  first_name: string;
  last_name: string;
  edit_url: string;
  items: IPlatformSubscriptionItem[];
  billing_attempts: IPlatformSubscriptionBillingAttempt[];
  billing_interval: '1 year' | '1 month' | null
  billing: IPlatformSubscriptionBilling;
  shipping: IPlatformSubscriptionShipping;
  payment_method: IPlatformSubscriptionPaymentMethod;
  cancelled_at: string | null;
  invoices: IPlatformSubscriptionInvoice[];
}

export interface IPlatformSubscriptionFull extends IPlatformSubscription {
  renewal_payment: IPlatformSubscriptionPaymentDetails | null;
  upgrade_payment: IPlatformSubscriptionUpgradePayment | null;
}

export interface ISubscriptionTier {
  max_profiles: number;
  min_profiles: number;
  name: string;
}

export interface IPlatformSubscriptionUpgradePayment
  extends IPlatformSubscriptionPaymentDetails {
  existing_tier: ISubscriptionTier;
  new_tier: ISubscriptionTier;
}

export interface IPlatformSubscriptionItem {
  id: number;
  quantity: number;
  original_amount: number;
  final_amount: number;
  variant_id: string;
  title: string;
}

export interface IPlatformSubscriptionBillingAttempt {
  id: number;
  date: string;
  status: string;
  skipped_on?: string;
}

export interface IPlatformSubscriptionInvoice {
  id: number;
  date: string;
  payment_status: string;
  invoice_url: string | null;
  invoice_download_url: string | null;
}

export interface IPlatformSubscriptionBilling {
  first_name: string;
  last_name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  province: string;
  province_code: string;
  country: string;
  country_code: string;
}

export interface IPlatformSubscriptionShipping {
  first_name: string;
  last_name: string;
  company: string;
  address: string;
  city: string;
  zip: string;
  province: string;
  province_code: string;
  country: string;
  country_code: string;
}

export interface IPlatformSubscriptionPaymentMethod {
  brand: string;
  last4: string;
  exp_month: string;
  exp_year: string;
}

export interface IPlatformSubscriptionInvoice {
  id: number;
  date: string;
  email_sent_at: string;
  invoice_url: string | null;
  payment_status: string;
}

export interface IPlatformSubscriptionPaymentDetails {
  date: string;
  error_code: string;
  error_message: string;
  grace_period_end_date: string;
  invoice_url: string | null;
  invoice_download_url: string | null;
  status: string;
}

export interface ISubscriptionTier {
  max_profiles: number;
  min_profiles: number;
  name: string;
}

export interface IPlatformSubscriptionUpgradePayment
  extends IPlatformSubscriptionPaymentDetails {
  existing_tier: ISubscriptionTier;
  new_tier: ISubscriptionTier;
}

export type IEmailSignature = {
  type: 'compact' | 'detailed';
  show_full_name: boolean;
  show_profile_image: boolean;
  show_job_title: boolean;
  show_mobile_number: boolean;
  show_email: boolean;
  show_social_links: boolean;
  show_company_name: boolean;
  show_company_website: boolean;
  show_company_phone: boolean;
  show_company_address: boolean;
  background_color: string;
  text_color: string;
  primary_text_color: string;
  show_qr_code: boolean;
  show_add_to_contacts: boolean;
  show_banner: boolean;
  disclaimer_text: string | null;
  banner_file: IFile | null;
  logo_file: IFile | null;
};

export type IEmailSignatureAssets = {
  profile_image_url: string | null;
  qr_code_url: string;
  banner_image_url: string | null;
};

export type IDigitalWalletDesignResponse = {
  text_color: string;
  background_color: string;
  logo_file: IFile | null;
  banner_image: IFile | null;
};

export type IDigitalWalletDesignRequest = {
  digital_wallet_design: {
    background_color: string;
    text_color: string;
    banner_image_file_id: number | null;
    logo_file_id: number | null;
  };
};

export type UpdateEmailSignatureRequest = Omit<
  IEmailSignature,
  'logo_file' | 'banner_file'
> & {
  logo_file_id: number | null;
  banner_file_id: number | null;
};

export const supportedClients = ['gmail', 'outlook', 'source-code'] as const;
export type SupportedClient = (typeof supportedClients)[number];
