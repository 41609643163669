import { useState } from 'react';

import { SparklesIcon } from '@heroicons/react/20/solid';
import { useMutation } from '@tanstack/react-query';

import dashboardApi from '@/api/dashboard';
import Modal from '@/components/Modals/Modal';
import useAuth from '@/hooks/useAuth';
import { useRateLimit } from '@/hooks/useRateLimit';
import { IBrandFetchOrganisation } from '@/types/IOrganisation';

import { ErrorAlert } from './Alert';
import Button from './Button';
import Input from './Input';

function getDomain(website: string) {
  const url = new URL(website.includes('://') ? website : `https://${website}`);
  const domain = url.hostname.replace('www.', '');

  if (domain.includes('.')) {
    return domain;
  }

  return null;
}

export function DesignWithAI({
  onSuccessCallback,
}: {
  onSuccessCallback?: (data: IBrandFetchOrganisation) => void;
}) {
  return (
    <div className="flex flex-col md:flex-row justify-between gap-4">
      <div className="flex flex-col text-sm">
        <span className="text-gray-900 font-medium">
          Need our help? Use our custom AI generator.
        </span>
        <span className="text-gray-500">
          We will try to find your brand colours and a suitable cover image.
        </span>
      </div>
      <div className="self-end">
        <DesignWithAIButton onSuccessCallback={onSuccessCallback} />
      </div>
    </div>
  );
}

export function DesignWithAIButton({
  onSuccessCallback,
}: {
  onSuccessCallback?: (data: IBrandFetchOrganisation) => void;
}) {
  const { orgID } = useAuth();
  const { canAttempt, useAttempt, attemptsLeft } = useRateLimit();

  const [website, setWebsite] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const {
    mutateAsync: fetchBrand,
    isPending: isLoading,
    error,
  } = useMutation({
    mutationFn: getBrandSettings,
    onSettled: useAttempt,
  });

  async function getBrandSettings() {
    if (orgID === undefined || !canAttempt) {
      return;
    }

    const domain = getDomain(website);

    if (domain === null) {
      const error = new Error('Invalid URL');
      error.name = 'INVALID_URL';
      throw error;
    }

    const res = await dashboardApi.searchBrand(orgID, domain);
    onSuccessCallback?.(res.data);
  }

  return (
    <>
      <Button
        buttonText="Design with AI"
        icon={<SparklesIcon />}
        onClick={() => setIsOpen(true)}
      />
      <Modal
        dialogTitle="Enter your company domain"
        dialogDescription="We will use this to find your brand colours and logos. (Limited to 3 attempts per 24 hours)"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSuccess={async () => {
          await fetchBrand();
          setIsOpen(false);
        }}
        successButtonText={
          canAttempt
            ? isLoading
              ? 'Allow up to 10 seconds'
              : 'Find Assets'
            : 'Try again in 24 hours'
        }
        isDisabled={!canAttempt}
        isLoading={isLoading}
      >
        <div className="pt-4 space-y-4">
          {error && error.name !== 'INVALID_URL' && (
            <ErrorAlert message="We couldn't find your brand assets. Please try a different domain." />
          )}
          <Input
            type="url"
            label="Company website"
            placeholder="https://tapt.io"
            value={website}
            onChange={setWebsite}
            error={error?.name === 'INVALID_URL'}
          />
          {attemptsLeft === 1 && (
            <span className="italic text-red-400 text-sm">
              1 more attempt available.
            </span>
          )}
          {!canAttempt && (
            <span className="italic text-red-400 text-sm">
              No more attempts available.
            </span>
          )}
        </div>
      </Modal>
    </>
  );
}
