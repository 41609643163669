import { useState } from 'react';

import {
  ArrowRightIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';

import accountsAPI from '@/api/accounts';
import BaseModal from '@/components/Modals/Modal';
import useAuth from '@/hooks/useAuth';
import { IPlatformSubscriptionPaymentDetails } from '@/types/IOrganisation';

import ModalNoCancel from '../Modals/ModalNoCancel';

const Banner = ({
  onOpenModal,
  daysLeft,
}: {
  onOpenModal: () => void;
  daysLeft: number;
}) => (
  <div className="flex p-3 bg-red-50 rounded-tl-2xl rounded-br-2xl rounded-tr-sm rounded-bl-sm space-x-3">
    <CurrencyDollarIcon className="w-6 h-6 text-red-400" strokeWidth={2} />
    <div className="flex flex-col">
      <div className="text-sm md:text-xs font-medium text-red-800">
        Platform fee overdue.
      </div>
      <div className="text-sm md:text-xs text-red-800 pt-2 pb-2">
        {daysLeft} {daysLeft > 1 ? 'Days' : 'Day'} until platform access is
        revoked.
      </div>
      <button
        className="flex text-sm md:text-xs items-center text-red-400 hover:text-red-500"
        onClick={e => {
          e.preventDefault();
          onOpenModal();
        }}
      >
        Read more&nbsp;
        <ArrowRightIcon className="w-4 h-4" strokeWidth={2} />
      </button>
    </div>
  </div>
);

const Modal = ({
  open,
  setOpen,
  onSuccess,
  payment,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
  payment: IPlatformSubscriptionPaymentDetails;
}) => {
  const { orgID } = useAuth();
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [
    isPaymentMethodEmailSentModalOpen,
    setIsPaymentMethodEmailSentModalOpen,
  ] = useState(false);
  const hasPaymentError = payment.error_code !== '';
  const limitedAccessDate = format(
    new Date(payment.grace_period_end_date),
    'dd/MM/yy',
  );

  const handleEditPaymentMethod = () => {
    if (!orgID) return;

    setIsSendingEmail(true);
    accountsAPI
      .sendPaymentMethodUpdateEmail(orgID)
      .then(() => {
        setIsPaymentMethodEmailSentModalOpen(true);
        setOpen(false);
      })
      .finally(() => setIsSendingEmail(false));
  };

  return (
    <>
      <BaseModal
        isOpen={open}
        onSuccess={hasPaymentError ? handleEditPaymentMethod : onSuccess}
        onCancel={() => setOpen(false)}
        dialogTitle={
          <div className="text-red-500 flex items-center space-x-2">
            <ExclamationTriangleIcon className="h-5 w-5" />{' '}
            <span className="font-medium">Subscription Renewal Overdue</span>
          </div>
        }
        cancelButtonText="Cancel"
        successButtonText={
          hasPaymentError ? 'Update Payment Method' : 'Pay Now'
        }
        successButtonHref={payment.invoice_url ?? undefined}
        isLoading={isSendingEmail}
      >
        <div className="mt-4 space-y-4">
          <p className="text-gray-600 text-sm">
            To maintain access to your dashboard, please pay the platform fee by{' '}
            <span className="font-medium">{limitedAccessDate}</span>. If payment
            isn't received, your subscription will be cancelled and you'll lose
            access.
          </p>
          {payment.invoice_download_url && (
            <p className="text-sm">
              Prefer an invoice?{' '}
              <a
                href={payment.invoice_download_url}
                target="_blank"
                rel="noreferrer"
              >
                Download here
              </a>
            </p>
          )}
        </div>
      </BaseModal>
      {hasPaymentError && (
        <ModalNoCancel
          isOpen={isPaymentMethodEmailSentModalOpen}
          setIsOpen={setIsPaymentMethodEmailSentModalOpen}
          successButtonText={'OK'}
          onSuccess={() => setIsPaymentMethodEmailSentModalOpen(false)}
          dialogTitle={'Email sent!'}
          dialogDescription={
            'We have sent you an email with a secure link to the page where you can update your payment details.'
          }
        />
      )}
    </>
  );
};

interface RenewalOverdueProps {
  daysLeft: number;
  payment: IPlatformSubscriptionPaymentDetails;
}

function RenewalOverdue({ daysLeft, payment }: RenewalOverdueProps) {
  const [isOverdueModalOpen, setIsOverdueModalOpen] = useState(false);

  return (
    <>
      <Banner
        daysLeft={daysLeft}
        onOpenModal={() => setIsOverdueModalOpen(true)}
      />
      <Modal
        open={isOverdueModalOpen}
        setOpen={setIsOverdueModalOpen}
        onSuccess={() => setIsOverdueModalOpen(false)}
        payment={payment}
      />
    </>
  );
}

export default RenewalOverdue;
