import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';

import { Dialog, Transition } from '@headlessui/react';
import { EyeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { format, parseISO } from 'date-fns';

import { ReactComponent as BlankProfileAvatar } from '@/assets/svg/default_avatar.svg';
import COLORS from '@/constants/colors';
import { getFullName } from '@/helpers/strings';
import IContacts from '@/types/IContacts';

import Button, { BUTTON_KIND } from './Button';
import { ProfileInfoCell } from './ProfileListItem';
import { ProfilePreview } from './ProfilePreview';

type ContactsListItemProps = {
  contact: IContacts;
};

export function ContactsListItem({ contact }: ContactsListItemProps) {
  const {
    profile,
    email,
    first_name: firstName,
    last_name: lastName,
    job_title: jobTitle,
    company_name: companyName,
    inserted_at,
  } = contact;

  const isProfileDeactivated = ['cleared', 'deleted'].includes(profile.status);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const getStatusPrefix = () => {
    if (profile.status === 'cleared') {
      return '(Cleared) ';
    } else if (profile.status === 'deleted') {
      return '(Deactivated) ';
    } else {
      return '';
    }
  };

  const profileFullName = getFullName({
    firstName: profile.first_name,
    middleName: profile.middle_name,
    lastName: profile.last_name,
  });

  const fullName = getFullName({
    firstName,
    lastName,
  });

  const insertedAt = format(parseISO(inserted_at), 'dd/MM/yy');

  return (
    <>
      <ContactPreview
        contact={contact}
        isOpen={isPreviewOpen}
        setIsOpen={setIsPreviewOpen}
      />
      <tr className="hover:bg-gray-50 relative cursor-pointer">
        <td className="whitespace-nowrap text-sm py-3 px-4">
          <span className="flex items-center space-x-3">
            <BlankProfileAvatar className="rounded-full" />
            <span className="flex flex-col">
              <span className="font-medium text-sm text-gray-900 truncate">
                {fullName}
              </span>
              <span className="text-sm text-gray-500">{email}</span>
            </span>
          </span>
        </td>
        <td className="whitespace-nowrap text-sm p-3">
          <span className="flex flex-col">
            <span className="font-medium text-sm text-gray-900 truncate">
              {jobTitle}
            </span>
            <span className="text-sm text-gray-500 truncate">
              {companyName}
            </span>
          </span>
        </td>
        <td className="whitespace-nowrap p-3">
          <ProfileInfoCell
            fullName={`${profileFullName}${
              isProfileDeactivated ? ` ${getStatusPrefix()}` : ''
            }`}
            profileHash={profile.profile_hash}
            photoUrl={profile.photo?.thumb_url}
            blurHash={profile.photo?.blur_hash}
          />
        </td>
        <td className="whitespace-nowrap text-sm p-3 text-gray-900">
          {insertedAt}
        </td>
        <td className="whitespace-nowrap text-sm py-3">
          <span className="flex justify-center">
            <Button
              icon={<EyeIcon />}
              kind={BUTTON_KIND.COLOR_OMITTED}
              onClick={() => setIsPreviewOpen(true)}
            />
          </span>
        </td>
      </tr>
    </>
  );
}

export function ContactsListItemMobile({ contact }: ContactsListItemProps) {
  const {
    first_name: firstName,
    last_name: lastName,
    email,
    job_title: jobTitle,
    company_name: companyName,
    inserted_at,
    profile,
  } = contact;

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const profileFullName = getFullName({
    firstName: profile.first_name,
    lastName: profile.last_name,
  });
  const fullName = getFullName({
    firstName,
    lastName,
  });
  const insertedAt = format(parseISO(inserted_at), 'dd/MM/yy');

  const contactData = [
    {
      title: 'Name',
      value: fullName,
      visibility: !!fullName,
    },
    { title: 'Email', value: email, visibility: !!email },
    { title: 'Position', value: jobTitle, visibility: !!jobTitle },
    { title: 'Company', value: companyName, visibility: !!companyName },
  ];

  return (
    <>
      <ContactPreview
        contact={contact}
        isOpen={isPreviewOpen}
        setIsOpen={setIsPreviewOpen}
      />
      <div className="border border-gray-300 bg-white rounded-md flex flex-col divide-y divide-gray-300">
        <div className="flex flex-col space-y-4 px-6 py-4">
          <div className="flex justify-between w-full">
            <ProfileInfoCell
              fullName={profileFullName}
              profileHash={contact.profile.profile_hash}
              photoUrl={contact.profile.photo?.thumb_url}
              blurHash={contact.profile.photo?.blur_hash}
            />
            <span className="text-sm font-poppins font-medium">
              {insertedAt}
            </span>
          </div>
          <div className="space-y-4">
            {contactData.map((item, index) => (
              <div
                key={index}
                className="justify-between text-sm"
                style={{
                  display: item.visibility ? 'flex' : 'none',
                }}
              >
                <span className="text-gray-900 font-medium">{item.title}</span>
                <span className="text-gray-500">{item.value}</span>
              </div>
            ))}
          </div>
        </div>
        <button
          type="button"
          className="flex items-center justify-center gap-x-2 text-gray-700 font-poppins font-medium text-sm py-3"
          onClick={() => setIsPreviewOpen(true)}
        >
          <EyeIcon className="w-5 h-5 text-gray-900" />
          View
        </button>
      </div>
    </>
  );
}

type ContactPreviewProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  contact: IContacts;
};

function ContactPreview({ isOpen, setIsOpen, contact }: ContactPreviewProps) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" static open={isOpen} onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="fixed top-0 right-0">
            <div className="h-[100dvh] w-[320px] md:w-[356px] bg-white p-6 space-y-4 flex flex-col justify-center">
              <button
                type="button"
                className="border border-gray-300 rounded-full w-fit p-1 place-self-end hover:bg-gray-50"
                onClick={() => setIsOpen(false)}
              >
                <XMarkIcon className="w-6 h-6 text-gray-500" />
              </button>
              <ProfilePreview
                settings={{
                  companyLogo: null,
                  bgColor: COLORS.organisation.defaultProfile.backgroundColor,
                  textColor: COLORS.organisation.defaultProfile.textColor,
                  buttonBgColor:
                    COLORS.organisation.defaultProfile.buttonBackgroundColor,
                  buttonTextColor:
                    COLORS.organisation.defaultProfile.buttonTextColor,
                }}
                content={{
                  first_name: contact.first_name,
                  last_name: contact.last_name,
                  job_title: contact.job_title,
                  email: contact.email,
                  mobile_number: contact.phone_number,
                  pronouns: contact.pronouns,
                  company_name: contact.company_name,
                  company_phone_number: contact.company_phone_number,
                  company_website: contact.company_website,
                  street_address: contact.street_address,
                  city: contact.city,
                  state: contact.state,
                  country: contact.country,
                  post_code: contact.post_code,
                  profile_social_links: contact.contact_social_links.map(
                    (link, index) => ({
                      ...link,
                      order: index,
                      personal: false,
                    }),
                  ),
                }}
              />
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
