import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/outline';

type InfoPanelContainerProps = {
  additionalContent?: ReactNode;
  title?: string;
  description?: string;
  hint?: string;
  dialog?: ReactNode;
  isRadio?: boolean;
  footerContent?: ReactNode;
} & PropsWithChildren &
  HTMLAttributes<HTMLDivElement>;

export function InfoPanelContainer({
  title,
  isRadio,
  children,
  hint,
  description,
  dialog,
  footerContent,
  additionalContent,
  ...props
}: InfoPanelContainerProps) {
  return (
    <div className="md:grid md:grid-cols-3 gap-x-4" {...props}>
      <div className="md:col-span-1 space-y-2">
        {title && (
          <span className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </span>
        )}
        {description && <p className="text-sm text-gray-500">{description}</p>}
        {hint && (
          <p className="text-sm text-gray-500">
            <b>Hint:</b>
            {hint}
          </p>
        )}
        {additionalContent && <span>{additionalContent}</span>}
        {dialog && (
          <div className="flex rounded-md bg-brand-100 text-white p-4 gap-x-3">
            <InformationCircleIcon
              className="h-5 w-5 text-gray-900 flex-shrink-0"
              aria-hidden="true"
            />
            <span>{dialog}</span>
          </div>
        )}
      </div>
      <div className={title ? 'col-span-2' : 'col-span-3'}>
        {isRadio ? (
          children
        ) : (
          <div className="border border-gray-300 rounded-md">
            <div className="bg-white rounded-md p-4 md:p-6">{children}</div>
            {footerContent && (
              <div className="px-6 py-3 border-t border-gray-200 text-right bg-white rounded-b-md space-x-3 flex justify-end">
                {footerContent}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
