import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useQuery } from '@tanstack/react-query';
import { paragon } from '@useparagon/connect';
import clsx from 'clsx';

import ssoApi from '@/api/sso';
import ActiveDirectoryLogo from '@/assets/images/active-directory.png';
import SSOLogo from '@/assets/svg/logos/sso-logo.svg';
import Button, { BUTTON_KIND } from '@/components/Button';
import { InfoPanelContainer } from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import ActiveDirectory from '@/components/IntegrationsPage/ActiveDirectory';
import {
  SsoConfigConnect,
  SsoConfigDisconnect,
} from '@/components/IntegrationsPage/SsoConfig';
import useAuth from '@/hooks/useAuth';
import useParagonAuth from '@/hooks/useParagonAuth';

function CRMLoadingSkeleton() {
  const integrations = ['Hubspot', 'Salesforce', 'Dynamic 365 Sales'];

  return (
    <div>
      {integrations.map((integration, index) => (
        <div
          key={index}
          className={clsx('p-4 flex flex-row justify-between', {
            'border-b border-gray-200': index !== integrations.length - 1,
          })}
        >
          <div className="flex flex-col gap-3 ">
            <div className="flex flex-row items-center gap-3">
              <Skeleton width={50} height={40} />
              <div className="font-medium capitalize">{integration}</div>
            </div>
            <div className="xl:hidden">
              <Skeleton width={114} style={{ borderRadius: '999px' }} />
            </div>
          </div>
          <div className="flex flex-col xl:flex-row items-end xl:items-center gap-2">
            <div className="hidden xl:block">
              <Skeleton
                width={114}
                style={{
                  borderRadius: '999px',
                }}
              />
            </div>
            <Button buttonText="Configure" kind={BUTTON_KIND.WHITE} disabled />
          </div>
        </div>
      ))}
    </div>
  );
}

export function IntegrationSettings() {
  const { orgID } = useAuth();
  const { user } = useParagonAuth();

  const [isSsoDisconnectOpen, setIsSsoDisconnectOpen] = useState(false);
  const [isSsoConnectOpen, setIsSsoConnectOpen] = useState(false);
  const [isActiveDirectoryConfigOpen, setIsActiveDirectoryConfigOpen] =
    useState(false);

  const { data: samlConfig, isFetching: isSamlLoading } = useQuery({
    queryKey: ['samlConfig', isSsoConnectOpen, isSsoDisconnectOpen],
    queryFn: getSamlConfig,
    enabled: orgID !== undefined,
  });

  const paragonIntegrations = paragon.getIntegrationMetadata();
  const integrations =
    paragonIntegrations.length === 0 ? undefined : paragonIntegrations;
  const otherIntegrations = [
    {
      key: 'active-directory',
      name: 'Active Directory',
      icon: ActiveDirectoryLogo,
      isLoading: false,
      enabled: false,
      onClick: () => setIsActiveDirectoryConfigOpen(true),
    },
    {
      key: 'sso',
      name: 'Single Sign-On SAML',
      icon: SSOLogo,
      isLoading: isSamlLoading,
      enabled: samlConfig?.is_active || false,
      onClick: samlConfig?.is_active
        ? () => setIsSsoDisconnectOpen(true)
        : () => setIsSsoConnectOpen(true),
    },
  ];

  async function getSamlConfig() {
    if (orgID === undefined) {
      return;
    }

    const { data: samlConfig } = await ssoApi.getSamlConfig(orgID);

    return samlConfig;
  }

  return (
    <div className="pt-6">
      <InfoPanelContainer
        title="CRM Integrations"
        description="Connect your CRM with Tapt to streamline lead management. Contacts collected using Tapt will sync automatically with your CRM."
      >
        {integrations ? (
          integrations.map((integration, index) => (
            <div
              key={index}
              className={clsx('flex flex-row justify-between p-4', {
                'border-b border-gray-200': index !== integrations.length - 1,
              })}
            >
              <div className="flex flex-col justify-center gap-3">
                <div className="flex flex-row items-center gap-3">
                  <img
                    src={integration.icon}
                    alt={`${integration.icon} icon`}
                    className="w-10 self-start xl:self-center"
                  />
                  <div className="font-medium capitalize">
                    {integration.name}
                  </div>
                </div>
                {user === undefined ? (
                  <div className="xl:hidden">
                    <Skeleton width={114} style={{ borderRadius: '999px' }} />
                  </div>
                ) : user.integrations[integration.type]?.enabled ? (
                  <div className="bg-emerald-100 rounded-full py-0.5 px-3 text-xs flex xl:hidden items-center w-max">
                    <span className="text-emerald-900 font-medium">
                      Connected
                    </span>
                  </div>
                ) : (
                  <div className="bg-gray-100 rounded-full py-0.5 px-3 text-xs xl:hidden flex items-center w-max">
                    <span className="text-gray-800 font-medium">
                      Not connected
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-row items-center gap-3">
                <div className="flex flex-col xl:flex-row items-center gap-2 h-full">
                  {user === undefined ? (
                    <span className="hidden xl:block">
                      <Skeleton
                        width={90}
                        style={{
                          borderRadius: '999px',
                        }}
                      />
                    </span>
                  ) : user.integrations[integration.type]?.enabled ? (
                    <div className="bg-emerald-100 rounded-full py-0.5 px-3 text-xs hidden xl:flex items-center self-end xl:self-center">
                      <span className="text-emerald-900 font-medium">
                        Connected
                      </span>
                    </div>
                  ) : (
                    <div className="bg-gray-100 rounded-full py-0.5 px-3 text-xs hidden xl:flex items-center self-end xl:self-center">
                      <span className="text-gray-800 font-medium">
                        Not connected
                      </span>
                    </div>
                  )}
                  <Button
                    buttonText="Configure"
                    kind={BUTTON_KIND.WHITE}
                    onClick={() => paragon.connect(integration.type, {})}
                  />
                </div>
              </div>
            </div>
          ))
        ) : (
          <CRMLoadingSkeleton />
        )}
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Other"
        description="Link Tapt with other software partners to enhance security and privacy."
      >
        {otherIntegrations.map((integration, index) => (
          <div
            key={index}
            className={clsx('flex flex-row justify-between p-4', {
              'border-b border-gray-200':
                index !== otherIntegrations.length - 1,
            })}
          >
            <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center gap-3">
                <img
                  src={integration.icon}
                  alt={`${integration.icon} icon`}
                  className="w-10 self-start xl:self-center"
                />
                <div className="font-medium">{integration.name}</div>
              </div>
              {integration.isLoading ? (
                <Skeleton width={114} style={{ borderRadius: '999px' }} />
              ) : (
                <>
                  {integration.key === 'sso' && integration.enabled && (
                    <div className="bg-emerald-100 rounded-full py-0.5 px-3 text-xs xl:hidden items-center w-max">
                      <span className="text-emerald-900 font-medium">
                        Connected
                      </span>
                    </div>
                  )}
                  {integration.key === 'sso' && !integration.enabled && (
                    <div className="bg-gray-100 rounded-full py-0.5 px-3 text-xs xl:hidden items-center w-max">
                      <span className="text-gray-800 font-medium">
                        Not connected
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            {integration.isLoading ? (
              <div className="flex flex-row items-center gap-2">
                <div className="hidden xl:block">
                  <Skeleton
                    width={114}
                    style={{
                      borderRadius: '999px',
                    }}
                  />
                </div>
                <Skeleton width={103} height={38} />
              </div>
            ) : (
              <div className="flex flex-col xl:flex-row items-center gap-2">
                {integration.key === 'sso' && integration.enabled && (
                  <div className="bg-emerald-100 rounded-full py-0.5 px-3 text-xs hidden xl:flex">
                    <span className="text-emerald-900 font-medium">
                      Connected
                    </span>
                  </div>
                )}
                {integration.key === 'sso' && !integration.enabled && (
                  <div className="bg-gray-100 rounded-full py-0.5 px-3 text-xs hidden xl:flex">
                    <span className="text-gray-800 font-medium">
                      Not connected
                    </span>
                  </div>
                )}
                <Button
                  buttonText={integration.enabled ? 'Disconnect' : 'Configure'}
                  kind={
                    integration.enabled ? BUTTON_KIND.RED : BUTTON_KIND.WHITE
                  }
                  onClick={integration.onClick}
                />
              </div>
            )}
          </div>
        ))}
      </InfoPanelContainer>

      {isSsoConnectOpen && (
        <SsoConfigConnect
          isOpen={isSsoConnectOpen}
          setIsOpen={setIsSsoConnectOpen}
          samlConfig={samlConfig}
        />
      )}
      {isSsoDisconnectOpen && (
        <SsoConfigDisconnect
          isOpen={isSsoDisconnectOpen}
          setIsOpen={setIsSsoDisconnectOpen}
          samlConfig={samlConfig}
        />
      )}
      <ActiveDirectory
        isOpen={isActiveDirectoryConfigOpen}
        setIsOpen={setIsActiveDirectoryConfigOpen}
      />
    </div>
  );
}
