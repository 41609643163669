import { useState } from 'react';

import { ShareIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { useWindowSize } from 'usehooks-ts';

import organisationsAPI from '@/api/organisations';
import profilesAPI from '@/api/profiles';
import Button, { BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import LoadingAnimation from '@/components/LoadingAnimation';
import Modal from '@/components/Modals/Modal';
import ModalFullWidth from '@/components/Modals/ModalFullWidth';
import Pagination from '@/components/Pagination';
import {
  ProfileListGroupItem,
  ProfileListGroupItemMobile,
} from '@/components/ProfileListGroupItem';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';

type ShareWithTeamButtonProps = {
  action: (
    orgID: number,
    body: {
      profile_ids: number[];
    },
  ) => Promise<AxiosResponse>;
  onSuccessCallback?: () => void;
  modalTitle: string;
};

export function ShareWithTeamButton({
  action,
  onSuccessCallback,
  modalTitle,
}: ShareWithTeamButtonProps) {
  const { orgID } = useAuth();
  const { width } = useWindowSize();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState<Set<number>>(
    new Set(),
  );

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [order, setOrder] = useState('date');
  const [sort, setSort] = useState('desc');
  const [isSending, setIsSending] = useState(false);

  const { data: profiles } = useQuery({
    queryKey: ['listProfiles', orgID, search, page, pageSize, sort, order],
    queryFn: () => listProfiles(search, page, pageSize, sort, order),
    enabled: orgID !== undefined,
  });

  async function listProfiles(
    search: string,
    page: number,
    pageSize: number,
    sort: string,
    order: string,
  ) {
    const res = await profilesAPI.listProfilesWithValidEmail({
      orgID,
      search,
      page,
      pageSize,
      sort,
      order,
    });

    return res.data;
  }

  async function sendEmailSignature() {
    if (orgID === undefined) {
      return;
    }

    setIsSending(true);

    await action(orgID, {
      profile_ids: Array.from(selectedProfiles),
    });

    onSuccessCallback?.();
    setIsSending(false);
    setIsModalOpen(false);
  }

  function onCheckItem(profileID: number) {
    setSelectedProfiles(prev => {
      const next = new Set(prev);
      if (prev.has(profileID)) {
        next.delete(profileID);
      } else {
        next.add(profileID);
      }
      return next;
    });
  }

  const buttonText = width > 1320 ? 'Share with team' : 'Share';

  return (
    <>
      <Button
        buttonText={buttonText}
        icon={<ShareIcon />}
        kind={BUTTON_KIND.WHITE}
        onClick={() => setIsModalOpen(true)}
      />
      <ModalFullWidth
        dialogTitle={modalTitle}
        dialogDescription="Only profiles who have provided an email address will appear in the table below."
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        isLoading={isSending}
        successButtonText="Send via email"
        onClose={() => {
          setSelectedProfiles(new Set());
          setIsModalOpen(false);
        }}
        onSuccess={async () => {
          await sendEmailSignature();
          setSelectedProfiles(new Set());
        }}
        footerText={`${selectedProfiles.size} selected`}
      >
        <div className="flex justify-end w-full space-x-3 py-4">
          <Search
            id={`send-email-signature-${page}-${pageSize}-${sort}-${order}-${search}`}
            search={search}
            setSearch={setSearch}
            fetchQuery={search =>
              listProfiles(search, page, pageSize, sort, order)
            }
          />
          <Sort
            id={`send-email-signature-${page}-${pageSize}-${sort}-${order}-${search}`}
            options={[
              {
                sort: 'date',
                order: 'desc',
                label: 'Newest first',
              },
              {
                sort: 'date',
                order: 'asc',
                label: 'Oldest first',
              },
              {
                sort: 'first_name',
                order: 'asc',
                label: 'Name (A-Z)',
              },
              {
                sort: 'first_name',
                order: 'desc',
                label: 'Name (Z-A)',
              },
            ]}
            sort={sort}
            setSort={setSort}
            order={order}
            setOrder={setOrder}
            fetchQuery={(sortQuery, orderQuery) =>
              listProfiles(search, page, pageSize, sortQuery, orderQuery)
            }
          />
        </div>
        {profiles ? (
          profiles.data.length > 0 ? (
            <div className="flex flex-col relative gap-6">
              <div className="hidden xl:block shadow-sm border border-gray-200 min-h-8 max-h-[450px] overflow-y-scroll overflow-hidden">
                <table
                  className={clsx('w-full', {
                    'opacity-40': isPaginationLoading,
                  })}
                >
                  <thead
                    className="bg-gray-100 border-b border-gray-200 sticky top-0"
                    style={{ zIndex: 2 }}
                  >
                    <tr className="uppercase text-gray-900 text-sm">
                      <th scope="col" className="py-3 pl-6">
                        <InputCheckbox
                          id="send-email-signature-modal-select-all"
                          value={isAllSelected}
                          onChange={selected => {
                            setIsAllSelected(selected);
                            if (selected) {
                              setSelectedProfiles(
                                new Set(profiles.data.map(({ id }) => id)),
                              );
                            } else {
                              setSelectedProfiles(new Set());
                            }
                          }}
                        />
                      </th>
                      <th
                        scope="col"
                        className="font-medium py-3 px-6 text-left"
                      >
                        Name &amp; profile id
                      </th>
                      <th
                        scope="col"
                        className="font-medium py-3 px-6 text-left"
                      >
                        Contact information
                      </th>
                      <th
                        scope="col"
                        className="font-medium py-3 px-6 text-left"
                      >
                        Group
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {profiles.data.map((profile, index) => (
                      <ProfileListGroupItem
                        key={index}
                        profile={profile}
                        selected={selectedProfiles.has(profile.id)}
                        checkItem={() => onCheckItem(profile.id)}
                      />
                    ))}
                  </tbody>
                </table>
                {isPaginationLoading && (
                  <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                  </div>
                )}
              </div>
              <div className="xl:hidden">
                <div className="bg-gray-100 border border-gray-300 rounded-md px-6 flex flex-row font-medium uppercase divide-x divide-gray-300">
                  <InputCheckbox
                    id="select-all-add-profiles-modal"
                    label=""
                    value={isAllSelected}
                    onChange={selected => {
                      setIsAllSelected(selected);
                      if (selected) {
                        setSelectedProfiles(
                          new Set(profiles.data.map(({ id }) => id)),
                        );
                      } else {
                        setSelectedProfiles(new Set());
                      }
                    }}
                  />
                  <span className="pl-2 py-3">
                    Name, profile id, contact information, &amp; group
                  </span>
                </div>
                <div className="max-h-[calc(100vh-50vh)] overflow-y-scroll overflow-hidden">
                  {profiles.data.map((profile, index) => (
                    <ProfileListGroupItemMobile
                      key={index}
                      profile={profile}
                      selected={selectedProfiles.has(profile.id)}
                      checkItem={() => onCheckItem(profile.id)}
                    />
                  ))}
                </div>
              </div>
              <Pagination
                id={`send-email-signature-${page}-${pageSize}-${sort}-${order}-${search}`}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                setIsLoading={setIsPaginationLoading}
                fetchQuery={(pageQuery, pageSizeQuery) =>
                  listProfiles(search, pageQuery, pageSizeQuery, sort, order)
                }
              />
            </div>
          ) : (
            <div className="flex flex-col items-center py-32 space-y-2">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.profile.list.empty.heading}
              </h3>
            </div>
          )
        ) : (
          <div className="h-56 flex items-center">
            <LoadingAnimation className="w-16 h-16 mx-auto" />
          </div>
        )}
      </ModalFullWidth>
    </>
  );
}

type SendEmailSignatureProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  checkedProfiles: number[];
  onSuccessCallback?: () => void;
};

export function SendEmailSignatureModal({
  isOpen,
  setIsOpen,
  checkedProfiles,
  onSuccessCallback,
}: SendEmailSignatureProps) {
  const { orgID } = useAuth();

  const [isSending, setIsSending] = useState(false);

  async function sendPass() {
    if (orgID === undefined) {
      return;
    }

    setIsSending(true);

    await organisationsAPI.shareEmailSignature(orgID, {
      profile_ids: checkedProfiles,
    });

    setIsSending(false);
    setIsOpen(false);
    onSuccessCallback?.();
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle="Share email signature with profile holders via email"
      dialogDescription="An email will be sent to the selected profile holders inviting them to add their Tapt email signature."
      successButtonText="Send email signature"
      onSuccess={sendPass}
      isLoading={isSending}
    />
  );
}
