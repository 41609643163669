import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { ReactComponent as BlankProfileAvatar } from '@/assets/svg/default_avatar.svg';

import { ProgressiveImage } from '../Image';

type ProfileDisplayProps = {
  photoUrl: string | null | undefined;
  fullName: string | null;
  email: string | null;
  isDeleted: boolean;
  blurHash?: string | null;
};

export const ProfileDisplay = ({
  photoUrl,
  fullName,
  email,
  isDeleted,
  blurHash,
}: ProfileDisplayProps) => {
  if (isDeleted) {
    const withFullName = fullName ? `: ${fullName}` : '';
    return (
      <div className="flex flex-row flex-shrink-0 items-center space-x-3">
        <div className="w-10 h-10 rounded-full bg-red-450 flex items-center justify-center">
          <ExclamationTriangleIcon
            className="size-7 text-red-100"
            strokeWidth={2}
          />
        </div>
        <div className="flex flex-col truncate">
          <span className="text-red-800 text-sm font-medium truncate">
            Deleted profile{withFullName}
          </span>
          <span className="text-sm text-red-800 truncate">
            Assign new profile to continue using the product.
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row flex-shrink-0 items-center space-x-3">
      {photoUrl ? (
        <ProgressiveImage
          source={photoUrl}
          blurHash={blurHash}
          size={40}
          alt="Profile image"
        />
      ) : (
        <BlankProfileAvatar />
      )}
      <div className="flex flex-col truncate">
        {fullName && (
          <span className="text-gray-900 text-sm font-medium truncate">
            {fullName}
          </span>
        )}
        {email && (
          <span className="text-sm text-gray-500 truncate">{email}</span>
        )}
      </div>
    </div>
  );
};
