import { useState } from 'react';

import { ArrowDownTrayIcon as DownloadIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';

import profilesAPI from '@/api/profiles';
import MESSAGES from '@/constants/messages-en';
import { downloadBlob } from '@/helpers/file';
import useAuth from '@/hooks/useAuth';

import CsvFileUploadSteps from '.';
import { BUTTON_KIND } from '../Button';

export function EditViaCSV() {
  const { orgID } = useAuth();

  const [importCSV, setImportCSV] = useState<File | undefined>(undefined);

  const { isPending } = useMutation({
    mutationFn: handleEditViaCSV,
  });

  async function handleEditViaCSV() {
    if (orgID === undefined || importCSV === undefined) {
      return;
    }

    const formData = new FormData();
    formData.append('file', importCSV);
    formData.append('type', 'profile');

    await profilesAPI.importProfilesCSV(orgID, formData);
  }

  async function exportCSV() {
    if (orgID === undefined) {
      return;
    }

    const response = await profilesAPI.exportProfilesCSV(orgID);
    const blob = new Blob([response.data]);

    downloadBlob(blob, 'cards.csv');
  }

  return (
    <CsvFileUploadSteps
      buttonTitle="Edit via CSV"
      buttonKind={BUTTON_KIND.PRIMARY}
      dialogTitle={MESSAGES.profile.import.heading}
      dialogDescription={MESSAGES.profile.import.description}
      isLoading={isPending}
      onSuccess={handleEditViaCSV}
      setImportCSV={setImportCSV}
    >
      <div className="flex items-center">
        <span className="pr-2 text-sm font-normal leading-5 text-gray-500">
          Don't have a CSV file?
        </span>
        &nbsp;
        <button
          type="button"
          className="flex items-center text-sm font-medium leading-5 text-brand-500"
          onClick={exportCSV}
        >
          <span className="pr-1">Export a CSV</span>
          <DownloadIcon width={27} height={27} className="text-gray-300" />
        </button>
      </div>
    </CsvFileUploadSteps>
  );
}
