import { UserIcon } from '@heroicons/react/24/outline';

import { ReactComponent as Behance } from '@/assets/svg/social/Behance.svg';
import { ReactComponent as Discord } from '@/assets/svg/social/Discord.svg';
import { ReactComponent as Facebook } from '@/assets/svg/social/Facebook.svg';
import { ReactComponent as GoogleMyBusiness } from '@/assets/svg/social/GoogleMyBusiness.svg';
import { ReactComponent as Instagram } from '@/assets/svg/social/Instagram.svg';
import { ReactComponent as LinkedIn } from '@/assets/svg/social/LinkedIn.svg';
import { ReactComponent as PayPal } from '@/assets/svg/social/PayPal.svg';
import { ReactComponent as Pinterest } from '@/assets/svg/social/Pinterest.svg';
import { ReactComponent as Threads } from '@/assets/svg/social/Threads.svg';
import { ReactComponent as TikTok } from '@/assets/svg/social/TikTok.svg';
import { ReactComponent as Twitch } from '@/assets/svg/social/Twitch.svg';
import { ReactComponent as Twitter } from '@/assets/svg/social/Twitter.svg';
import { ReactComponent as WeChat } from '@/assets/svg/social/WeChat.svg';
import { ReactComponent as WhatsApp } from '@/assets/svg/social/Whatsapp.svg';
import { ReactComponent as YouTube } from '@/assets/svg/social/YouTube.svg';
import { ReactComponent as PersonalSocialBackground } from '@/assets/svg/social/personal-social-bg.svg';
import { hexToRgba } from '@/helpers/strings';
import { IProfileSocialLink } from '@/types/IProfile';

export const SocialIconMap = {
  linkedin: LinkedIn,
  facebook: Facebook,
  twitter: Twitter,
  instagram: Instagram,
  youtube: YouTube,
  tiktok: TikTok,
  whatsapp: WhatsApp,
  pinterest: Pinterest,
  googlemybusiness: GoogleMyBusiness,
  threads: Threads,
  discord: Discord,
  twitch: Twitch,
  paypal: PayPal,
  wechat: WeChat,
  behance: Behance,
} as const;

type ProfileSocialLinksProps = {
  links: IProfileSocialLink[];
  textColor: string;
};

export function ProfileSocialLinks({
  links,
  textColor,
}: ProfileSocialLinksProps) {
  return (
    <div className="flex flex-wrap gap-2">
      {links.map(({ type, personal }, index) => {
        const Icon = SocialIconMap[type];

        if (personal) {
          return (
            <div className="relative" key={index}>
              <PersonalSocialBackground
                className="w-12 h-12 opacity-20"
                fill={hexToRgba(textColor, 0.2)}
              />
              <div className="absolute top-[3px] left-[34px]">
                <UserIcon
                  width="10"
                  height="10"
                  color={textColor}
                  strokeWidth={2}
                />
              </div>
              <div
                className="w-12 h-12 flex align-middle justify-center transition-all duration-300 absolute top-0 left-0 items-center"
                style={{ color: textColor }}
              >
                <Icon width="20" height="20" fill={textColor} />
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className="w-12 h-12 p-2 rounded-full flex items-center justify-center"
              style={{ backgroundColor: hexToRgba(textColor, 0.04) }}
            >
              <Icon width="20" height="20" fill={textColor} />
            </div>
          );
        }
      })}
    </div>
  );
}
