import { ContactInfo } from '@/components/Templates/EmailSignature/ContactInfo';
import { ProfileImage } from '@/components/Templates/EmailSignature/ProfileImage';
import {
  CONTENT_MAX_WIDTH,
  DisclaimerText,
  JobTitle,
  NameTitle,
  QRCode,
  SocialIcons,
} from '@/components/Templates/EmailSignature/SignatureElements';
import { formatPhoneNumber, getFullName } from '@/helpers/strings';
import {
  IEmailSignature,
  IEmailSignatureAssets,
  SupportedClient,
} from '@/types/IOrganisation';
import { IProfileSignature } from '@/types/IProfile';

import { Banner } from './Banner';

interface PreviewProps {
  profile: IProfileSignature;
  signature: IEmailSignature;
  assets: IEmailSignatureAssets;
  clientType?: SupportedClient;
  sampleMode?: boolean;
}

const addRef = (url: string, ref: string) => {
  const urlObj = new URL(url);
  urlObj.searchParams.set('ref', ref);
  return urlObj.toString();
};

export function SimplifiedEmailSignaturePreview({
  profile,
  signature,
  assets,
  sampleMode = false,
}: PreviewProps) {
  const urlForQrCode = addRef(profile.profile_page_url, 'email-signature-qr');
  const urlForProfileImage = addRef(
    profile.profile_page_url,
    'email-signature-profile-image',
  );

  const fullName = getFullName({
    firstName: profile.first_name,
    middleName: profile.middle_name,
    lastName: profile.last_name,
  });
  const jobTitle = signature.show_job_title ? profile.job_title : null;
  const companyName = signature.show_company_name ? profile.company_name : null;
  const position = [jobTitle, companyName].filter(Boolean).join(' at ');
  const mobileNumber = formatPhoneNumber(profile.mobile_number);
  const address = signature.show_company_address
    ? [
        profile.street_address,
        profile.city,
        profile.state,
        profile.post_code,
        profile.country,
      ]
        .filter(Boolean)
        .join(', ')
    : null;

  return (
    <table
      dir="ltr"
      width="600"
      style={{ marginTop: '16px' }}
      cellSpacing="0"
      cellPadding="0"
    >
      <tbody>
        <tr>
          <td>
            <table cellSpacing="0" cellPadding="0">
              <tbody>
                <tr>
                  <td style={{ paddingRight: '8px' }} valign="top">
                    <table cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td>
                            <table cellSpacing="0" cellPadding="0">
                              <tbody>
                                <tr>
                                  {!sampleMode && assets?.profile_image_url && (
                                    <ProfileImage
                                      profilePageUrl={urlForProfileImage}
                                      imgSrc={assets?.profile_image_url}
                                      blurHash={profile.photo?.blur_hash}
                                      logoImgSrc={
                                        signature.logo_file?.original_url
                                      }
                                      sampleMode={sampleMode}
                                      size={157}
                                      logoSize={40}
                                    />
                                  )}
                                  {sampleMode &&
                                    (signature.show_profile_image ||
                                      signature.logo_file) && (
                                      <ProfileImage
                                        profilePageUrl={urlForProfileImage}
                                        imgSrc={
                                          signature.show_profile_image
                                            ? assets?.profile_image_url
                                            : null
                                        }
                                        logoImgSrc={
                                          signature.logo_file?.original_url
                                        }
                                        blurHash={profile?.photo?.blur_hash}
                                        sampleMode={sampleMode}
                                        size={157}
                                        logoSize={40}
                                      />
                                    )}
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td valign="top">
                    <table cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td>
                            <table cellSpacing="0" cellPadding="0">
                              <tbody>
                                <tr>
                                  <td style={{ paddingBottom: '24px' }}>
                                    <table cellSpacing="0" cellPadding="0">
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ paddingRight: '24px' }}
                                            valign="top"
                                          >
                                            <table
                                              cellSpacing="0"
                                              cellPadding="0"
                                            >
                                              <tbody>
                                                <tr>
                                                  {signature.show_full_name &&
                                                    fullName && (
                                                      <td
                                                        style={{
                                                          fontFamily:
                                                            'Verdana, Geneva, sans-serif',
                                                          fontWeight: 700,
                                                          lineHeight: '22px',
                                                          height: '22px',
                                                          textAlign: 'left',
                                                          paddingBottom: '8px',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              signature.primary_text_color ||
                                                              '#873bfe',
                                                            fontSize: '16px',
                                                          }}
                                                        >
                                                          {fullName}
                                                        </span>
                                                      </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                  {position && (
                                                    <td
                                                      style={{
                                                        fontFamily:
                                                          'Verdana, Geneva, sans-serif',
                                                        lineHeight: '22px',
                                                        height: '22px',
                                                        textAlign: 'left',
                                                        paddingBottom: '16px',
                                                        maxWidth:
                                                          CONTENT_MAX_WIDTH,
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            signature.text_color,
                                                          fontSize: '14px',
                                                          fontWeight: 400,
                                                        }}
                                                      >
                                                        {position}
                                                      </span>
                                                    </td>
                                                  )}
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <table
                                                      cellSpacing="0"
                                                      cellPadding="0"
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          {signature.show_social_links &&
                                                            profile.profile_social_links
                                                              .slice(0, 4)
                                                              .map(
                                                                (
                                                                  link,
                                                                  index,
                                                                ) => (
                                                                  <td
                                                                    key={index}
                                                                    valign="middle"
                                                                    style={{
                                                                      paddingBottom:
                                                                        '16px',
                                                                      width:
                                                                        index ===
                                                                        0
                                                                          ? '32px'
                                                                          : '48px',
                                                                    }}
                                                                    align="right"
                                                                  >
                                                                    <a
                                                                      href={
                                                                        link.value
                                                                      }
                                                                      target="_blank"
                                                                      style={{
                                                                        textDecoration:
                                                                          'none',
                                                                        color:
                                                                          '#111827',
                                                                      }}
                                                                      rel="noopener noreferrer"
                                                                    >
                                                                      <strong
                                                                        style={{
                                                                          textDecoration:
                                                                            'none',
                                                                          fontWeight:
                                                                            'normal',
                                                                          color:
                                                                            '#111827',
                                                                        }}
                                                                      >
                                                                        <img
                                                                          style={{
                                                                            display:
                                                                              'block',
                                                                          }}
                                                                          width={
                                                                            32
                                                                          }
                                                                          height={
                                                                            32
                                                                          }
                                                                          src={`https://assets.tapt.io/email/${link.type}.png`}
                                                                          alt={
                                                                            link.type
                                                                          }
                                                                        />
                                                                      </strong>
                                                                    </a>
                                                                  </td>
                                                                ),
                                                              )}
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td style={{ height: '0px' }}>
                                                    <table
                                                      cellSpacing="0"
                                                      cellPadding="0"
                                                    >
                                                      <tbody>
                                                        {signature.show_mobile_number &&
                                                          mobileNumber && (
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  width: '21px',
                                                                  textAlign:
                                                                    'left',
                                                                }}
                                                                valign="middle"
                                                              >
                                                                <img
                                                                  style={{
                                                                    display:
                                                                      'block',
                                                                  }}
                                                                  width="11"
                                                                  height="11"
                                                                  src="https://assets.tapt.io/email/mobile.png"
                                                                  alt="Cellphone"
                                                                />
                                                              </td>
                                                              <td
                                                                style={{
                                                                  fontFamily:
                                                                    'Verdana, Geneva, sans-serif',
                                                                  fontWeight:
                                                                    '400',
                                                                  lineHeight:
                                                                    '21px',
                                                                  height:
                                                                    '21px',
                                                                  textAlign:
                                                                    'left',
                                                                }}
                                                                valign="middle"
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      signature.text_color,
                                                                    fontSize:
                                                                      '12px',
                                                                  }}
                                                                >
                                                                  {mobileNumber}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          )}
                                                        {signature.show_company_address &&
                                                          address && (
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  width: '21px',
                                                                  textAlign:
                                                                    'left',
                                                                }}
                                                                valign="middle"
                                                              >
                                                                <img
                                                                  style={{
                                                                    display:
                                                                      'block',
                                                                  }}
                                                                  width="11"
                                                                  height="11"
                                                                  src="https://assets.tapt.io/email/location-marker.png"
                                                                  alt="Address"
                                                                />
                                                              </td>
                                                              <td
                                                                style={{
                                                                  fontFamily:
                                                                    'Verdana, Geneva, sans-serif',
                                                                  fontWeight:
                                                                    '400',
                                                                  lineHeight:
                                                                    '21px',
                                                                  height:
                                                                    '21px',
                                                                  textAlign:
                                                                    'left',
                                                                  maxWidth:
                                                                    CONTENT_MAX_WIDTH,
                                                                }}
                                                                valign="middle"
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      signature.text_color,
                                                                    fontSize:
                                                                      '12px',
                                                                  }}
                                                                >
                                                                  {address}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          )}
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                          {signature.show_qr_code &&
                                            assets?.qr_code_url && (
                                              <td valign="top">
                                                <table
                                                  cellSpacing="0"
                                                  cellPadding="0"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          height: '0px',
                                                          lineHeight: '0px',
                                                          borderTop:
                                                            '1px solid #ffffff',
                                                          paddingRight: '16px',
                                                          paddingBottom: '52px',
                                                        }}
                                                      ></td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <a
                                                          href={urlForQrCode}
                                                          target="_blank"
                                                          style={{
                                                            textDecoration:
                                                              'none',
                                                          }}
                                                          rel="noopener noreferrer"
                                                        >
                                                          <img
                                                            src={
                                                              assets.qr_code_url ||
                                                              '/placeholder.svg'
                                                            }
                                                            alt="QR Code"
                                                            style={{
                                                              display: 'block',
                                                              maxHeight:
                                                                '101px',
                                                              maxWidth: '87px',
                                                              width: 'auto',
                                                              height: 'auto',
                                                              objectFit:
                                                                'contain',
                                                            }}
                                                          />
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            )}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        {signature.disclaimer_text && (
                          <DisclaimerText
                            disclaimer={signature.disclaimer_text}
                          />
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export function DetailedEmailSignaturePreview({
  profile,
  signature,
  assets,
  clientType = 'gmail',
  sampleMode = false,
}: PreviewProps) {
  const urlForQrCode = addRef(profile.profile_page_url, 'email-signature-qr');
  const urlForProfileImage = addRef(
    profile.profile_page_url,
    'email-signature-profile-image',
  );

  const fullName = getFullName({
    firstName: profile.first_name,
    middleName: profile.middle_name,
    lastName: profile.last_name,
  });

  return (
    <table
      dir="ltr"
      width="500"
      style={{ marginTop: '16px' }}
      cellSpacing="0"
      cellPadding="0"
    >
      <tr>
        <td>
          <table cellSpacing="0" cellPadding="0">
            <tr>
              {!sampleMode && assets?.profile_image_url && (
                <ProfileImage
                  profilePageUrl={urlForProfileImage}
                  imgSrc={assets?.profile_image_url}
                  blurHash={profile.photo?.blur_hash}
                  logoImgSrc={signature.logo_file?.original_url}
                  sampleMode={sampleMode}
                />
              )}
              {sampleMode &&
                (signature.show_profile_image || signature.logo_file) && (
                  <ProfileImage
                    profilePageUrl={urlForProfileImage}
                    imgSrc={
                      signature.show_profile_image
                        ? assets?.profile_image_url
                        : null
                    }
                    blurHash={profile.photo?.blur_hash}
                    logoImgSrc={signature.logo_file?.original_url}
                    sampleMode={sampleMode}
                  />
                )}
              <td valign="top">
                <table cellSpacing="0" cellPadding="0">
                  <tr>
                    <td>
                      <table cellSpacing="0" cellPadding="0">
                        <tr>
                          <td valign="top">
                            <table cellSpacing="0" cellPadding="0">
                              <tr>
                                <td
                                  style={
                                    signature.show_qr_code ||
                                    assets.banner_image_url
                                      ? { paddingBottom: '12px' }
                                      : {}
                                  }
                                >
                                  <table cellSpacing="0" cellPadding="0">
                                    <tr>
                                      <td valign="top">
                                        <table cellSpacing="0" cellPadding="0">
                                          {signature.show_full_name &&
                                            fullName && (
                                              <tr>
                                                <NameTitle
                                                  name={fullName}
                                                  textColor={
                                                    signature.primary_text_color
                                                  }
                                                />
                                              </tr>
                                            )}

                                          <tr>
                                            <JobTitle
                                              jobTitle={
                                                signature.show_job_title
                                                  ? profile.job_title
                                                  : null
                                              }
                                              company={
                                                signature.show_company_name
                                                  ? profile.company_name
                                                  : null
                                              }
                                              textColor={signature.text_color}
                                            />
                                          </tr>
                                          {signature.show_social_links && (
                                            <SocialIcons
                                              socialLinks={
                                                profile.profile_social_links
                                              }
                                            />
                                          )}
                                          <tr>
                                            <td
                                              style={{
                                                height: '0px',
                                                paddingBottom: '16px',
                                              }}
                                            >
                                              <ContactInfo
                                                profile={profile}
                                                signature={signature}
                                                clientType={clientType}
                                              />
                                            </td>
                                          </tr>
                                          {(assets.banner_image_url ||
                                            signature.show_qr_code) && (
                                            <tr>
                                              <td>
                                                <table
                                                  cellSpacing="0"
                                                  cellPadding="0"
                                                >
                                                  <tr>
                                                    {assets.banner_image_url && (
                                                      <Banner
                                                        imgSrc={
                                                          assets.banner_image_url
                                                        }
                                                        sampleMode={sampleMode}
                                                      />
                                                    )}
                                                    {signature.show_qr_code && (
                                                      <QRCode
                                                        imgSrc={
                                                          assets.qr_code_url
                                                        }
                                                        url={urlForQrCode}
                                                      />
                                                    )}
                                                  </tr>
                                                </table>
                                              </td>
                                            </tr>
                                          )}

                                          <tr></tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              {signature.disclaimer_text && (
                                <DisclaimerText
                                  disclaimer={signature.disclaimer_text}
                                />
                              )}
                              <tr></tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  );
}
