import { ensureAbsoluteUrl } from '@/helpers/urlHandler';
import { IProfileSocialLink } from '@/types/IProfile';

export const CONTENT_MAX_WIDTH = '325px';

export const NameTitle: React.FC<{ name: string; textColor?: string }> = ({
  name = 'Firstname Lastname',
  textColor = '#1B3B49',
}) => {
  return (
    <td
      style={{
        fontSize: '15px',
        fontFamily: 'Verdana, Geneva, sans-serif',
        fontWeight: 700,
        lineHeight: '22px',
        height: '22px',
        textAlign: 'left',
        paddingBottom: '8px',
        maxWidth: CONTENT_MAX_WIDTH,
      }}
    >
      <span style={{ color: textColor }}>{name}</span>
    </td>
  );
};

export const JobTitle: React.FC<{
  jobTitle: string | null;
  company: string | null;
  textColor?: string;
}> = ({ jobTitle = 'Developer', company = 'Tapt', textColor = '#848083' }) => {
  const position = [jobTitle, company].filter(Boolean).join(' at ');

  if (!position) {
    return null;
  }

  return (
    <td
      style={{
        fontFamily: 'Verdana, Geneva, sans-serif',
        lineHeight: '22px',
        height: '22px',
        textAlign: 'left',
        paddingRight: '16px',
        paddingBottom: '16px',
      }}
    >
      <span style={{ color: textColor, fontSize: '15px', fontWeight: 400 }}>
        {position}
      </span>
    </td>
  );
};

interface QRCodeProps {
  url: string;
  imgSrc: string;
}
const DISPLAY_SIZE = { width: 75, height: 101 };

export const QRCode: React.FC<QRCodeProps> = ({ url, imgSrc }) => {
  return (
    <td valign="top">
      <table cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td>
              <a
                href={url}
                target="_blank"
                style={{ textDecoration: 'none' }}
                rel="noopener noreferrer"
              >
                <img
                  src={imgSrc}
                  alt="QR code"
                  style={{
                    display: 'block',
                    height: `${DISPLAY_SIZE.height}px`,
                    width: `${DISPLAY_SIZE.width}px`,
                  }}
                  height={DISPLAY_SIZE.height}
                  width={DISPLAY_SIZE.width}
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  );
};

interface SocialIconProps {
  href: string;
  alt: string;
  imageUrl: string;
}

const socialIconStyles = {
  td: {
    valign: 'middle' as const,
    width: '48px',
    paddingBottom: '16px',
    align: 'right' as const,
  },
  link: {
    textDecoration: 'none',
    color: '#1B3B49',
  },
  strong: {
    textDecoration: 'none',
    fontWeight: 'normal',
    color: '#1B3B49',
  },
  img: {
    display: 'block',
    width: '32px',
    height: '32px',
  },
};

const SocialIcon: React.FC<SocialIconProps> = ({ href, alt, imageUrl }) => (
  <td style={socialIconStyles.td}>
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={socialIconStyles.link}
    >
      <strong style={socialIconStyles.strong}>
        <img
          style={socialIconStyles.img}
          width="32"
          height="32"
          alt={alt}
          src={imageUrl}
        />
      </strong>
    </a>
  </td>
);

// Social Icons Container Component
export const SocialIcons: React.FC<{ socialLinks: IProfileSocialLink[] }> = ({
  socialLinks,
}) => {
  if (socialLinks.length === 0) {
    return null;
  }

  return (
    <tr>
      <td>
        <table cellSpacing="0" cellPadding="0">
          <tr>
            {socialLinks.map((link, index) => {
              try {
                const url = ensureAbsoluteUrl(link.value);
                return !url ? null : (
                  <SocialIcon
                    key={index}
                    href={url}
                    alt={link.type}
                    imageUrl={`https://assets.tapt.io/email/${link.type}.png`}
                  />
                );
              } catch (e) {
                return null;
              }
            })}
          </tr>
        </table>
      </td>
    </tr>
  );
};

export const DisclaimerText = ({ disclaimer }: { disclaimer: string }) => {
  return (
    <tr>
      <td
        style={{
          color: '#848083',
          fontSize: '10px',
          fontFamily: 'Verdana, Geneva, sans-serif',
          fontWeight: '400',
          lineHeight: '21px',
          height: '21px',
          textAlign: 'left',
          maxWidth: CONTENT_MAX_WIDTH,
        }}
      >
        {disclaimer}
      </td>
    </tr>
  );
};
