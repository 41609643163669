import React, { Fragment, JSX, ReactNode, useEffect, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { read, utils } from 'xlsx';

import Button, { BUTTON_KIND } from '../Button';
import Steps from '../Steps';
import { FieldMapping } from './CsvFieldRow';
import CsvFileUploadComponent from './CsvFileUploadComponent';
import CsvFileUploadStep2 from './CsvFileUploadStep2';
import data from './data';

export interface CsvFileUploadStepsProps {
  buttonKind?: string;
  buttonSize?: string;
  buttonClassName?: string;
  buttonTitle?: string;
  dialogTitleIcon?: JSX.Element;
  dialogTitle?: string | JSX.Element;
  dialogDescription?: string | JSX.Element;
  icon?: JSX.Element;
  children?: ReactNode;
  isLoading?: boolean;
  onSuccess: () => void;
  setImportCSV: React.Dispatch<React.SetStateAction<File | undefined>>;
}

export type CSVImportErrorDetail = {
  rowNumber: number;
  cause: string;
};

export type CSVImportError = Record<string, Set<CSVImportErrorDetail>>;

const CsvFileUploadSteps = ({
  buttonTitle,
  buttonKind,
  buttonSize,
  buttonClassName,
  children,
  dialogDescription,
  dialogTitle,
  dialogTitleIcon,
  icon,
  isLoading,
  onSuccess,
  setImportCSV,
}: CsvFileUploadStepsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [sizeError, setSizeError] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [csvFields, setCsvFields] = useState<string[]>([]);
  const [csvEntries, setCsvEntries] = useState<Record<string, unknown>[]>([]);
  const [hasImportError, setHasImportError] = useState(false);
  const [importErrors, setImportErrors] = useState<CSVImportError>({});
  const [shouldContinueWithImportError, setShouldContinueWithImportError] =
    useState(false);

  const [originalCsvFieldMapping, setCsvFieldMapping] = useState<
    FieldMapping[]
  >([]);
  const [csvMappedEntries, setCsvMappedEntries] = useState<
    Record<string, unknown>[]
  >([]);

  const csvFieldMapping = originalCsvFieldMapping.map(i => ({
    ...i,
    isError: i.csvField in importErrors,
  }));
  const isAnyFieldMissing = !!csvFieldMapping.find(
    i => i.taptField === 'select_field',
  );

  const isDisabled =
    !file ||
    (currentStep === 1 && sizeError) ||
    isAnyFieldMissing ||
    (currentStep === 2 && hasImportError && !shouldContinueWithImportError);

  const readFile = (csvfile: File) => {
    const reader = new FileReader();

    reader.onload = event => {
      const text = event?.target?.result || '';
      const wb = read(text, { type: 'array' }); // parse the array buffer
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet

      const columns = utils.sheet_to_json<string[]>(ws, { header: 1 })?.[0];
      const csvData = utils.sheet_to_json<Record<string, unknown>>(ws); // generate objects

      setCsvFields(columns.filter(i => !!i) ?? []);
      setCsvEntries(csvData.filter(i => !!i));
    };

    reader.readAsArrayBuffer(csvfile);
  };

  const onNext = () => {
    if (currentStep === 1 && file) {
      readFile(file);
      setCurrentStep(currentStep + 1);
    } else if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const closePopup = () => {
    setFile(undefined);
    setSizeError(false);
    setCurrentStep(1);
    setCsvFields([]);
    setCsvEntries([]);
    setHasImportError(false);
    setShouldContinueWithImportError(false);
    setIsOpen(false);
  };

  useEffect(() => {
    if (hasImportError) {
      setShouldContinueWithImportError(false);
    }
  }, [hasImportError]);

  // --- step2 functions --- //
  const updateFieldMapping = (field: FieldMapping) => {
    setCsvFieldMapping(state =>
      state.map(i => {
        if (i.csvField === field.csvField) {
          return field;
        }
        return i;
      }),
    );
  };

  const validateEntries = (
    entries: Record<string, unknown>[],
    mapping: FieldMapping[],
  ) => {
    const errors: CSVImportError = {};

    entries.forEach(oldValue => {
      Object.keys(oldValue).forEach(key => {
        const mappedField = mapping.find(i => i.csvField === key);

        if (mappedField) {
          if (mappedField.taptField !== 'select_field') {
            const isValid =
              !data.profileTaptFields.find(i => i.key === mappedField.taptField)
                ?.isValid ||
              data.profileTaptFields
                .find(i => i.key === mappedField.taptField)
                ?.isValid?.(oldValue[key]);

            if (!isValid) {
              const errorDetail = {
                rowNumber: (oldValue.__rowNum__ as number) + 1,
                cause: String(oldValue[mappedField.csvField]),
              };

              if (mappedField.csvField in errors) {
                errors[mappedField.csvField].add(errorDetail);
              } else {
                errors[mappedField.csvField] = new Set([errorDetail]);
              }
            }
          }
        }
      });
    });

    return { isValid: Object.keys(errors).length === 0, errors };
  };

  const mapCsvToTapt = (
    entries: Record<string, unknown>[],
    mapping: FieldMapping[],
    columnsToBeExcluded?: string[],
  ) => {
    return entries.map(oldValue => {
      const newFields: typeof oldValue = {};

      for (const key of Object.keys(oldValue)) {
        const mappedField = mapping.find(i => i.csvField === key);

        if (!mappedField || mappedField.taptField === 'no_map') {
          newFields[key] = oldValue[key];
        } else if (
          mappedField.taptField !== 'select_field' &&
          !(columnsToBeExcluded || []).includes(mappedField.taptField)
        ) {
          newFields[mappedField.taptField] = oldValue[key];
        }
      }

      return newFields;
    });
  };

  const update = () => {
    const { isValid, errors } = validateEntries(csvEntries, csvFieldMapping);
    setHasImportError(!isValid);
    setImportErrors(errors);

    const entries = mapCsvToTapt(
      csvEntries,
      csvFieldMapping,
      Object.keys(importErrors),
    );
    setCsvMappedEntries(entries);
  };

  useEffect(() => {
    update();
  }, [originalCsvFieldMapping, csvEntries]);

  useEffect(() => {
    setCsvFieldMapping(
      csvFields.map(i => ({
        csvField: i,
        taptField: data.profileTaptFields.find(t => t.key === i)
          ? i
          : 'select_field',
      })),
    );
  }, [csvFields]);

  return (
    <>
      {buttonTitle && (
        <Button
          kind={buttonKind}
          size={buttonSize}
          icon={icon}
          iconPos="right"
          className={buttonClassName}
          onClick={() => setIsOpen(true)}
          buttonText={buttonTitle}
        />
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closePopup}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={clsx(
                  'inline-block my-8 text-left align-middle transition-all transform bg-white max-w-[43.625rem] large-screen:max-w-[60rem] w-full shadow-[0px_10px_10px_-5px_rgba(0,0,0,0.04),0px_20px_25px_-5px_rgba(0,0,0,0.10)] rounded-lg p-6',
                )}
              >
                <div className="flex items-end justify-end w-full">
                  <Steps currentStep={currentStep} totalSteps={3} />
                </div>

                {dialogTitleIcon && (
                  <div className="flex items-center justify-center">
                    {dialogTitleIcon}
                  </div>
                )}

                {dialogTitle && currentStep === 1 && (
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium leading-8 text-gray-900"
                  >
                    {dialogTitle}
                  </Dialog.Title>
                )}
                {currentStep !== 1 && (
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium leading-8 text-gray-900"
                  >
                    {currentStep === 2
                      ? data.mapScreenTitle
                      : data.importScreenTitle}
                  </Dialog.Title>
                )}

                {dialogDescription && currentStep === 1 && (
                  <div className={clsx(dialogTitle ? 'mt-2 mb-4' : '')}>
                    <Dialog.Description className="text-sm font-normal leading-5 text-gray-500">
                      {dialogDescription}
                    </Dialog.Description>
                  </div>
                )}
                {currentStep !== 1 && (
                  <div className={clsx(dialogTitle ? 'mt-2' : '')}>
                    <Dialog.Description className="text-sm font-normal leading-5 text-gray-500">
                      {currentStep === 2
                        ? data.mapScreenDescription
                        : data.importScreenDescription}
                    </Dialog.Description>
                  </div>
                )}

                {currentStep === 1 && children}
                <div>
                  {currentStep === 1 && (
                    <CsvFileUploadComponent
                      onFileSelected={file => setImportCSV(file)}
                      setFile={setFile}
                      file={file}
                      sizeError={sizeError}
                      setSizeError={setSizeError}
                    />
                  )}
                  {currentStep === 2 && (
                    <CsvFileUploadStep2
                      csvFields={csvFields}
                      csvEntries={csvEntries}
                      hasImportError={hasImportError}
                      setHasImportError={setHasImportError}
                      csvFieldMapping={csvFieldMapping}
                      csvMappedEntries={csvMappedEntries}
                      importErrors={importErrors}
                      isAnyFieldMissing={isAnyFieldMissing}
                      originalCsvFieldMapping={originalCsvFieldMapping}
                      setCsvFieldMapping={setCsvFieldMapping}
                      setCsvMappedEntries={setCsvMappedEntries}
                      setImportErrors={setImportErrors}
                      updateFieldMapping={updateFieldMapping}
                    />
                  )}
                </div>
                <div className="flex items-center justify-between mt-8">
                  {hasImportError && currentStep === 2 && (
                    <div className="flex items-start justify-start w-full space-x-4">
                      <input
                        id="model_checkbox"
                        type="checkbox"
                        className="w-4 h-4 mt-1 border-gray-300 rounded focus:ring-brand-500 text-brand-500"
                        checked={shouldContinueWithImportError}
                        onChange={() => {
                          setShouldContinueWithImportError(
                            !shouldContinueWithImportError,
                          );
                        }}
                      />
                      <label className="text-sm" htmlFor="model_checkbox">
                        <span className="text-sm font-medium leading-5 text-gray-700">
                          Map fields even with import errors.
                        </span>
                        <span className="text-[#6B7280] text-sm font-normal leading-5 block">
                          Some data may be missing in profile.
                        </span>
                      </label>
                    </div>
                  )}
                  <div className="flex items-center ml-auto space-x-4">
                    <Button
                      kind={BUTTON_KIND.WHITE}
                      onClick={() => {
                        if (currentStep > 1) {
                          setCurrentStep(currentStep - 1);
                        } else {
                          closePopup();
                        }
                      }}
                      buttonText={currentStep > 1 ? 'Back' : 'Cancel'}
                    />
                    {currentStep < 3 ? (
                      <Button
                        kind={BUTTON_KIND.PURPLE_SECONDARY_MD}
                        onClick={onNext}
                        buttonText="Next"
                        loading={isLoading}
                        disabled={isDisabled}
                        className="disabled:!opacity-100 disabled:text-gray-400"
                      />
                    ) : (
                      <Button
                        kind={BUTTON_KIND.PRIMARY_ROUNDED_MD}
                        onClick={() => {
                          if (
                            !hasImportError ||
                            shouldContinueWithImportError
                          ) {
                            onSuccess();
                          }
                          setIsOpen(false);
                          setCurrentStep(1);
                        }}
                        buttonText="Import"
                        loading={isLoading}
                        disabled={isDisabled}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CsvFileUploadSteps;
