import { useFieldArray, useFormContext } from 'react-hook-form';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';

import filesAPI from '@/api/files';
import Button, { BUTTON_ICON_POSITION, BUTTON_KIND } from '@/components/Button';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import { InfoPanelContainer } from '@/components/InfoPanelContainer';
import Input from '@/components/Input';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import { IProfileFileLink, IProfileQuickLink } from '@/types/IProfile';
import { WorkingProfileFileLink } from '@/types/TaptSocials';

import { QuickLinksForm } from './schema';

type ProfileLink =
  | IProfileFileLink
  | IProfileQuickLink
  | IncompleteProfileFileLink;

const isFileLink = (
  link: ProfileLink,
): link is IProfileFileLink | IncompleteProfileFileLink => {
  return 'file' in link || 'file_id' in link;
};

type IncompleteProfileFileLink = {
  file_id: undefined;
  file: undefined;
  order: number;
  title: string;
};

export function ProfileQuickFileLinks({
  disabled = false,
}: {
  disabled?: boolean;
}) {
  const { setValue, watch, getValues } = useFormContext<QuickLinksForm>();

  const profileFileLinks = watch('profile_file_links');
  const profileQuickLinks = watch('profile_quick_links');

  const { append: appendFileLink, remove: removeFileLink } = useFieldArray({
    name: 'profile_file_links',
  });
  const { append: appendQuickLink, remove: removeQuickLink } = useFieldArray({
    name: 'profile_quick_links',
  });

  const links = [...(profileFileLinks || []), ...(profileQuickLinks || [])]
    .sort((a, b) => a.order - b.order)
    .map((link, index) => ({
      ...link,
      order: index,
    }));

  function removeLink(link: ProfileLink) {
    let index = -1;
    if (isFileLink(link)) {
      index = getValues('profile_file_links').findIndex(
        item => item.title === link.title && item.order === link.order,
      );

      if (index !== -1) {
        removeFileLink(index);
      }
    } else {
      index = getValues('profile_quick_links').findIndex(
        item => item.title === link.title && item.value === link.value,
      );

      if (index !== -1) {
        removeQuickLink(index);
      }
    }

    const orderedLinks = [
      ...getValues('profile_file_links'),
      ...getValues('profile_quick_links'),
    ]
      .sort((a, b) => a.order - b.order)
      .map((link, index) => ({
        ...link,
        order: index,
      }));

    const filteredFileLinks = orderedLinks.filter(
      (link): link is WorkingProfileFileLink => isFileLink(link),
    );
    const filteredQuickLinks = orderedLinks.filter(
      (link): link is IProfileQuickLink => !isFileLink(link),
    );

    setValue('profile_file_links', filteredFileLinks);
    setValue('profile_quick_links', filteredQuickLinks);
  }

  function addNewLink(isFile: boolean) {
    if (isFile) {
      appendFileLink({
        order: links.length,
        title: '',
        type: 'incomplete',
        file: undefined,
        file_id: undefined,
      });
    } else {
      appendQuickLink({
        order: links.length,
        title: '',
        value: '',
      });
    }
  }

  return (
    <InfoPanelContainer
      title="Quick Links and Files"
      description={MESSAGES.profile.edit.links.heading}
    >
      <div className="flex flex-col space-y-3">
        {links.map((link, index) => (
          <EditQuickLink
            key={index}
            link={link}
            removeLink={() => removeLink(link)}
            isLast={index === links.length - 1}
            disabled={disabled}
          />
        ))}
        <div className="flex space-x-3">
          <Button
            buttonText="Add new quick link"
            kind={BUTTON_KIND.WHITE}
            icon={<PlusIcon />}
            iconPos={BUTTON_ICON_POSITION.LEFT}
            onClick={() => addNewLink(false)}
            disabled={disabled}
          />
          <Button
            buttonText="Add new file link"
            kind={BUTTON_KIND.WHITE}
            icon={<PlusIcon />}
            iconPos={BUTTON_ICON_POSITION.LEFT}
            onClick={() => addNewLink(true)}
            disabled={disabled}
          />
        </div>
      </div>
    </InfoPanelContainer>
  );
}

type EditQuickLinkProps = {
  link: IProfileQuickLink | IProfileFileLink | IncompleteProfileFileLink;
  removeLink: () => void;
  isLast: boolean;
  disabled?: boolean;
};

function EditQuickLink({
  link,
  isLast,
  removeLink,
  disabled = false,
}: EditQuickLinkProps) {
  const { orgID } = useAuth();

  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<QuickLinksForm>();

  const { mutateAsync: uploadFile, isPending: isUploadingFile } = useMutation({
    mutationFn: createAndUploadFile,
    onSuccess(data) {
      if (data) {
        const profileFileLinks = getValues('profile_file_links');
        const index = profileFileLinks.findIndex(
          item => item.title === link.title,
        );

        setValue(`profile_file_links.${index}.status`, 'complete');
        setValue(`profile_file_links.${index}.file`, data);
        setValue(`profile_file_links.${index}.file_id`, data.id);
      }
    },
  });

  function onTitleChange(value: string) {
    if (isFileLink(link)) {
      const profileFileLinks = getValues('profile_file_links');
      const index = profileFileLinks.findIndex(
        item => item.order === link.order,
      );
      setValue(`profile_file_links.${index}.title`, value);
    } else {
      const profileQuickLinks = getValues('profile_quick_links');
      const index = profileQuickLinks.findIndex(
        item => item.title === link.title && item.order === link.order,
      );
      setValue(`profile_quick_links.${index}.title`, value);
    }
  }

  function onValueChange(value: string) {
    if (isFileLink(link)) {
      return;
    }

    const profileQuickLinks = getValues('profile_quick_links');
    const index = profileQuickLinks.findIndex(
      item => item.title === link.title,
    );
    setValue(`profile_quick_links.${index}.value`, value);
  }

  function moveLink(direction: 'up' | 'down') {
    const allLinks = [
      ...getValues('profile_file_links'),
      ...getValues('profile_quick_links'),
    ].sort((a, b) => a.order - b.order);

    const index = allLinks.findIndex(item => item.title === link.title);
    if (index === -1) {
      return;
    }

    const targetIndex = direction === 'up' ? index - 1 : index + 1;
    if (targetIndex < 0 || targetIndex >= allLinks.length - 1) {
      return;
    }

    const updatedLinks = [...allLinks];
    const temp = updatedLinks[index];
    updatedLinks[index] = updatedLinks[targetIndex];
    updatedLinks[targetIndex] = temp;

    const orderedLinks = updatedLinks.map((link, index) => ({
      ...link,
      order: index,
    }));

    const updatedFileLinks = orderedLinks.filter(
      (link): link is WorkingProfileFileLink => isFileLink(link),
    );
    const updatedQuickLinks = orderedLinks.filter(
      (link): link is IProfileQuickLink => !isFileLink(link),
    );

    setValue('profile_file_links', updatedFileLinks);
    setValue('profile_quick_links', updatedQuickLinks);
  }

  function getLinkIndex() {
    if (isFileLink(link)) {
      const profileFileLinks = getValues('profile_file_links');
      return profileFileLinks.findIndex(
        item => item.title === link.title && item.order === link.order,
      );
    } else {
      const profileQuickLinks = getValues('profile_quick_links');
      return profileQuickLinks.findIndex(
        item => item.title === link.title && item.value === item.value,
      );
    }
  }

  async function createAndUploadFile(file: File) {
    if (orgID === undefined) {
      return;
    }

    const { data } = await filesAPI.createAndUploadFile(
      orgID,
      new File([file], file.name),
    );

    return data.data;
  }

  const fileLinkError = errors.profile_file_links?.[getLinkIndex()];
  const quickLinkError = errors.profile_quick_links?.[getLinkIndex()];

  return (
    <div className="flex flex-col-reverse xl:flex-row border border-gray-300 overflow-hidden rounded-md">
      <div className="flex flex-row xl:flex-col justify-end xl:justify-between space-x-2 xl:space-x-0 bg-gray-50 p-3">
        <button
          className="border border-gray-300 bg-white rounded-md p-2 disabled:opacity-40 hover:bg-gray-50"
          onClick={() => moveLink('up')}
          disabled={disabled || link.order === 0}
        >
          <ArrowUpIcon
            className="w-5 h-5 text-gray-500"
            strokeWidth={2}
            aria-hidden="true"
          />
        </button>
        <button
          className="border border-gray-300 bg-white rounded-md p-2 disabled:opacity-40 hover:bg-gray-50"
          onClick={() => moveLink('down')}
          disabled={disabled || isLast}
        >
          <ArrowDownIcon
            className="w-5 h-5 text-gray-500"
            strokeWidth={2}
            aria-hidden="true"
          />
        </button>
      </div>
      <div className="flex flex-col w-full py-4 px-6 space-y-3">
        <div className="flex flex-col xl:flex-row justify-between gap-4">
          <div className="flex-1">
            <Input
              type="text"
              label="Link button text"
              value={link.title}
              onChange={onTitleChange}
              message="This will appear as a button on your profile"
              error={!!fileLinkError?.title}
              disabled={disabled}
            />
            {fileLinkError && fileLinkError.title && (
              <span className="text-sm font-medium text-red-400">
                {fileLinkError.title.message}
              </span>
            )}
            {quickLinkError && quickLinkError.title && (
              <span className="text-sm font-medium text-red-400">
                {quickLinkError.title.message}
              </span>
            )}
          </div>
          <div className="flex-1">
            {isFileLink(link) ? (
              <div className="flex flex-col">
                <FileUploadButtonComponent
                  url={link.file?.original_url}
                  filename={link.file?.file?.file_name}
                  title="Linked file"
                  bottomText="Clicking the button will download this file"
                  onFileSelected={uploadFile}
                  fileFormat=".gif, .jpg, .jpeg, .png, .txt, .tiff, .tif, .mp3, .mpeg, .mpg, .wav, .pdf, .doc, .docx, .xls, .xlsx"
                  loading={isUploadingFile}
                  disabled={disabled}
                />
                {fileLinkError && fileLinkError.file && (
                  <span className="text-sm text-red-400 font-medium">
                    {fileLinkError.file.message}
                  </span>
                )}
              </div>
            ) : (
              <>
                <Input
                  type="text"
                  label="Link address"
                  value={link.value}
                  onChange={onValueChange}
                  message="Clicking the button will go here"
                  disabled={disabled}
                />
                {quickLinkError && quickLinkError.value && (
                  <span className="text-sm text-red-400 font-medium">
                    {quickLinkError.value.message}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <button
          className="text-brand-500 font-medium text-sm self-start"
          onClick={removeLink}
        >
          Remove link
        </button>
      </div>
    </div>
  );
}
