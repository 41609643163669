import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Tab } from '@headlessui/react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useUpdateEffect } from 'usehooks-ts';

import accountsAPI from '@/api/accounts';
import filesAPI from '@/api/files';
import organisationsAPI from '@/api/organisations';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import { InfoPanelContainer } from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Input from '@/components/Input';
import Layout from '@/components/Layout';
import Modal from '@/components/Modals/Modal';
import ManagePlanTab from '@/components/SettingsPage/ManagePlanTab';
import TabList from '@/components/TabList';
import ToggleField from '@/components/ToggleField';
import ToggleFieldWithInfo from '@/components/ToggleFieldWithInfo';
import MESSAGES from '@/constants/messages-en';
import { IntegrationSettings } from '@/containers/IntegrationSettings';
import OrganisationSettings from '@/containers/OrganisationSettings';
import useAppState, { SettingsTab } from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import IFile from '@/types/IFile';
import { IOrganisationUser } from '@/types/IOrganisation';
import SubscriptionStatus from '@/types/SubscriptionStatus';

export default function AccountSettingsPage() {
  const { user, orgID, getCurrentUser, userRole, platformSubscriptionStatus } =
    useAuth();
  const { settingsTab, selectSettingsTab } = useAppState();
  const location = useLocation();

  const disableTabs =
    platformSubscriptionStatus === SubscriptionStatus.INACTIVE;
  const [isPlanRequiredModalOpen, setIsPlanRequiredModalOpen] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [tabs, setTabs] = useState([
    {
      name: 'General',
      value: 'general',
      current: true,
      disabled: disableTabs,
    },
    {
      name: 'Organisation',
      value: 'organisation',
      current: false,
      disabled: disableTabs,
    },
    {
      name: 'Integrations',
      value: 'integrations',
      current: false,
      disabled: disableTabs,
    },
    {
      name: 'Profile Editing',
      value: 'profile-editing',
      current: false,
      disabled: disableTabs,
    },
    {
      name: 'Manage Plan',
      value: 'manage-plan',
      current: false,
    },
  ]);

  const defaultTab = disableTabs
    ? SettingsTab.MANAGE_PLAN
    : tabs[settingsTab]
    ? settingsTab
    : SettingsTab.GENERAL;
  const [selectedIndex, setSelectedIndex] = useState<number>(defaultTab);
  const [tab, setTab] = useState(tabs[defaultTab].value);

  useEffect(() => {
    let tabIndex = settingsTab;
    const tab = tabs[settingsTab];
    if (!tab || tab.disabled) {
      tabIndex = defaultTab;
    }

    setTab(tabs[tabIndex].value);
    setSelectedIndex(tabIndex);
  }, [settingsTab, tabs]);

  useEffect(() => {
    if (
      user &&
      user.platform_subscription_status === SubscriptionStatus.INACTIVE
    ) {
      selectSettingsTab(SettingsTab.MANAGE_PLAN);
      setSelectedIndex(SettingsTab.MANAGE_PLAN);
      setTab(tabs[SettingsTab.MANAGE_PLAN].value);
    }
  }, [user]);

  const [firstName, setFirstName] = useState<string>(user?.first_name || '');
  const [lastName, setLastName] = useState<string>(user?.last_name || '');
  const [email, setEmail] = useState<string>(user?.email || '');
  const [mfaEnabled, setMfaEnabled] = useState(
    user?.authentication_type === 'mfa_email',
  );
  const [organisationName, setOrganisationName] = useState<string>('');
  const [logoFile, setLogoFile] = useState<IFile>();
  const [backgroundColour, setBackgroundColour] = useState<string>('#0A0A0A');
  const [textColour, setTextColour] = useState<string>('#FFFFFF');
  const [buttonBackgroundColour, setButtonBackgroundColour] =
    useState<string>('#873CFF');
  const [buttonTextColour, setButtonTextColour] = useState<string>('#FFFFFF');
  const [consentStatement, setConsentStatement] = useState<IFile>();
  const [organisationError, setOrganisationError] = useState<boolean>(false);
  const [resetPasswordIsOpen, setResetPasswordIsOpen] =
    useState<boolean>(false);
  const [inviteUserEmail, setInviteUserEmail] = useState<string>('');
  const [inviteUserRole, setInviteUserRole] = useState<string>('org_admin');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [orgUsers, setOrgUsers] = useState<IOrganisationUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordLoading, setPasswordLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [passwordSuccess, setPasswordSuccess] = useState<string>('');
  const [isCardholderEditingEnabled, setIsCardholderEditingEnabled] =
    useState<boolean>(true);
  const [isCardholderEditingEmailOpen, setIsCardholderEditingEmailOpen] =
    useState<boolean>(false);

  const handleGeneralSettingsSave = () => {
    setSuccess('');
    setLoading(true);

    accountsAPI
      .updateCurrentUser({
        first_name: firstName,
        last_name: lastName,
      })
      .then(() => setSuccess(MESSAGES.account.settings.success))
      .then(() => getCurrentUser())
      .finally(() => setLoading(false));
  };

  const handleOrganisationSettingsSave = () => {
    setSuccess('');
    setOrganisationError(false);
    setLoading(true);

    if (orgID) {
      organisationsAPI
        .updateOrganisationSettings(orgID, {
          organisation_setting: {
            bg_color: backgroundColour,
            text_color: textColour,
            button_bg_color: buttonBackgroundColour,
            button_text_color: buttonTextColour,
            company_logo_file_id: logoFile ? logoFile.id : null,
            is_cardholder_editing_enabled: isCardholderEditingEnabled,
          },
        })
        .then(() => setSuccess(MESSAGES.organisation.settings))
        .catch(() => setOrganisationError(true))
        .finally(() => setLoading(false));
    }
  };

  const handleProfileEditingAccessSave = (emailProfileEditing?: boolean) => {
    setSuccess('');
    setOrganisationError(false);
    setLoading(true);

    if (orgID) {
      organisationsAPI
        .updateOrganisationSettings(orgID, {
          email_profile_editing: emailProfileEditing,
          organisation_setting: {
            is_cardholder_editing_enabled: isCardholderEditingEnabled,
          },
        })
        .then(() => setSuccess(MESSAGES.organisation.settings))
        .catch(() => setOrganisationError(true))
        .finally(() => setLoading(false));
    }
  };

  const handleEmailModal = () => {
    if (isCardholderEditingEnabled) {
      setIsCardholderEditingEmailOpen(true);
    } else {
      handleProfileEditingAccessSave(false);
    }
  };

  const handleResetPassword = () => {
    setPasswordSuccess('');
    setPasswordError(false);
    setPasswordLoading(true);
    accountsAPI
      .forgotPassword({ forgot: { email } })
      .then(() => setPasswordSuccess(MESSAGES.account.reset.success))
      .catch(() => setPasswordError(true))
      .then(() => setPasswordLoading(false))
      .finally(() => setResetPasswordIsOpen(false));
  };

  const showOrganisationSettings = useCallback(() => {
    if (orgID) {
      setLoading(true);
      organisationsAPI
        .showOrganisationSettings(orgID)
        .then(res => {
          const data = res?.data?.data;
          if (data.bg_color) setBackgroundColour(data.bg_color);
          if (data.text_color) setTextColour(data.text_color);
          if (data.button_bg_color)
            setButtonBackgroundColour(data.button_bg_color);
          if (data.button_text_color)
            setButtonTextColour(data.button_text_color);
          if (data?.company_consent_file)
            setConsentStatement(data.company_consent_file);
          if (data.company_logo) setLogoFile(data.company_logo);
          setIsCardholderEditingEnabled(data.is_cardholder_editing_enabled);
        })
        .finally(() => {
          setLoading(false);
          if (location && location.state) {
            const { fromPage } = location.state;
            if (fromPage === 'preview') {
              if (location.state.bg_color)
                setBackgroundColour(location.state.bg_color);
              if (location.state.text_color)
                setTextColour(location.state.text_color);
              if (location.state.button_bg_color)
                setButtonBackgroundColour(location.state.button_bg_color);
              if (location.state.button_text_color)
                setButtonTextColour(location.state.button_text_color);
              if (location.state.company_logo_file_id) {
                filesAPI
                  .showFile(orgID, Number(location.state.company_logo_file_id))
                  .then(res => setLogoFile(res.data.data))
                  .finally(() => {});
              }
            }
            if (location.state.company_consent_file_id) {
              filesAPI
                .showFile(orgID, Number(location.state.company_consent_file_id))
                .then(res => setConsentStatement(res.data.data))
                .finally(() => {});
            }
          }
        });
    }
  }, [orgID, location]);

  const renderInvalidPlanInfo = () => {
    if (selectedIndex !== SettingsTab.MANAGE_PLAN) return false;
    if (platformSubscriptionStatus !== SubscriptionStatus.INACTIVE)
      return false;

    return (
      <div className="rounded-md bg-brand-100 max-w-2xl text-white p-3 md:mt-0 right-0">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-brand-900"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-brand-900">
              tapt Management Dashboard plan invalid
            </h3>
            <p className="mt-2 text-sm leading-5 text-brand-600">
              You currently do not have an active plan to access the tapt
              Management Dashboard. If you would like access to management
              features, activate your plan below.{' '}
              <button onClick={() => setIsPlanRequiredModalOpen(true)}>
                <span className="underline">Learn more</span>
              </button>
            </p>
            <a
              href={checkoutUrl}
              target="_blank"
              rel="noreferrer"
              className="mt-2 text-sm leading-5 font-medium text-brand-500 flex w-full"
            >
              Join now
              <ArrowRightIcon className="ml-2 w-5 h-5" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    showOrganisationSettings();
  }, [showOrganisationSettings]);

  useUpdateEffect(() => {
    setSuccess('');
    setLoading(true);
    accountsAPI
      .updateCurrentUser({
        authentication_type: mfaEnabled ? 'mfa_email' : 'password',
      })
      .then(() => setSuccess(MESSAGES.account.settings.success))
      .then(() => getCurrentUser())
      .finally(() => setLoading(false));
  }, [mfaEnabled]);

  return (
    <Layout pageName="Settings" rightTitle={renderInvalidPlanInfo()}>
      {userRole === 'org_admin' && (
        <Tab.Group
          defaultIndex={settingsTab}
          selectedIndex={selectedIndex}
          onChange={(index: number) => {
            setSelectedIndex(index);
            setTab(tabs[index].value);
            selectSettingsTab(index);
            setSuccess('');
            setOrganisationError(false);
            setPasswordError(false);
            setLoading(false);
          }}
        >
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
            <div className="pt-4 md:pt-0 w-full md:w-auto">
              <TabList tabs={tabs} value={tab} />
            </div>
          </div>
          <Tab.Panels>
            <div className="pt-4">
              {success !== '' && <SuccessAlert message={success} />}
            </div>
            <Tab.Panel className="outline-none">
              <Modal
                isOpen={resetPasswordIsOpen}
                setIsOpen={setResetPasswordIsOpen}
                dialogTitle="Reset password"
                dialogDescription="This will send an email with instructions to reset your password."
                successButtonText="Reset password"
                onSuccess={handleResetPassword}
                isLoading={passwordLoading}
              />
              <div className="mt-10">
                <InfoPanelContainer
                  title="Account settings"
                  footerContent={
                    <Button
                      buttonText="Save"
                      loading={loading}
                      onClick={handleGeneralSettingsSave}
                    />
                  }
                >
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        label="First name"
                        type="text"
                        value={firstName}
                        onChange={setFirstName}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        label="Last Name"
                        type="text"
                        value={lastName}
                        onChange={setLastName}
                      />
                    </div>
                    <div className="mt-3 col-span-8 sm:col-span-4">
                      <Input
                        label="Email Address"
                        type="text"
                        value={email}
                        onChange={setEmail}
                        disabled
                      />
                    </div>
                  </div>
                </InfoPanelContainer>
                <InfoPanelDivider />
                <InfoPanelContainer title="Account security">
                  {passwordSuccess !== '' && (
                    <SuccessAlert message={passwordSuccess} />
                  )}
                  {passwordError && (
                    <ErrorAlert message="Something went wrong. Please try again later" />
                  )}
                  <div className="grid grid-cols-1 gap-6">
                    <ToggleFieldWithInfo
                      label="Multi-factor authentication"
                      enabled={mfaEnabled}
                      setter={setMfaEnabled}
                      modalTitle="What is multi-factor authentication?"
                      modalDescription="mfa_content"
                    />
                  </div>
                  <div className="mt-5 flex flex-col lg:flex-row items-start lg:items-center justify-between">
                    <div className="min-w-0 text-sm leading-5 font-medium text-gray-900 mt-0.5">
                      Reset Password
                    </div>
                    <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-start lg:items-center mt-5 sm:mt-0">
                      <Button
                        kind={BUTTON_KIND.WHITE}
                        buttonText="Reset"
                        onClick={() => setResetPasswordIsOpen(true)}
                      />
                    </div>
                  </div>
                </InfoPanelContainer>
              </div>
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              {orgID && (
                <OrganisationSettings
                  orgID={orgID}
                  organisationName={organisationName}
                  setOrganisationName={setOrganisationName}
                  inviteUserEmail={inviteUserEmail}
                  setInviteUserEmail={setInviteUserEmail}
                  inviteUserRole={inviteUserRole}
                  setInviteUserRole={setInviteUserRole}
                  orgUsers={orgUsers}
                  setOrgUsers={setOrgUsers}
                />
              )}
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <IntegrationSettings />
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <div className="mt-10">
                <InfoPanelContainer
                  title="Employee editing access"
                  description="Grant employees access to edit their own profiles within the Tapt app"
                >
                  <ToggleField
                    label="Grant all employees profile editing access"
                    enabled={isCardholderEditingEnabled}
                    setter={setIsCardholderEditingEnabled}
                  />
                  <InfoPanelFooter>
                    <div className="flex justify-end">
                      <Button
                        buttonText="Discard changes"
                        className="mr-3"
                        kind={BUTTON_KIND.WHITE}
                        onClick={window.location.reload}
                      />
                      <Button buttonText="Save" onClick={handleEmailModal} />
                    </div>
                  </InfoPanelFooter>
                </InfoPanelContainer>
                <Modal
                  isOpen={isCardholderEditingEmailOpen}
                  setIsOpen={setIsCardholderEditingEmailOpen}
                  dialogTitle="Do you want to notify the cardholders?"
                  dialogDescription="By clicking send email, these people will receive an email notification that they can edit their profiles on the Tapt App."
                  onSuccess={() => {
                    setIsCardholderEditingEmailOpen(false);
                    handleProfileEditingAccessSave(true);
                  }}
                  onCancel={() => {
                    setIsCardholderEditingEmailOpen(false);
                    handleProfileEditingAccessSave(false);
                  }}
                  successButtonText="Send Email"
                  cancelButtonText="Don't send"
                  buttonKind={BUTTON_KIND.CIRCULAR}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel className="outline-none">
              <div className="mt-10">
                <ManagePlanTab
                  organisationId={orgID}
                  isPlanRequiredModalOpen={isPlanRequiredModalOpen}
                  setIsPlanRequiredModalOpen={setIsPlanRequiredModalOpen}
                  checkoutUrl={checkoutUrl}
                  setCheckoutUrl={setCheckoutUrl}
                />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      )}
    </Layout>
  );
}
