import { IProfileSignature } from '@/types/IProfile';

export const sampleProfileUrl =
  'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=200&h=200&auto=format&fit=crop';

export const sampleProfile: IProfileSignature = {
  first_name: 'Sam',
  middle_name: null,
  last_name: 'Sample',
  job_title: 'Sales Director',
  email: 'sam.sample@sampleinc.com',
  mobile_number: '0412 345 678',
  photo: {
    id: 1,
    name: 'profile.jpg',
    file_size: 1024,
    small_url: sampleProfileUrl,
    medium_url: sampleProfileUrl,
    large_url: sampleProfileUrl,
    blur_url: sampleProfileUrl,
    blur_hash: 'LKJRU6o#?wi^xvM{4nof0Kn%-VkX',
    thumb_url: sampleProfileUrl,
    original_url: sampleProfileUrl,
    upload_url: sampleProfileUrl,
  },
  profile_page_url: 'https://profile.tapt.io/profile/sample',
  company_name: 'Sample Inc.',
  company_website: 'https://sampleinc.com',
  company_phone_number: '03 9999 9999',
  country: 'Australia',
  street_address: '81 Green Street',
  city: 'Cremorne',
  state: 'VIC',
  post_code: '3121',
  profile_social_links: [
    {
      order: 1,
      type: 'facebook',
      value: 'https://www.facebook.com/taptofficial/',
      personal: false,
    },
    {
      order: 2,
      type: 'instagram',
      value: 'https://www.instagram.com/taptofficial/',
      personal: false,
    },
    {
      order: 3,
      type: 'linkedin',
      value: 'https://www.linkedin.com/company/taptofficial/',
      personal: false,
    },
    {
      order: 4,
      type: 'tiktok',
      value: 'https://www.tiktok.com/@taptofficial/',
      personal: false,
    },
    {
      order: 5,
      type: 'pinterest',
      value: 'https://www.pinterest.com/taptofficial/',
      personal: false,
    },
    {
      order: 6,
      type: 'youtube',
      value: 'https://www.youtube.com/taptofficial/',
      personal: false,
    },
  ],
};
