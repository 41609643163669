import { useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useBlocker, useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';

import profilesAPI from '@/api/profiles';
import { ErrorAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import { CompanyInformation } from '@/components/EditProfilePage/CompanyInfoForm';
import { ContactExchange } from '@/components/EditProfilePage/ProfileOperations';
import { ProfilePageAppearance } from '@/components/EditProfilePage/ProfilePageAppearance';
import { ProfileQuickFileLinks } from '@/components/EditProfilePage/QuickLinksForm';
import { ProfileSocialLinks } from '@/components/EditProfilePage/SocialLinksForm';
import {
  SharedProfilePageSchema,
  WorkingSharedProfilePageForm,
} from '@/components/EditProfilePage/schema';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import { UnsavedChangesModal } from '@/components/UnsavedChangesPrompt';
import COLORS from '@/constants/colors';
import MESSAGES from '@/constants/messages-en';
import useAppState from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import IProfile from '@/types/IProfile';

export default function EditSharedProfilePage() {
  const { orgID } = useAuth();
  const { multipleProfiles } = useAppState();
  const navigate = useNavigate();

  const { data: profile } = useQuery({
    queryKey: ['showProfilesShared', ...multipleProfiles],
    queryFn: showProfilesShared,
    enabled: orgID !== undefined,
    refetchOnWindowFocus: false,
  });

  const methods = useForm<WorkingSharedProfilePageForm>({
    resolver: zodResolver(SharedProfilePageSchema),
  });

  useEffect(() => {
    if (multipleProfiles.length === 0) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (profile) {
      methods.reset({
        contact_exchange_enabled: profile.contact_exchange_enabled,
        company_name: profile.company_name,
        company_phone_number: profile.company_phone_number,
        company_website: profile.company_website,
        city: profile.city,
        country: profile.country,
        state: profile.state,
        street_address: profile.street_address,
        profile_social_links: profile.profile_social_links,
        profile_file_links: profile.profile_file_links.map(link => ({
          status: 'complete',
          ...link,
        })),
        profile_quick_links: profile.profile_quick_links,
        extra_company_phone_numbers:
          profile.extra_company_phone_numbers ?? null,
        // Default to organisation settings and default profile design values
        // All profiles will have their `settings` and `settings_level` overwritten anyway
        settings: {
          logo: null,
          logo_file_id: null,
          bg_color: COLORS.organisation.defaultProfile.backgroundColor,
          text_color: COLORS.organisation.defaultProfile.textColor,
          button_bg_color:
            COLORS.organisation.defaultProfile.buttonBackgroundColor,
          button_text_color: COLORS.organisation.defaultProfile.buttonTextColor,
        },
        settings_level: 'organisation',
      });
    }
  }, [profile]);

  async function showProfilesShared() {
    if (orgID === undefined) {
      return;
    }

    const { data: profiles } = await profilesAPI.showProfilesShared(
      orgID,
      multipleProfiles,
    );

    return profiles.data;
  }

  if (profile === undefined) {
    return (
      <Layout
        pageName="Edit shared information"
        description={`${multipleProfiles.length} profiles selected`}
        className="bg-gray-50"
      >
        <div className="flex items-center justify-center h-full">
          <LoadingAnimation className="w-16 h-16 mx-auto" />
        </div>
      </Layout>
    );
  }

  return (
    <FormProvider {...methods}>
      <Layout
        pageName="Edit shared information"
        description={`${multipleProfiles.length} profiles selected`}
        className="bg-gray-50"
      >
        <EditSharedProfileForm />
      </Layout>
    </FormProvider>
  );
}

function EditSharedProfileForm() {
  const { orgID } = useAuth();
  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, isSubmitSuccessful, errors },
  } = useFormContext<WorkingSharedProfilePageForm>();
  const navigate = useNavigate();
  const { multipleProfiles } = useAppState();

  const { mutateAsync: updateProfiles, isError: isUpdateProfilesError } =
    useMutation({
      mutationFn: updateProfilesShared,
    });

  const blocker = useBlocker(isDirty && !isSubmitSuccessful);

  useEffect(() => {
    if (blocker.state === 'blocked' && isSubmitSuccessful) {
      blocker.proceed();
    }
  }, [blocker, isSubmitSuccessful]);

  async function onSubmit(data: WorkingSharedProfilePageForm) {
    const profile: Partial<IProfile> = {
      company_name: data.company_name,
      company_phone_number: data.company_phone_number,
      company_website: data.company_website,
      city: data.city,
      country: data.country,
      state: data.state,
      street_address: data.street_address,
      post_code: data.post_code,
      profile_file_links: data.profile_file_links
        .filter(link => link.status !== 'incomplete')
        .map(link => ({
          ...link,
          title_to: link.title,
          file_id: link.file_id,
        })),
      profile_quick_links: data.profile_quick_links.map(link => ({
        ...link,
        title_to: link.title,
        value_to: link.value,
      })),
      profile_social_links: data.profile_social_links.map(link => ({
        ...link,
        type_to: link.type,
        value_to: link.value,
      })),
      contact_exchange_enabled: data.contact_exchange_enabled,
      settings: data.settings,
      settings_level: data.settings_level,
    };

    await updateProfiles(profile);

    navigate('/', {
      state: {
        success: MESSAGES.profile.edit.successMultiple,
      },
    });
  }

  async function updateProfilesShared(profile: Partial<IProfile>) {
    if (orgID === undefined) {
      return;
    }

    await profilesAPI.updateProfilesSharedNew({
      orgID,
      body: { ids: multipleProfiles, profile },
    });
  }

  const hasFormErrors = Object.keys(errors).length > 0;

  return (
    <div className="pb-20">
      {hasFormErrors && (
        <div className="pb-6">
          <ErrorAlert message="Please review and correct the form to continue" />
        </div>
      )}
      {isUpdateProfilesError && <ErrorAlert message={MESSAGES.error.generic} />}
      <CompanyInformation />
      <InfoPanelDivider />
      <ProfileSocialLinks />
      <InfoPanelDivider />
      <ProfileQuickFileLinks />
      <InfoPanelDivider />
      <ContactExchange />
      <InfoPanelDivider />
      <ProfilePageAppearance />
      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="Cancel"
            kind={BUTTON_KIND.WHITE}
            onClick={() => navigate('/')}
          />
          <Button
            buttonText="Save changes"
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
          />
        </div>
        {blocker.state === 'blocked' && (
          <UnsavedChangesModal
            proceed={handleSubmit(onSubmit)}
            cancel={blocker.proceed}
            reset={blocker.reset}
            isLoading={isSubmitting}
          />
        )}
      </InfoPanelFooter>
    </div>
  );
}
