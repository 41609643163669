import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useBlocker, useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { z } from 'zod';

import organisationsAPI from '@/api/organisations';
import DefaultProfilePicture from '@/assets/images/DefaultProfilePicture.png';
import QRCode from '@/assets/images/qr-code.png';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import ColorPicker from '@/components/ColorPicker';
import TaptUnion from '@/components/Icons/TaptUnion';
import { InfoPanelContainer } from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Modal from '@/components/Modals/Modal';
import { ShareWithTeamButton } from '@/components/Templates/ShareWithTeamButton';
import ToggleField from '@/components/ToggleField';
import { UnsavedChangesModal } from '@/components/UnsavedChangesPrompt';
import UploadPhoto from '@/components/UploadPhoto';
import MESSAGES from '@/constants/messages-en';
import { isDarkColor } from '@/helpers/strings';
import useAuth from '@/hooks/useAuth';
import { FileSchema } from '@/types/IFile';
import { IDigitalWalletDesignRequest } from '@/types/IOrganisation';

const DEFAULT_BACKGROUND_COLOR = '#FFFFFF';
const DEFAULT_TEXT_COLOR = '#000000';

const DigitalWalletFormSchema = z.object({
  background_color: z.string(),
  text_color: z.string(),
  company_logo: FileSchema.nullable(),
  banner_image: FileSchema.nullable(),
});

type DigitalWalletForm = z.infer<typeof DigitalWalletFormSchema>;

export function DigitalWallet() {
  const { orgID } = useAuth();
  const navigate = useNavigate();

  const {
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitSuccessful, isDirty, isSubmitting },
  } = useForm<DigitalWalletForm>({
    defaultValues: {
      background_color: DEFAULT_BACKGROUND_COLOR,
      text_color: DEFAULT_TEXT_COLOR,
      company_logo: null,
      banner_image: null,
    },
    resolver: zodResolver(DigitalWalletFormSchema),
  });

  const blocker = useBlocker(isDirty && !isSubmitSuccessful);

  const { data: digitalWalletDesign } = useQuery({
    queryKey: ['getDigitalWalletDesign', orgID],
    queryFn: getDigitalWalletDesign,
    enabled: orgID !== undefined,
  });
  const { mutateAsync: saveDigitalWalletDesign, isError } = useMutation({
    mutationFn: updateDigitalWalletDesign,
  });

  const [currentPreview, setCurrentPreview] = useState<'apple' | 'google'>(
    'apple',
  );
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );

  const {
    text_color: textColorApple,
    company_logo: companyLogo,
    background_color: backgroundColor,
    banner_image: bannerImageGoogle,
  } = watch();

  useEffect(() => {
    if (digitalWalletDesign) {
      reset({
        text_color: digitalWalletDesign.text_color,
        background_color: digitalWalletDesign.background_color,
        banner_image: digitalWalletDesign.banner_image,
        company_logo: digitalWalletDesign.logo_file,
      });
    }
  }, [digitalWalletDesign]);

  useEffect(() => {
    if (blocker.state === 'blocked' && isSubmitSuccessful) {
      blocker.proceed();
    }
  }, [blocker, isSubmitSuccessful]);

  async function getDigitalWalletDesign() {
    if (orgID === undefined) {
      return;
    }

    const res = await organisationsAPI.getDigitalWalletDesign(orgID);
    return res.data;
  }

  async function onSubmit(data: DigitalWalletForm) {
    const payload = {
      text_color: data.text_color,
      background_color: data.background_color,
      logo_file_id: data.company_logo?.id || null,
      banner_image_file_id: data.banner_image?.id || null,
    };

    if (successMessage) {
      setSuccessMessage(undefined);
    }

    await saveDigitalWalletDesign({ digital_wallet_design: payload });
    reset(data, { keepValues: true });
  }

  async function updateDigitalWalletDesign(
    payload: IDigitalWalletDesignRequest,
  ) {
    if (orgID === undefined) {
      return;
    }

    await organisationsAPI.updateDigitalWalletDesign(orgID, payload);
  }

  return (
    <>
      {(isSubmitSuccessful || successMessage) && (
        <div className="pt-8">
          <SuccessAlert
            message={
              successMessage
                ? successMessage
                : MESSAGES.organisation.digitalWallet
            }
          />
        </div>
      )}
      {isError && (
        <div className="pt-8">
          <ErrorAlert message={MESSAGES.error.generic} />
        </div>
      )}
      <div className="flex flex-col-reverse xl:flex-row justify-between gap-6 pb-[33%] md:pb-[68px]">
        <InfoPanelContainer className="flex-1 mt-8">
          <span className="font-medium text-xl text-gray-900">
            Branding &amp; Colours
          </span>
          <InfoPanelDivider />
          <UploadPhoto
            title="Banner image"
            fileFormatMessage={`Recommended dimensions: 1032px x 336px\nMax file size 8MB (.jpeg or .png only)`}
            photo={bannerImageGoogle || undefined}
            setPhoto={banner => setValue('banner_image', banner || null)}
            size="large"
            maxWidth={1032}
            maxHeight={336}
            aspectRatio={1032 / 336}
          />
          <InfoPanelDivider />
          <UploadPhoto
            title="Company mark logo/icon"
            fileFormatMessage={`Recommended dimensions 600px x 600px\nMax file size 8MB (.jpeg or .png only)`}
            photo={companyLogo || undefined}
            setPhoto={logo => setValue('company_logo', logo || null)}
            maxWidth={660}
            maxHeight={660}
          />
          <InfoPanelDivider />
          <div className="flex flex-col xl:flex-row gap-y-8 justify-between">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="flex flex-col space-y-2">
                <span className="text-sm font-medium text-gray-900">
                  Background colour
                </span>
                {digitalWalletDesign === undefined ? (
                  <Skeleton width={200} height={42} />
                ) : (
                  <ColorPicker
                    color={backgroundColor}
                    setColor={color =>
                      setValue('background_color', color, {
                        shouldDirty: true,
                      })
                    }
                  />
                )}
              </div>
              <div className="flex flex-col space-y-2">
                <span className="text-sm font-medium text-gray-900">
                  Text colour (Apple only)
                </span>
                {digitalWalletDesign === undefined ? (
                  <Skeleton width={200} height={42} />
                ) : (
                  <ColorPicker
                    color={textColorApple}
                    setColor={color =>
                      setValue('text_color', color, { shouldDirty: true })
                    }
                  />
                )}
              </div>
            </div>
            <Button
              buttonText="Reset colours"
              className="self-end"
              kind={BUTTON_KIND.WHITE}
              onClick={() => {
                if (digitalWalletDesign) {
                  setValue(
                    'background_color',
                    digitalWalletDesign.background_color,
                  );
                  setValue('text_color', digitalWalletDesign.text_color);
                }
              }}
              loading={digitalWalletDesign === undefined}
              disabled={
                backgroundColor === digitalWalletDesign?.background_color &&
                textColorApple === digitalWalletDesign.text_color
              }
            />
          </div>
        </InfoPanelContainer>
        <div className="xl:sticky xl:top-0 z-100 h-fit">
          <InfoPanelContainer className="flex-1 mt-8">
            <div className="flex items-center space-x-3 mb-4">
              <span
                className={clsx(
                  'text-sm',
                  currentPreview === 'apple'
                    ? 'text-gray-900 font-medium'
                    : 'text-gray-500',
                )}
              >
                Apple preview
              </span>
              <ToggleField
                label=""
                lableColor=""
                enabled={currentPreview === 'google'}
                setter={enabled =>
                  enabled
                    ? setCurrentPreview('google')
                    : setCurrentPreview('apple')
                }
              />
              <span
                className={clsx(
                  'text-sm',
                  currentPreview === 'google'
                    ? 'text-gray-900 font-medium'
                    : 'text-gray-500',
                )}
              >
                Google preview
              </span>
            </div>
            {digitalWalletDesign ? (
              <>
                {currentPreview === 'apple' ? (
                  <AppleGenericPass
                    payload={{
                      title: 'Sample Inc',
                      header: 'John Doe',
                      subheading: 'Marketing Manager',
                      logo: companyLogo ? companyLogo.small_url : null,
                      backgroundColor: backgroundColor,
                      textColor: textColorApple,
                    }}
                  />
                ) : (
                  <GoogleGenericPass
                    payload={{
                      title: 'Sample Inc',
                      subheading: 'Marketing Manager',
                      header: 'John Doe',
                      heroImage: bannerImageGoogle
                        ? bannerImageGoogle.small_url
                        : null,
                      logo: companyLogo ? companyLogo.small_url : null,
                      backgroundColor: backgroundColor,
                    }}
                  />
                )}
              </>
            ) : (
              <Skeleton width={330} height={440} />
            )}
          </InfoPanelContainer>
        </div>
      </div>
      <InfoPanelFooter>
        <div className="flex items-center justify-between">
          <ShareWithTeamButton
            modalTitle="Share digital wallet pass with profile holders via email"
            action={organisationsAPI.shareDigitalWallet}
            onSuccessCallback={() =>
              setSuccessMessage(
                'Digital wallet passes have been sent to the selected profile holders.',
              )
            }
          />
          <div className="flex justify-end space-x-4">
            <Button
              buttonText="Cancel"
              kind={BUTTON_KIND.WHITE}
              onClick={() => navigate(-1)}
            />
            <Button
              buttonText="Save changes"
              onClick={handleSubmit(onSubmit)}
              loading={isSubmitting}
            />
          </div>
        </div>
      </InfoPanelFooter>
      {blocker.state === 'blocked' && (
        <UnsavedChangesModal
          proceed={handleSubmit(onSubmit)}
          reset={blocker.reset}
          cancel={blocker.proceed}
          isLoading={isSubmitting}
        />
      )}
    </>
  );
}

type GoogleWalletPassProps = {
  payload: GoogleWalletPayload;
};

type GoogleWalletPayload = {
  logo: string | null;
  title: string;
  backgroundColor: string;
  subheading?: string;
  header?: string;
  heroImage: string | null;
};

// reference: https://developers.google.com/wallet/generic/resources/pass-builder
function GoogleGenericPass({ payload }: GoogleWalletPassProps) {
  const { backgroundColor, title, subheading, header, logo, heroImage } =
    payload;

  const textColor = isDarkColor(backgroundColor) ? 'white' : 'black';

  return (
    <div
      className="w-[300px] shadow-xl rounded-md overflow-hidden"
      style={{ color: textColor, backgroundColor }}
    >
      {/* title bar */}
      <div className="flex items-center space-x-3 px-3 pt-3">
        {/* logo */}
        <span className="w-6 h-6 rounded-full bg-white flex-shrink-0 inline-flex items-center justify-center overflow-hidden">
          {logo ? (
            <img src={logo} />
          ) : (
            <TaptUnion className="text-brand-500" width="20" height="20" />
          )}
        </span>
        {/* title */}
        <span className="text-sm font-medium">{title}</span>
      </div>
      {/* divider */}
      <div className="h-[1px]" />
      {/* spacer */}
      <div className="h-[10px]" />
      {/* header */}
      <div className="flex flex-col px-3">
        {subheading && <span className="text-sm">{subheading}</span>}
        {header && <span className="text-xl">{header}</span>}
      </div>

      {/* spacer */}
      <div className="h-1.5" />
      {/* spacer */}
      <div className="h-[10px]" />
      {/* qr code */}
      <div className="py-[5px] flex justify-center">
        <div className="p-[14px] bg-white rounded-xl">
          <img
            src={QRCode}
            className="w-28 h-28 self-center"
            alt="Profile QR Code"
          />
        </div>
      </div>
      {/* spacer */}
      <div className="h-[10px]" />
      {/* hero image */}
      {heroImage && (
        <div className="h-[88px]">
          <img src={heroImage} className="w-full h-full object-cover" />
        </div>
      )}
    </div>
  );
}

type AppleWalletPassProps = {
  payload: AppleWalletPayload;
};

type AppleWalletPayload = Omit<GoogleWalletPayload, 'heroImage'> & {
  textColor: string;
};

// references https://developer.apple.com/design/human-interface-guidelines/wallet#Generic-passes
function AppleGenericPass({ payload }: AppleWalletPassProps) {
  const {
    backgroundColor,
    logo,
    textColor,
    header: name,
    subheading: jobTitle,
    title: companyName,
  } = payload;

  const logoColor = isDarkColor(backgroundColor) ? 'white' : 'black';

  return (
    <div
      className="h-[465px] w-[300px] flex flex-col justify-between rounded-md shadow-xl"
      style={{ backgroundColor, color: textColor }}
    >
      <div className="space-y-6">
        <div className="flex items-center px-4 pt-1">
          {/* logo */}
          {logo ? (
            <div className="w-12 h-12 rounded-full overflow-hidden">
              <img src={logo} width={48} height={48} />
            </div>
          ) : (
            <div className="pt-3">
              <TaptUnion color={logoColor} width="24" height="24" />
            </div>
          )}
        </div>

        <div className="flex justify-between px-4">
          <div className="space-y-10">
            <div className="flex flex-col font-medium">
              <span className="uppercase text-xs">Name</span>
              {name && <span className="text-2xl">{name}</span>}
            </div>
            <div className="space-y-4">
              <div className="flex flex-col text-xs">
                <span className="uppercase font-medium">Job title</span>
                {jobTitle && <span className="font-light">{jobTitle}</span>}
              </div>
              <div className="flex flex-col text-xs">
                <span className="uppercase font-medium">Company</span>
                {companyName && (
                  <span className="font-light">{companyName}</span>
                )}
              </div>
            </div>
          </div>
          <div className="w-20 h-20 rounded-full overflow-hidden">
            <img
              src={DefaultProfilePicture}
              className="w-20 h-20"
              alt="Profile photo"
            />
          </div>
        </div>
      </div>

      <div className="pb-2 w-fit self-center">
        <div className="p-2 rounded-md bg-white place-self-center">
          <img src={QRCode} className="w-36 h-36" alt="Profile QR Code" />
        </div>
      </div>
    </div>
  );
}

type SendPassModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  checkedProfiles: number[];
  onSuccessCallback?: () => void;
};

export function SendPassModal({
  isOpen,
  setIsOpen,
  checkedProfiles,
  onSuccessCallback,
}: SendPassModalProps) {
  const { orgID } = useAuth();

  const [isSending, setIsSending] = useState(false);

  async function sendPass() {
    if (orgID === undefined) {
      return;
    }

    setIsSending(true);

    await organisationsAPI.shareDigitalWallet(orgID, {
      profile_ids: checkedProfiles,
    });

    setIsSending(false);
    setIsOpen(false);
    onSuccessCallback?.();
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle="Share digital wallet pass with profile holders via email"
      dialogDescription="An email will be sent to the selected profile holders inviting them to add their Tapt profile to their digital wallet (either Apple Wallet or Google Wallet)."
      successButtonText="Send pass"
      onSuccess={sendPass}
      isLoading={isSending}
    />
  );
}
