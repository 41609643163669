import React, { useCallback } from 'react';

import { ProgressiveImage } from '@/components/Image';

const IMAGE_SIZE = 93;
const LOGO_SIZE = 24;

interface ProfileImageProps {
  profilePageUrl: string;
  imgSrc?: string | null;
  blurHash?: string | null;
  logoImgSrc?: string | null;
  sampleMode?: boolean;
  size?: number;
  logoSize?: number;
}

export const ProfileImage: React.FC<ProfileImageProps> = ({
  profilePageUrl,
  imgSrc,
  blurHash,
  logoImgSrc,
  sampleMode = false,
  logoSize = LOGO_SIZE,
  size = IMAGE_SIZE,
}) => {
  const renderImageWithLogo = useCallback(() => {
    if (imgSrc) {
      return (
        <div className="relative">
          <ProgressiveImage
            source={imgSrc}
            size={size}
            blurHash={blurHash}
            alt="Profile image"
          />
          {logoImgSrc && (
            <div className="absolute bottom-0 right-0">
              <ProgressiveImage
                source={logoImgSrc}
                alt="Company logo"
                size={logoSize}
              />
            </div>
          )}
        </div>
      );
    }

    if (!imgSrc && logoImgSrc) {
      return (
        <ProgressiveImage
          source={logoImgSrc}
          blurHash={blurHash}
          size={size}
          alt="Company Logo"
        />
      );
    }
  }, [imgSrc, logoImgSrc, blurHash]);

  if (!imgSrc && !logoImgSrc) {
    return null;
  }

  return (
    <td style={{ paddingRight: '16px' }} valign="top">
      <a
        href={profilePageUrl}
        style={{ textDecoration: 'none' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {sampleMode && renderImageWithLogo()}
        {!sampleMode && imgSrc && (
          <ProgressiveImage
            source={imgSrc}
            blurHash={blurHash}
            size={size}
            className="rounded-none"
            alt="Profile image"
          />
        )}
      </a>
    </td>
  );
};
