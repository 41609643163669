import { IInputCheckbox } from '@/types/IInput';

export default function InputCheckbox({
  id = '',
  label = '',
  value,
  onChange,
  labelClassName = 'ml-3',
}: IInputCheckbox) {
  return (
    <div className="relative flex items-center justify-center">
      <div className="flex items-center h-5">
        <input
          id={id}
          name={id}
          type="checkbox"
          required
          checked={!!value}
          onClick={e => e.stopPropagation()}
          onChange={() => onChange(!value)}
          className="h-3.5 w-3.5 text-brand-500 border-gray-300 rounded focus:outline-none focus:ring-0"
        />
      </div>
      <div className={labelClassName}>
        {label ? (
          <label htmlFor={id} className="text-sm leading-5 font-medium">
            {label}
          </label>
        ) : (
          <label htmlFor={id} className="sr-only">
            {`Check ${id}`}
          </label>
        )}
      </div>
    </div>
  );
}
