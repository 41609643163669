import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';

import { EyeIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';

import profilesAPI from '@/api/profiles';
import { ErrorAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import { CompanyInformation } from '@/components/EditProfilePage/CompanyInfoForm';
import { PersonalInformation } from '@/components/EditProfilePage/PersonalInfoForm';
import {
  ClearProfile,
  ContactExchange,
  DeleteProfile,
} from '@/components/EditProfilePage/ProfileOperations';
import { ProfilePageAppearance } from '@/components/EditProfilePage/ProfilePageAppearance';
import { ProfileQuickFileLinks } from '@/components/EditProfilePage/QuickLinksForm';
import { ProfileSocialLinks } from '@/components/EditProfilePage/SocialLinksForm';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import { UnsavedChangesModal } from '@/components/UnsavedChangesPrompt';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import IProfile from '@/types/IProfile';

import { ProfilePreviewModal } from '../ProfilePreview';
import { WorkingProfileForm } from './schema';

type ProfileForm = {
  profile: Partial<IProfile>;
};

export function EditProfileForm() {
  const { userRole, orgID } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [, , profileID] = location.pathname.split('/');
  const {
    handleSubmit,
    formState: { isDirty, errors, isSubmitting, isSubmitSuccessful },
  } = useFormContext<WorkingProfileForm>();

  const { mutateAsync: saveProfile, isError: isProfileError } = useMutation({
    mutationFn: updateProfile,
  });

  // Confirms with the user if they want to navigate away if the form is
  // dirty. Does not intercept everything e.g. refreshes or closing the tab
  // but serves to reduce the possibility of a user accidentally losing their
  // progress.
  const blocker = useBlocker(isDirty && !isSubmitSuccessful);

  // Gating on !isSubmitSuccessful above makes the blocker stop blocking nav
  // after a successful submit. However, this can take a little time (there is a
  // render in between when isSubmitSuccessful is true but the blocker is still
  // ready to block), so we also have this useEffect that lets blocked requests
  // through if submit is succesful but the blocker hasn't gotten a chance to
  // stop blocking yet.
  useEffect(() => {
    if (blocker.state === 'blocked' && isSubmitSuccessful) {
      blocker.proceed();
    }
  }, [blocker, isSubmitSuccessful]);

  const isAdmin = userRole === 'org_admin';

  async function onSubmit(data: WorkingProfileForm) {
    const profileForm: ProfileForm = {
      profile: {
        first_name: data.first_name,
        middle_name: data.middle_name,
        last_name: data.last_name,
        city: data.city,
        state: data.state,
        country: data.country,
        post_code: data.post_code,
        company_name: data.company_name,
        company_website: data.company_website,
        company_phone_number: data.company_phone_number,
        email: data.email,
        mobile_number: data.mobile_number,
        extra_emails: data.extra_emails?.filter(email => !!email),
        extra_mobile_numbers: data.extra_mobile_numbers?.filter(
          phone => !!phone,
        ),
        extra_company_phone_numbers: data.extra_company_phone_numbers?.filter(
          phone => !!phone,
        ),
        photo_file_id: data.photo?.id || null,
        profile_file_links: data.profile_file_links.filter(
          link => link.status !== 'incomplete',
        ),
        profile_quick_links: data.profile_quick_links,
        profile_social_links: data.profile_social_links,
        note: data.note,
        job_title: data.job_title,
        contact_exchange_enabled: data.contact_exchange_enabled,
        pronouns: data.pronouns,
        street_address: data.street_address,
        settings: data.settings,
        settings_level: data.settings_level,
      },
    };

    await saveProfile(profileForm);

    navigate('/', {
      state: {
        success: MESSAGES.profile.edit.success,
        setupInfo: true,
      },
    });
  }

  async function updateProfile(data: ProfileForm) {
    if (orgID === undefined) {
      return;
    }

    await profilesAPI.updateProfile({
      orgID,
      profileID: parseInt(profileID),
      body: data,
    });
  }

  const hasFormErrors = Object.keys(errors).length > 0;

  return (
    <div className="pb-20">
      {hasFormErrors && (
        <div className="pb-6">
          <ErrorAlert message="Please review and correct the form to continue" />
        </div>
      )}
      {isProfileError && (
        <div className="pb-6">
          <ErrorAlert message={MESSAGES.error.generic} />
        </div>
      )}
      <PersonalInformation />
      <InfoPanelDivider />
      <ProfileSocialLinks />
      <InfoPanelDivider />
      <CompanyInformation />
      <InfoPanelDivider />
      <ProfileQuickFileLinks />
      <InfoPanelDivider />
      <ContactExchange />
      <InfoPanelDivider />
      <ProfilePageAppearance />
      {isAdmin && (
        <>
          <InfoPanelDivider />
          <ClearProfile />
          <InfoPanelDivider />
          <DeleteProfile />
        </>
      )}
      <InfoPanelFooter>
        <div className="flex justify-between space-x-4">
          <EditProfilePreview />
          <div className="flex justify-end space-x-4">
            <Button
              buttonText="Cancel"
              kind={BUTTON_KIND.WHITE}
              onClick={() => navigate('/')}
            />
            <Button
              buttonText="Save changes"
              onClick={handleSubmit(onSubmit)}
              loading={isSubmitting}
            />
          </div>
        </div>
      </InfoPanelFooter>
      {blocker.state === 'blocked' && (
        <UnsavedChangesModal
          proceed={handleSubmit(onSubmit)}
          cancel={blocker.proceed}
          reset={blocker.reset}
          isLoading={isSubmitting}
        />
      )}
    </div>
  );
}

function EditProfilePreview() {
  const { getValues } = useFormContext<WorkingProfileForm>();

  const [isProfilePreviewOpen, setIsProfilePreviewOpen] = useState(false);

  const settings = {
    bgColor: getValues('settings.bg_color'),
    textColor: getValues('settings.text_color'),
    buttonBgColor: getValues('settings.button_bg_color'),
    buttonTextColor: getValues('settings.button_text_color'),
    companyLogo: getValues('settings.logo'),
  };
  const profileContent = {
    first_name: getValues('first_name'),
    middle_name: getValues('middle_name'),
    last_name: getValues('last_name'),
    company_name: getValues('company_name'),
    email: getValues('email'),
    mobile_number: getValues('mobile_number'),
    job_title: getValues('job_title'),
    pronouns: getValues('pronouns'),
    photo: getValues('photo'),
    company_phone_number: getValues('company_phone_number'),
    company_website: getValues('company_website'),
    street_address: getValues('street_address'),
    city: getValues('city'),
    state: getValues('state'),
    postCode: getValues('post_code'),
    country: getValues('country'),
    profile_file_links: getValues('profile_file_links')?.filter(
      link => link.status !== 'incomplete',
    ),
    profile_quick_links: getValues('profile_quick_links'),
    profile_social_links: getValues('profile_social_links'),
    note: getValues('note'),
  };

  return (
    <>
      <Button
        buttonText="Preview"
        kind={BUTTON_KIND.WHITE}
        icon={<EyeIcon />}
        onClick={() => setIsProfilePreviewOpen(true)}
      />
      <ProfilePreviewModal
        isOpen={isProfilePreviewOpen}
        setIsOpen={setIsProfilePreviewOpen}
        settings={settings}
        content={profileContent}
      />
    </>
  );
}
