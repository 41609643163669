import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useBlocker } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import organisationsAPI from '@/api/organisations';
import SampleQRCode from '@/assets/images/email_signature_qr.png';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import ColorPicker from '@/components/ColorPicker';
import { InfoPanelContainer } from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import {
  DetailedEmailSignaturePreview,
  SimplifiedEmailSignaturePreview,
} from '@/components/Templates/EmailSignature/Preview';
import {
  sampleProfile,
  sampleProfileUrl,
} from '@/components/Templates/EmailSignature/data';
import { ShareWithTeamButton } from '@/components/Templates/ShareWithTeamButton';
import ToggleField from '@/components/ToggleField';
import { UnsavedChangesModal } from '@/components/UnsavedChangesPrompt';
import UploadPhoto from '@/components/UploadPhoto';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import { FileSchema } from '@/types/IFile';
import {
  IEmailSignature,
  UpdateEmailSignatureRequest,
} from '@/types/IOrganisation';

const emailSignatureFormSchema = z.object({
  type: z.union([z.literal('compact'), z.literal('detailed')]),
  // personal fields
  show_full_name: z.boolean(),
  show_profile_image: z.boolean(),
  show_job_title: z.boolean(),
  show_mobile_number: z.boolean(),
  show_email: z.boolean(),
  show_social_links: z.boolean(),
  // company fields
  show_company_name: z.boolean(),
  show_company_website: z.boolean(),
  show_company_phone: z.boolean(),
  show_company_address: z.boolean(),
  logo_file: FileSchema.nullable(),
  banner_file: FileSchema.nullable(),
  // colors
  background_color: z.string(),
  text_color: z.string(),
  primary_text_color: z.string(),
  show_qr_code: z.boolean(),
  show_add_to_contacts: z.boolean(),
  disclaimer_text: z.string().nullable(),
  show_banner: z.boolean(),
});

type EmailSignatureForm = z.infer<typeof emailSignatureFormSchema>;

const formDefaultValues: IEmailSignature = {
  type: 'detailed',
  show_full_name: true,
  show_profile_image: false,
  show_job_title: false,
  show_mobile_number: false,
  show_email: false,
  show_social_links: false,
  // company fields
  show_company_name: false,
  show_company_website: false,
  show_company_phone: false,
  show_company_address: false,
  logo_file: null,
  banner_file: null,
  // colors
  background_color: '#FFFFFF',
  text_color: '#000000',
  primary_text_color: '#2B2B2B',
  show_qr_code: false,
  show_add_to_contacts: false,
  disclaimer_text: '',
  show_banner: false,
};

export default function EmailSignature() {
  const { orgID } = useAuth();

  const { data: emailSignature, isFetching: isFetchingSignature } = useQuery({
    queryKey: ['getEmailSignature', orgID],
    queryFn: getEmailSignature,
    enabled: orgID !== undefined,
    refetchOnWindowFocus: false,
  });
  const {
    mutateAsync: saveEmailSignature,
    isError: isSaveEmailSignatureError,
  } = useMutation({
    mutationFn: updateEmailSignature,
  });

  const {
    setValue,
    register,
    watch,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = useForm<EmailSignatureForm>({
    defaultValues: formDefaultValues,
    resolver: zodResolver(emailSignatureFormSchema),
  });

  const blocker = useBlocker(isDirty && !isSubmitSuccessful);

  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );

  const {
    type,
    show_full_name: showFullName,
    show_profile_image: showProfileImage,
    show_job_title: showJobTitle,
    show_mobile_number: showMobileNumber,
    show_email: showEmail,
    show_social_links: showSocialLinks,
    show_company_name: showCompanyName,
    show_company_address: showCompanyAddress,
    show_company_phone: showCompanyPhone,
    show_company_website: showCompanyWebsite,
    logo_file: logoFile,
    banner_file: bannerFile,
    background_color: backgroundColor,
    text_color: textColor,
    primary_text_color: primaryTextColor,
    show_banner: showBanner,
    show_qr_code: showQRCode,
    show_add_to_contacts: showAddToContacts,
    disclaimer_text: disclaimer,
  } = watch();

  useEffect(() => {
    if (emailSignature) {
      reset({
        type: emailSignature.type,
        show_full_name: emailSignature.show_full_name,
        show_profile_image: emailSignature.show_profile_image,
        show_job_title: emailSignature.show_job_title,
        show_mobile_number: emailSignature.show_job_title,
        show_email: emailSignature.show_email,
        show_social_links: emailSignature.show_social_links,
        show_company_name: emailSignature.show_company_name,
        show_company_address: emailSignature.show_company_address,
        show_company_website: emailSignature.show_company_website,
        show_company_phone: emailSignature.show_company_phone,
        logo_file: emailSignature.logo_file,
        banner_file: emailSignature.banner_file,
        background_color: emailSignature.background_color,
        text_color: emailSignature.text_color,
        primary_text_color: emailSignature.primary_text_color,
        show_banner: emailSignature.show_banner,
        show_qr_code: emailSignature.show_qr_code,
        show_add_to_contacts: emailSignature.show_add_to_contacts,
        disclaimer_text: emailSignature.disclaimer_text,
      });
    }
  }, [emailSignature]);

  useEffect(() => {
    if (blocker.state === 'blocked' && isSubmitSuccessful) {
      blocker.proceed();
    }
  }, [blocker, isSubmitSuccessful]);

  async function getEmailSignature() {
    if (orgID === undefined) {
      return;
    }

    const { data } = await organisationsAPI.getEmailSignature(orgID);

    return data.data;
  }

  async function onSubmit(data: EmailSignatureForm) {
    const emailSignature: UpdateEmailSignatureRequest = {
      type: data.type,
      show_full_name: data.show_full_name,
      show_profile_image: data.show_profile_image,
      show_job_title: data.show_job_title,
      show_mobile_number: data.show_mobile_number,
      show_email: data.show_email,
      show_social_links: data.show_social_links,
      show_company_name: data.show_company_name,
      show_company_website: data.show_company_website,
      show_company_phone: data.show_company_phone,
      show_company_address: data.show_company_address,
      background_color: data.background_color,
      text_color: data.text_color,
      primary_text_color: data.primary_text_color,
      show_qr_code: data.show_qr_code,
      show_add_to_contacts: data.show_add_to_contacts,
      show_banner: data.show_banner,
      disclaimer_text: data.disclaimer_text,
      banner_file_id: data.banner_file?.id || null,
      logo_file_id: data.logo_file?.id || null,
    };

    if (successMessage) {
      setSuccessMessage(undefined);
    }

    await saveEmailSignature(emailSignature);
    reset(data, { keepValues: true });
  }

  async function updateEmailSignature(signature: UpdateEmailSignatureRequest) {
    if (orgID === undefined) {
      return;
    }

    await organisationsAPI.updateEmailSignature(orgID, signature);
  }

  const personalInformationToggles = [
    {
      label: 'Full name',
      enabled: showFullName,
      setter: (enabled: boolean) =>
        setValue('show_full_name', enabled, { shouldDirty: true }),
      disabled: true,
      message: 'Mandatory field',
      visibility: true,
    },
    {
      label: 'Profile picture',
      enabled: showProfileImage,
      setter: (enabled: boolean) =>
        setValue('show_profile_image', enabled, { shouldDirty: true }),
      visibility: true,
    },
    {
      label: 'Job title',
      enabled: showJobTitle,
      setter: (enabled: boolean) =>
        setValue('show_job_title', enabled, { shouldDirty: true }),
      visibility: true,
    },
    {
      label: 'Mobile number',
      enabled: showMobileNumber,
      setter: (enabled: boolean) =>
        setValue('show_mobile_number', enabled, { shouldDirty: true }),
      visibility: true,
    },
    {
      label: 'Email address',
      enabled: showEmail,
      setter: (enabled: boolean) =>
        setValue('show_email', enabled, { shouldDirty: true }),
      visibility: type === 'detailed',
    },
    {
      label: 'Social media links',
      enabled: showSocialLinks,
      setter: (enabled: boolean) =>
        setValue('show_social_links', enabled, { shouldDirty: true }),
      visibility: true,
    },
  ];

  const companyInformationToggles = [
    {
      label: 'Company name',
      enabled: showCompanyName,
      setter: (enabled: boolean) =>
        setValue('show_company_name', enabled, { shouldDirty: true }),
      visibility: true,
    },
    {
      label: 'Company website',
      enabled: showCompanyWebsite,
      setter: (enabled: boolean) =>
        setValue('show_company_website', enabled, { shouldDirty: true }),
      visibility: type === 'detailed',
    },
    {
      label: 'Company phone number',
      enabled: showCompanyPhone,
      setter: (enabled: boolean) =>
        setValue('show_company_phone', enabled, { shouldDirty: true }),
      visibility: type === 'detailed',
    },
    {
      label: 'Company address',
      enabled: showCompanyAddress,
      setter: (enabled: boolean) =>
        setValue('show_company_address', enabled, { shouldDirty: true }),
      visibility: true,
    },
  ];

  const signature: IEmailSignature = {
    type,
    show_full_name: showFullName,
    show_profile_image: showProfileImage,
    show_job_title: showJobTitle,
    show_mobile_number: showMobileNumber,
    show_email: showEmail,
    show_social_links: showSocialLinks,
    show_company_name: showCompanyName,
    show_company_website: showCompanyWebsite,
    show_company_phone: showCompanyPhone,
    show_company_address: showCompanyAddress,
    background_color: backgroundColor,
    text_color: textColor,
    primary_text_color: primaryTextColor,
    show_qr_code: showQRCode,
    show_add_to_contacts: showAddToContacts,
    show_banner: showBanner,
    disclaimer_text: disclaimer,
    banner_file: bannerFile,
    logo_file: logoFile,
  };

  const emailSignatureTypes = [
    {
      name: 'Simplified',
      value: 'compact',
    },
    {
      name: 'Detailed',
      value: 'detailed',
    },
  ];

  return (
    <>
      {isSaveEmailSignatureError && (
        <div className="pt-8">
          <ErrorAlert message={MESSAGES.error.generic} />
        </div>
      )}
      {(isSubmitSuccessful || successMessage) && (
        <div className="pt-8">
          <SuccessAlert
            message={
              successMessage
                ? successMessage
                : MESSAGES.organisation.emailSignature
            }
          />
        </div>
      )}
      <div className="flex flex-col-reverse xl:flex-row gap-6 pb-[33%] md:pb-[68px]">
        <div className="flex flex-col flex-1">
          <InfoPanelContainer className="mt-8">
            <div className="flex items-center space-x-4">
              {emailSignatureTypes.map((item, index) => (
                <div key={index} className="space-x-2 flex items-center">
                  <input
                    type="radio"
                    name="email-signature-type"
                    checked={type === item.value}
                    value={item.value}
                    onChange={e =>
                      setValue('type', e.target.value as 'compact' | 'detailed')
                    }
                    className="ring-brand-500 border-gray-300 text-brand-500 focus:ring-brand-500 cursor-pointer"
                  />
                  <label htmlFor={item.name}>{item.name}</label>
                </div>
              ))}
            </div>
          </InfoPanelContainer>

          <InfoPanelDivider />

          <InfoPanelContainer>
            <span className="text-xl font-medium text-gray-900">
              Personal Information
            </span>
            <InfoPanelDivider />
            <div className="space-y-4">
              {personalInformationToggles.map((field, index) =>
                field.visibility ? (
                  <ToggleField
                    key={index}
                    label={field.label}
                    enabled={field.enabled}
                    setter={field.setter}
                    disabled={field.disabled}
                    message={field.message}
                  />
                ) : null,
              )}
            </div>
          </InfoPanelContainer>

          <InfoPanelDivider />

          <InfoPanelContainer>
            <span className="text-xl font-medium text-gray-900">
              Company Information
            </span>
            <InfoPanelDivider />
            <div className="space-y-4">
              {companyInformationToggles.map((field, index) =>
                field.visibility ? (
                  <ToggleField
                    key={index}
                    label={field.label}
                    enabled={field.enabled}
                    setter={field.setter}
                  />
                ) : null,
              )}

              <div className="flex flex-col text-sm space-y-1">
                <span>Disclaimer</span>
                <textarea
                  className="min-h-[100px] rounded-md border border-gray-300 focus:ring-brand-500 focus:border-brand-500 p-2 text-sm "
                  placeholder="Add your email's legal or confidentiality disclaimer text here"
                  {...register('disclaimer_text')}
                />
              </div>
            </div>
          </InfoPanelContainer>

          <InfoPanelDivider />

          <InfoPanelContainer>
            <span className="text-xl font-medium text-gray-900">
              Branding &amp; colours
            </span>
            <InfoPanelDivider />
            {type === 'detailed' && (
              <>
                <UploadPhoto
                  title="Banner image"
                  fileFormatMessage={`Recommended dimensions: 492px x 202px\nMax file size 8MB (.jpeg or .png only)`}
                  photo={bannerFile || undefined}
                  setPhoto={photo =>
                    setValue('banner_file', photo || null, {
                      shouldDirty: true,
                    })
                  }
                  size="large"
                  // 4x of banner size 246x101
                  maxWidth={984}
                  maxHeight={404}
                  aspectRatio={984 / 404}
                />
                <InfoPanelDivider />
              </>
            )}
            <UploadPhoto
              title="Company logo mark/icon"
              fileFormatMessage={`Recommended dimensions: 100px x 100px\nMax file size 8MB (.jpeg or .png only)`}
              photo={logoFile || undefined}
              setPhoto={photo =>
                setValue('logo_file', photo || null, { shouldDirty: true })
              }
              maxWidth={100}
              maxHeight={100}
            />
            <InfoPanelDivider />
            <div className="space-y-4">
              <div className="flex flex-col lg:flex-row md:gap-6 space-y-6 lg:space-y-0">
                <div className="flex flex-col">
                  <p className="mb-2 font-medium">Primary text colour</p>
                  <ColorPicker
                    color={primaryTextColor}
                    setColor={color =>
                      setValue('primary_text_color', color, {
                        shouldDirty: true,
                      })
                    }
                  />
                </div>

                <div className="flex flex-col">
                  <p className="mb-2 font-medium">Secondary text colour</p>
                  <ColorPicker
                    color={textColor}
                    setColor={color =>
                      setValue('text_color', color, { shouldDirty: true })
                    }
                  />
                </div>
              </div>
            </div>
          </InfoPanelContainer>

          <InfoPanelDivider />

          <InfoPanelContainer>
            <span className="text-xl font-medium text-gray-900">
              Link your Tapt profile
            </span>
            <InfoPanelDivider />
            <div className="space-y-4">
              {type === 'detailed' && (
                <ToggleField
                  label={`'Add to contact' button`}
                  enabled={showAddToContacts}
                  setter={enabled =>
                    setValue('show_add_to_contacts', enabled, {
                      shouldDirty: true,
                    })
                  }
                />
              )}
              <ToggleField
                label="QR Code"
                enabled={showQRCode}
                setter={enabled =>
                  setValue('show_qr_code', enabled, { shouldDirty: true })
                }
              />
            </div>
          </InfoPanelContainer>

          <InfoPanelFooter>
            <div className="flex items-center justify-between">
              <ShareWithTeamButton
                modalTitle="Share email signatures with profile holders via email"
                action={organisationsAPI.shareEmailSignature}
                onSuccessCallback={() =>
                  setSuccessMessage(
                    'Email signatures have been sent to the selected profile holders.',
                  )
                }
              />
              <div className="flex justify-end space-x-4">
                <Button
                  buttonText="Cancel"
                  kind={BUTTON_KIND.WHITE}
                  onClick={window.location.reload}
                  disabled={isSubmitting}
                />
                <Button
                  buttonText="Save changes"
                  onClick={handleSubmit(onSubmit)}
                  loading={isSubmitting}
                />
              </div>
            </div>
          </InfoPanelFooter>

          {blocker.state === 'blocked' && (
            <UnsavedChangesModal
              proceed={handleSubmit(onSubmit)}
              reset={blocker.reset}
              cancel={blocker.proceed}
              isLoading={isSubmitting}
            />
          )}
        </div>
        <div className="xl:sticky xl:top-0 h-fit z-100">
          <InfoPanelContainer className="flex-1 w-full mt-8">
            <div className="rounded-md shadow-md p-3 overflow-scroll">
              {isFetchingSignature ? (
                <Skeleton width={450} className="h-36" />
              ) : type === 'compact' ? (
                <div style={{ zoom: 0.8 }}>
                  <SimplifiedEmailSignaturePreview
                    signature={signature}
                    profile={sampleProfile}
                    assets={{
                      profile_image_url: sampleProfileUrl,
                      qr_code_url: SampleQRCode,
                      banner_image_url: bannerFile?.original_url || null,
                    }}
                    sampleMode
                  />
                </div>
              ) : (
                <div style={{ zoom: 0.8 }}>
                  <DetailedEmailSignaturePreview
                    signature={signature}
                    profile={sampleProfile}
                    assets={{
                      profile_image_url: sampleProfileUrl,
                      qr_code_url: SampleQRCode,
                      banner_image_url: bannerFile?.original_url || null,
                    }}
                    sampleMode
                  />
                </div>
              )}
            </div>
          </InfoPanelContainer>
        </div>
      </div>
    </>
  );
}
