import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  ArrowRightIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';

import cardsAPI from '@/api/cards';
import { ReactComponent as MenuIcon } from '@/assets/svg/navbar-minimal-menu-open.svg';
import { ActivateProductsButton } from '@/components/ActivateProductsButton';
import { Alert, ErrorAlert, SuccessAlert } from '@/components/Alert';
import Filter, {
  DEFAULT_PRODUCT_OPTION,
  GroupFilterOption,
} from '@/components/Filter';
import Copy from '@/components/Icons/Copy';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import Modal from '@/components/Modals/Modal';
import Pagination from '@/components/Pagination';
import {
  ProductItemRow,
  ProductItemRowMobile,
} from '@/components/ProductsPage/ProductListItem';
import Search from '@/components/Search';
import Sort, { ISortOption } from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import { getVariantCategory } from '@/helpers/products';
import useAuth from '@/hooks/useAuth';

const sortOptions: ISortOption[] = [
  {
    sort: 'date',
    order: 'desc',
    label: 'Added (newest first)',
  },
  {
    sort: 'date',
    order: 'asc',
    label: 'Added (oldest first)',
  },
  {
    sort: 'assigned_date',
    order: 'desc',
    label: 'Reassigned (newest first)',
  },
  {
    sort: 'assigned_date',
    order: 'asc',
    label: 'Reassigned (oldest first)',
  },
  {
    sort: 'type',
    order: 'asc',
    label: 'Product type (A-Z)',
  },
  {
    sort: 'type',
    order: 'desc',
    label: 'Product type (Z-A)',
  },
  {
    sort: 'profile_name',
    order: 'asc',
    label: 'Profile name (A-Z)',
  },
  {
    sort: 'profile_name',
    order: 'desc',
    label: 'Profile name (Z-A)',
  },
];

export default function ProductsListPage() {
  const { orgID, userRole } = useAuth();
  const hasOrgAdminAccess = userRole === 'org_admin';

  const [sort, setSort] = useState('assigned_date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedFilter, setSelectedFilter] = useState<
    GroupFilterOption<string[]>
  >(DEFAULT_PRODUCT_OPTION);

  const [actionResult, setActionResult] = useState<Alert | undefined>(
    undefined,
  );
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const { data: cards, refetch: refetchCards } = useQuery({
    queryKey: [
      'listCards',
      page,
      pageSize,
      sort,
      order,
      search,
      selectedFilter.id,
    ],
    queryFn: () => listCards({ page, pageSize, sort, order, search }),
    enabled: orgID !== undefined,
  });

  const { data: cardTypes } = useQuery({
    queryKey: ['listCardTypes', orgID],
    queryFn: listCardTypes,
    enabled: orgID !== undefined,
  });

  async function listCards({
    page,
    pageSize,
    sort,
    order,
    search,
  }: {
    page: number;
    pageSize: number;
    search: string;
    sort: string;
    order: string;
  }) {
    const types =
      selectedFilter.id[0] === 'all_products' ? undefined : selectedFilter.id;

    const res = await cardsAPI.listCards({
      orgID,
      page,
      pageSize,
      sort,
      order,
      search,
      types,
    });

    return res.data;
  }

  async function listCardTypes() {
    if (orgID === undefined) {
      return;
    }

    return await cardsAPI.listCardTypes(orgID);
  }

  const filterOptions = cardTypes
    ? [
        DEFAULT_PRODUCT_OPTION,
        // Transform cardTypes.data into a grouped structure
        ...Object.entries(
          cardTypes.data.reduce((acc: Record<string, string[]>, type) => {
            // Get the category name for the current type
            const name = getVariantCategory(type);

            // Add the type to its category array, or create a new array if it doesn't exist
            acc[name] = acc[name] ? [...acc[name], type] : [type];
            return acc;
          }, {}),
          // Convert the grouped structure into an array of GroupFilterOption
        ).map(([key, value]) => ({ id: value, name: key })),
      ]
    : undefined;

  return (
    <Layout
      pageName="Products"
      className="bg-gray-50"
      rightTitle={<ProductIdentification />}
    >
      <div className="space-y-8">
        {actionResult &&
          (actionResult.outcome === 'success' ? (
            <SuccessAlert
              message={actionResult.message}
              actionButton={actionResult.action}
            />
          ) : (
            <ErrorAlert
              message={actionResult.message}
              actionButton={actionResult.action}
            />
          ))}
        <div className="flex flex-col xl:flex-row xl:items-center justify-end space-y-6 xl:space-y-0 xl:space-x-2">
          <Search
            id={`products-page-search-${search}-${page}-${pageSize}-${order}`}
            search={search}
            setSearch={setSearch}
            fetchQuery={searchQuery =>
              listCards({ page, pageSize, order, sort, search: searchQuery })
            }
          />
          <div className="flex flex-row flex-wrap sm:justify-end gap-2">
            {filterOptions ? (
              filterOptions.length > 2 ? (
                <Filter
                  id={`products-page-filter-${selectedFilter.id}`}
                  filter="groups"
                  selected={selectedFilter}
                  setSelected={setSelectedFilter}
                  data={filterOptions}
                />
              ) : null
            ) : (
              <Skeleton width={140} height={38} />
            )}
            <Sort
              id={`products-page-sort-${sort}-${order}`}
              options={sortOptions}
              sort={sort}
              setSort={setSort}
              order={order}
              setOrder={setOrder}
              fetchQuery={(sortQuery, orderQuery) =>
                listCards({
                  page,
                  pageSize,
                  sort: sortQuery,
                  order: orderQuery,
                  search,
                })
              }
            />
            {hasOrgAdminAccess && (
              <ActivateProductsButton
                setActionResult={setActionResult}
                refreshCards={refetchCards}
              />
            )}
          </div>
        </div>
        {cards ? (
          cards.data.length > 0 ? (
            <>
              <div className="hidden xl:block relative border border-solid rounded-lg">
                <table
                  className={clsx('w-full max-w-full table-auto', {
                    'opacity-40': isPaginationLoading,
                  })}
                >
                  <thead className="bg-gray-100 border-b border-gray-200">
                    <tr className="uppercase text-gray-900">
                      <th
                        scope="col"
                        className="font-medium text-left text-sm py-3 px-4 w-auto"
                      >
                        Type &amp; id
                      </th>
                      <th
                        scope="col"
                        className="font-medium text-sm text-left p-3"
                      >
                        Assigned
                      </th>
                      <th
                        scope="col"
                        className="font-medium text-left text-sm p-3 w-auto"
                      >
                        Linked Profile
                      </th>
                      <th scope="col" className="font-medium text-sm p-3">
                        View profile
                      </th>
                      <th scope="col" className="font-medium text-sm py-3 px-4">
                        Allocation
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {cards.data.map((card, index) => (
                      <ProductItemRow
                        key={index}
                        card={card}
                        setActionResult={setActionResult}
                        refreshCards={refetchCards}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="block xl:hidden space-y-4">
                <div className="bg-gray-100 flex border border-gray-300 rounded-md font-medium p-3 uppercase">
                  Type &amp; id, created, linked profile, view profile,
                  allocation
                </div>
                {cards.data.map((card, index) => (
                  <ProductItemRowMobile
                    key={index}
                    card={card}
                    setActionResult={setActionResult}
                    refreshCards={refetchCards}
                  />
                ))}
              </div>
              {isPaginationLoading && (
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                </div>
              )}
            </>
          ) : (
            <div className="py-32">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.profile.list.empty.heading}
              </h3>
              <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.profile.list.empty.description}
              </p>
            </div>
          )
        ) : (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
          </div>
        )}
        <Pagination
          id={`products-page-pagination-${page}-${pageSize}-${search}-${selectedFilter.id}`}
          className="bg-gray-50"
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setIsLoading={setIsPaginationLoading}
          fetchQuery={(pageQuery, pageSizeQuery) =>
            listCards({
              page: pageQuery,
              pageSize: pageSizeQuery,
              sort,
              order,
              search,
            })
          }
        />
      </div>
    </Layout>
  );
}

function ProductIdentification() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const content = [
    <>Scan/tap your products and open the profile in browser.</>,
    <span className="flex items-center gap-1 flex-wrap leading-normal">
      Click the menu button icon <MenuIcon className="w-4 h-4" /> (top of
      profile).
    </span>,
    <span className="flex items-center gap-1 flex-wrap">
      Click the
      <Copy className="w-4 h-4 text-brand-500" />
      button to copy the ID.
    </span>,
    <>Paste the ID into the search bar on the product page.</>,
    <>All done! Your product should appear at the top of the table.</>,
  ];

  return (
    <div className="flex items-center border border-amber-300 bg-amber-50 rounded-md text-sm p-2 font-medium gap-2 self-end w-full md:w-auto">
      <InformationCircleIcon
        className="w-5 h-5 text-amber-500 self-start"
        strokeWidth={2}
      />
      <div className="flex flex-col md:flex-row text-sm gap-2">
        <span className="text-amber-800 font-medium">
          You can reassign activated products
        </span>
        <button
          type="button"
          className="flex items-center text-amber-500 gap-0.5"
          onClick={() => setIsModalOpen(true)}
        >
          How to identify products{' '}
          <ArrowRightIcon className="w-4 h-4" strokeWidth={2} />
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        dialogTitle="How to identify products"
        dialogDescription="If you are unable to differentiate between products, follow these steps to help identify the physical product."
        cancelButtonText="Close"
        onSuccess={() => setIsModalOpen(false)}
      >
        <div className="border border-gray-300 rounded-md bg-gray-50 p-3 mt-4">
          <ol className="list-decimal text-sm leading-7 pl-6">
            {content.map((line, index) => (
              <li key={index} className="text-gray-600">
                {line}
              </li>
            ))}
          </ol>
        </div>
      </Modal>
    </div>
  );
}
