import { compareAsc, differenceInCalendarDays } from 'date-fns';

import useAuth from '@/hooks/useAuth';
import {
  IPlatformSubscriptionFull,
  IPlatformSubscriptionPaymentDetails,
  IPlatformSubscriptionUpgradePayment,
} from '@/types/IOrganisation';

import RenewalOverdue from './RenewalOverdue';
import RenewalUpcomingBanner from './RenewalUpcomingBanner';
import UpgradeOverdue from './UpgradeOverdue';
import UpgradeUpcoming from './UpgradeUpcoming';

// Days before renewal is overdue (negative for pre-threshold)
const UPCOMING_RENEWAL_THRESHOLD_IN_DAYS = -30;

const renderRenewal = (renewalPayment: IPlatformSubscriptionPaymentDetails) => {
  const today = new Date();
  const daysUntilRenewal = differenceInCalendarDays(
    today,
    new Date(renewalPayment.date),
  );

  if (daysUntilRenewal >= 0) {
    if (compareAsc(today, new Date(renewalPayment.date)) != 1) {
      return (
        <RenewalUpcomingBanner daysUntilRenewal={1} />
      );
    }

    const daysUntilAccessLimit = differenceInCalendarDays(
      new Date(renewalPayment.grace_period_end_date),
      today,
    );
    return (
      <RenewalOverdue
        daysLeft={daysUntilAccessLimit}
        payment={renewalPayment}
      />
    );
  }

  if (
    daysUntilRenewal > UPCOMING_RENEWAL_THRESHOLD_IN_DAYS &&
    daysUntilRenewal < 0
  ) {
    return (
      <RenewalUpcomingBanner daysUntilRenewal={Math.abs(daysUntilRenewal)} />
    );
  }

  return null;
};

const renderUpgrade = (upgradePayment: IPlatformSubscriptionUpgradePayment) => {
  const today = new Date();
  const daysUntilUpgrade = differenceInCalendarDays(
    today,
    new Date(upgradePayment.date),
  );

  if (daysUntilUpgrade >= 0) {
    return <UpgradeOverdue payment={upgradePayment} />;
  }

  if (daysUntilUpgrade < 0) {
    return (
      <UpgradeUpcoming payment={upgradePayment} />
    );
  }

  return null;
};

/**
 * Determines if an upgrade payment is valid based on the total number of profiles.
 *
 * @param upgradePayment - The upgrade payment object containing details about the new subscription tier.
 * @param totalProfiles - The total number of profiles to validate against the new tier's constraints.
 * @returns A boolean indicating whether the upgrade payment is valid for the given number of profiles.
 *
 * Note: Customers can delete profiles once they see the upgrade reminders, so this validation ensures
 * the upgrade is still applicable after any profile adjustments. If the total profiles drop below the
 * minimum required for the new tier, the banner will not show.
 */
const isValidUpgradePayment = (
  upgradePayment: IPlatformSubscriptionUpgradePayment,
  totalProfiles: number,
) => {
  const { min_profiles: min, max_profiles: max } = upgradePayment.new_tier;
  return (
    totalProfiles >= min &&
    totalProfiles <= max
  );
};

const isAnnualSubscription = (platformSubscription: IPlatformSubscriptionFull) => {
  return (
    platformSubscription.billing_interval === '1 year'
  );
}

const Container = () => {
  const { platformSubscription, totalProfiles } = useAuth();
  let content = null;

  if (!platformSubscription) return null;

  if (platformSubscription?.type === 'prepaid') return null;

  const { renewal_payment: renewalPayment, upgrade_payment: upgradePayment } =
    platformSubscription;

  if (upgradePayment && isValidUpgradePayment(upgradePayment, totalProfiles)) {
    content = renderUpgrade(upgradePayment);
  } else if (renewalPayment && isAnnualSubscription(platformSubscription)) {
    content = renderRenewal(renewalPayment);
  }

  return content ? <div className="px-3">{content}</div> : null;
};

export default Container;
