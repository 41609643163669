import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';

import contactsAPI from '@/api/contacts';
import organisationsAPI from '@/api/organisations';
import Button, { BUTTON_KIND } from '@/components/Button';
import {
  ContactsListItem,
  ContactsListItemMobile,
} from '@/components/ContactsListItem';
import Filter, { GroupFilterOption } from '@/components/Filter';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import { downloadBlob } from '@/helpers/file';
import useAuth from '@/hooks/useAuth';

const DEFAULT_GROUP_OPTION = {
  id: 0,
  name: 'All groups',
};

export default function ContactListPage() {
  const { user, orgID, userRole } = useAuth();

  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [filterOptions, setFilterOptions] = useState<GroupFilterOption[]>([]);
  const [editorsGroup, setEditorsGroup] = useState<GroupFilterOption[]>([]);
  const [selectedGroup, setSelectedGroup] =
    useState<GroupFilterOption>(DEFAULT_GROUP_OPTION);

  const { isFetching: isGroupsFetching, isFetched: isGroupsReady } = useQuery({
    queryKey: ['listGroups', orgID],
    queryFn: listGroups,
    enabled: orgID !== undefined && user !== undefined,
  });
  const { data: contacts } = useQuery({
    queryKey: [
      'listContacts',
      page,
      pageSize,
      sort,
      order,
      search,
      selectedGroup.id,
      editorsGroup,
    ],
    queryFn: () => {
      return listContacts();
    },
    enabled: isGroupsReady && !isGroupsFetching,
  });

  async function exportCSV() {
    if (orgID === undefined) {
      return;
    }

    const response = await contactsAPI.exportContactsCSV(orgID);
    const blob = new Blob([response.data]);

    downloadBlob(blob, 'contacts.csv');
  }

  async function listGroups() {
    if (orgID === undefined || user === undefined) {
      return;
    }

    const { data: groups } = await organisationsAPI.listOrganisationGroups({
      orgID,
      pageSize: 100,
    });

    const groupList: GroupFilterOption[] = [];

    groups.data.forEach(group => {
      if (group.editors.length > 0) {
        group.editors.forEach(editor => {
          if (editor.user.id === user.id) {
            groupList.push({ id: group.id, name: group.name });
          }

          if (groupList.length === 0 && editor.user.id === user.id) {
            setSelectedGroup({ name: group.name, id: group.id });
          }
        });
      }
    });

    setEditorsGroup(groupList);

    if (userRole === 'org_admin') {
      setFilterOptions([
        DEFAULT_GROUP_OPTION,
        ...groups.data.map(group => ({
          id: group.id,
          name: group.name,
        })),
      ]);
    } else if (userRole === 'org_editor') {
      setFilterOptions([
        DEFAULT_GROUP_OPTION,
        ...groupList.map(group => ({
          id: group.id,
          name: group.name,
        })),
      ]);
    }

    return groups;
  }

  async function listContacts(
    newPage: number = page,
    newPageSize: number = pageSize,
    newSort: string = sort,
    newOrder: string = order,
    newSearch: string = search,
    newGroupID: number = selectedGroup.id,
  ) {
    if (userRole === 'org_editor' && editorsGroup.length === 0) {
      return {
        data: [],
        paging: {
          page_number: 0,
          page_offset: 0,
          page_size: pageSize,
          total_entries: 0,
          total_pages: 0,
        },
      };
    }

    // if fetching all groups, get only the groups that the user is an editor of
    if (newGroupID === 0) {
      const { data: contacts } = await contactsAPI.listContactsInGroups({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        search: newSearch,
        groupIDs: editorsGroup.map(group => group.id),
      });

      setPage(newPage);
      setPageSize(newPageSize);
      setOrder(newOrder);
      setSort(newSort);

      return contacts;
    } else {
      const { data: contacts } = await contactsAPI.listContactsInGroup({
        orgID,
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
        groupID: newGroupID,
      });

      setPage(newPage);
      setPageSize(newPageSize);
      setOrder(newOrder);
      setSort(newSort);

      return contacts;
    }
  }

  return (
    <Layout pageName="Contacts">
      <div className="pb-8 pt-6 md:pt-0 space-y-4">
        <div className="flex flex-wrap items-end xl:space-y-0 justify-end gap-4">
          <Search
            id={`ContactsList-${page}-${pageSize}-${sort}-${order}-${search}`}
            search={search}
            setSearch={setSearch}
            fetchQuery={searchQuery =>
              listContacts(
                page,
                pageSize,
                sort,
                order,
                searchQuery,
                selectedGroup.id,
              )
            }
            fetchEnabled={!isGroupsFetching}
          />
          <Filter
            id={`ContactsListFilter-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
            filter="groups"
            selected={selectedGroup}
            setSelected={setSelectedGroup}
            data={filterOptions}
          />
          <Sort
            id={`ContactsList-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
            options={[
              {
                sort: 'date',
                order: 'desc',
                label: 'Date created (Newest first)',
              },
              {
                sort: 'date',
                order: 'asc',
                label: 'Date created (Oldest first)',
              },
              {
                sort: 'first_name',
                order: 'asc',
                label: 'Name (A-Z)',
              },
              {
                sort: 'first_name',
                order: 'desc',
                label: 'Name (Z-A)',
              },
            ]}
            sort={sort}
            setSort={setSort}
            order={order}
            setOrder={setOrder}
            fetchQuery={(sortQuery, orderQuery) =>
              listContacts(
                page,
                pageSize,
                sortQuery,
                orderQuery,
                search,
                selectedGroup.id,
              )
            }
            fetchQueryEnabled={!isGroupsFetching}
          />
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Export via CSV"
            onClick={exportCSV}
          />
        </div>
        {contacts ? (
          contacts.data.length > 0 ? (
            <>
              <div className="hidden xl:block border border-solid rounded-lg">
                <table
                  className={clsx('w-full max-w-full table-fixed', {
                    'opacity-40': isPaginationLoading,
                  })}
                >
                  <thead className="bg-gray-100 border-b border-gray-200">
                    <tr className="uppercase text-gray-900">
                      <th
                        scope="col"
                        className="font-medium py-3 px-4 text-left text-sm w-1/3"
                      >
                        Name &amp; email
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-left text-sm w-1/4"
                      >
                        Position &amp; company
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-left text-sm w-1/5"
                      >
                        Connected with
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-left text-sm"
                      >
                        Date
                      </th>
                      <th scope="col" className="font-medium px-4 py-3 text-sm">
                        View
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {contacts.data.map((contact, index) => (
                      <ContactsListItem key={index} contact={contact} />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="block xl:hidden space-y-4">
                <div className="bg-gray-100 flex xl:hidden flex-row border border-gray-300 rounded-md uppercase p-4 font-medium">
                  Connected with, date, name &amp; email, position &amp;
                  company, view
                </div>
                {contacts.data.map((contact, index) => (
                  <ContactsListItemMobile key={index} contact={contact} />
                ))}
              </div>
              {isPaginationLoading && (
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                </div>
              )}
            </>
          ) : (
            <div className="py-32">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.contact.list.empty.heading}
              </h3>
              <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.contact.list.empty.description}{' '}
                <a
                  href="https://tapt.io/pages/how-to-use"
                  rel="noreferrer"
                  target="_blank"
                >
                  Learn more.
                </a>
              </p>
            </div>
          )
        ) : (
          <div className="flex flex-col justify-center min-h-screen py-6 bg-white sm:px-6 lg:px-8">
            <div>
              <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
            </div>
          </div>
        )}
      </div>
      <Pagination
        id={`ContactsList-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(pageQuery, pageSizeQuery) =>
          listContacts(
            pageQuery,
            pageSizeQuery,
            sort,
            order,
            search,
            selectedGroup.id,
          )
        }
        fetchQueryEnabled={!isGroupsFetching}
        setIsLoading={setIsPaginationLoading}
      />
    </Layout>
  );
}
