import { ImgHTMLAttributes, useState } from 'react';
import { Blurhash } from 'react-blurhash';

type ProgressiveImageProps = {
  source: string;
  blurHash?: string | null;
  hideAltText?: boolean;
  size?: number;
} & Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>;

export function ProgressiveImage({
  source,
  blurHash,
  size,
  hideAltText = true,
  ...props
}: ProgressiveImageProps) {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <div className="relative rounded-full">
      {blurHash && isImageLoading && (
        <div
          className="absolute inset-0 overflow-hidden rounded-full"
          style={{ width: size, height: size }}
        >
          <Blurhash hash={blurHash} />
        </div>
      )}
      <img
        src={source}
        width={size}
        height={size}
        style={
          hideAltText
            ? {
                fontSize: 0,
              }
            : undefined
        }
        className="rounded-full"
        onLoad={() => setIsImageLoading(false)}
        onError={() => console.log('error')}
        {...props}
      />
    </div>
  );
}
