import React from 'react';

import { ensureAbsoluteUrl } from '@/helpers/urlHandler';
import { IEmailSignature, SupportedClient } from '@/types/IOrganisation';
import { IProfileSignature } from '@/types/IProfile';

// Constants
const MAX_COMBINED_TEXT_LENGTH = 52;
const ITEMS_PER_ROW = 2;

// Types
interface ContactInfoProps {
  signature: IEmailSignature;
  profile: IProfileSignature;
  clientType: SupportedClient;
}

interface ContactItem {
  icon: string;
  text: string | null;
  type: string;
  link?: string | null;
  paddingTop: string;
}

// Helper Functions
const addRef = (url: string, ref: string): string => {
  const urlObj = new URL(url);
  urlObj.searchParams.set('ref', ref);
  return urlObj.toString();
};

const groupItemsIntoRows = (items: ContactItem[]): ContactItem[][] => {
  const rows: ContactItem[][] = [];
  let i = 0;

  while (i < items.length) {
    const currentItem = items[i];
    const isLastItem = i === items.length - 1;

    if (isLastItem) {
      rows.push([currentItem]);
      break;
    }

    const nextItem = items[i + 1];
    const combinedLength =
      (currentItem.text?.length || 0) + (nextItem.text?.length || 0);

    if (combinedLength > MAX_COMBINED_TEXT_LENGTH) {
      rows.push([currentItem]);
      i += 1;
    } else {
      const remainingItems = items.length - i;
      const itemsToTake = Math.min(ITEMS_PER_ROW, remainingItems);
      rows.push(items.slice(i, i + itemsToTake));
      i += itemsToTake;
    }
  }

  return rows;
};

export const ContactInfo: React.FC<ContactInfoProps> = ({
  signature,
  profile,
  clientType,
}) => {
  // Styles
  const styles = {
    common: {
      color: signature.text_color,
      fontFamily: 'Verdana, Geneva, sans-serif',
      fontWeight: 400,
      lineHeight: '21px',
      height: '21px',
      textAlign: 'left' as const,
      verticalAlign: 'middle',
    },
    separator: {
      width: '18px',
      textAlign: 'center' as const,
      color: '#E6E6E6',
      verticalAlign: 'middle',
    },
    link: {
      color: signature.text_color,
      fontFamily: 'Verdana, Geneva, sans-serif',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '21px',
      textDecoration: clientType === 'outlook' ? 'none' : 'unset',
    },
  };

  // Generate address string if company address should be shown
  const address = signature.show_company_address
    ? [
        profile.street_address,
        profile.city,
        profile.state,
        profile.post_code,
        profile.country,
      ]
        .filter(Boolean)
        .join(', ')
    : null;

  // Contact items configuration
  const contactItems: ContactItem[] = [
    {
      icon: 'https://assets.tapt.io/email/email.png',
      text: signature.show_email ? profile.email : null,
      type: 'email',
      paddingTop: '3px',
    },
    {
      icon: 'https://assets.tapt.io/email/mobile.png',
      text: signature.show_mobile_number ? profile.mobile_number : null,
      type: 'mobile',
      paddingTop: '0',
    },
    {
      icon: 'https://assets.tapt.io/email/phone.png',
      text: signature.show_company_phone ? profile.company_phone_number : null,
      type: 'phone',
      paddingTop: '3px',
    },
    {
      icon: 'https://assets.tapt.io/email/globe.png',
      text: signature.show_company_website ? profile.company_website : null,
      link: signature.show_company_website
        ? ensureAbsoluteUrl(profile.company_website)
        : null,
      type: 'website',
      paddingTop: '3px',
    },
    {
      icon: 'https://assets.tapt.io/email/location-marker.png',
      text: address,
      type: 'address',
      paddingTop: '3px',
    },
  ];

  if (signature.show_add_to_contacts) {
    contactItems.push({
      icon: 'https://assets.tapt.io/email/plus.png',
      text: 'Add to contacts',
      link: addRef(profile.profile_page_url, 'email-signature-cta'),
      type: 'add-to-contacts',
      paddingTop: '3px',
    });
  }

  const validItems = contactItems.filter(
    item =>
      item.type === 'add-to-contacts' || (item.text && item.text.length > 0),
  );

  const rows = groupItemsIntoRows(validItems);

  return (
    <table cellSpacing="0" cellPadding="0">
      {rows.map((row, rowIndex) => (
        <tr key={rowIndex}>
          <td>
            <table cellSpacing="0" cellPadding="0">
              {row.map((item, itemIndex) => (
                <React.Fragment key={itemIndex}>
                  <td
                    style={{
                      width: '21px',
                      textAlign: 'left',
                      verticalAlign: 'middle',
                      paddingTop: item.paddingTop,
                    }}
                  >
                    <img
                      style={{ display: 'block' }}
                      width="11"
                      height="11"
                      alt={item.type}
                      src={item.icon}
                    />
                  </td>
                  <td style={styles.common}>
                    {item.link ? (
                      <a href={item.link} target="_blank" style={styles.link}>
                        {item.text}
                      </a>
                    ) : (
                      <span style={{ fontSize: '12px' }}>{item.text}</span>
                    )}
                  </td>
                  {itemIndex < row.length - 1 && (
                    <td width="18" style={styles.separator}>
                      |
                    </td>
                  )}
                </React.Fragment>
              ))}
            </table>
          </td>
        </tr>
      ))}
    </table>
  );
};
