import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ArrowRightIcon,
  ArrowTrendingUpIcon,
} from '@heroicons/react/24/outline';
import { differenceInCalendarDays, format } from 'date-fns';

import BaseModal from '@/components/Modals/ModalNoButton';
import useAppState, { SettingsTab } from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import { IPlatformSubscriptionUpgradePayment } from '@/types/IOrganisation';

interface UpgradeUpcomingProps {
  payment: IPlatformSubscriptionUpgradePayment;
}

const Banner = ({
  onOpenModal,
  payment,
}: {
  onOpenModal: () => void;
  payment: IPlatformSubscriptionUpgradePayment;
}) => {
  const daysUntilUpgrade = differenceInCalendarDays(
    new Date(),
    new Date(payment.date),
  );
  return (
    <div className="flex p-3 bg-brand-50 rounded-tl-2xl rounded-br-2xl rounded-tr-sm rounded-bl-sm space-x-3">
      <ArrowTrendingUpIcon
        className="w-6 h-6 text-brand-500 mt-2"
        strokeWidth={2}
      />
      <div className="flex flex-col">
        <div className="text-sm md:text-xs text-brand-900 font-medium pt-2 pb-2">
          {Math.abs(daysUntilUpgrade)} Days until platform fee upgrade due.
        </div>
        <button
          className="flex text-sm md:text-xs items-center font-medium text-brand-500 hover:text-brand-700"
          onClick={e => {
            e.preventDefault();
            onOpenModal();
          }}
        >
          Read more&nbsp;
          <ArrowRightIcon className="w-4 h-4" strokeWidth={2} />
        </button>
      </div>
    </div>
  );
};

const Modal = ({
  open,
  payment,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  payment: IPlatformSubscriptionUpgradePayment;
}) => {
  const { totalProfiles } = useAuth();

  const limitedAccessDate = format(
    new Date(payment.grace_period_end_date),
    'dd/MM/yy',
  );

  return (
    <BaseModal
      isOpen={open}
      setIsOpen={setOpen}
      dialogTitle="Your profile limit has been exceeded"
    >
      <div className="mt-4 space-y-4 text-gray-600">
        <p>
          <span className="font-medium">Current Total Profiles:</span>{' '}
          {totalProfiles} profiles
        </p>
        <p>
          <span className="font-medium">Profile Tier Paid For:</span>{' '}
          {payment.existing_tier.name}
        </p>
        <p className="text-gray-600 text-sm">
          To continue using all profiles, an upgrade fee is required for the
          remainder of the year. If payment is not made by {limitedAccessDate},
          your account will have limited access until the balance is settled.
        </p>
      </div>
    </BaseModal>
  );
};

function UpgradeUpcoming({ payment }: UpgradeUpcomingProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Banner payment={payment} onOpenModal={() => setIsModalOpen(true)} />
      <Modal open={isModalOpen} setOpen={setIsModalOpen} payment={payment} />
    </>
  );
}

export default UpgradeUpcoming;
