import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useBlocker, useNavigate } from 'react-router-dom';

import { EyeIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useWindowSize } from 'usehooks-ts';
import { z } from 'zod';

import organisationsAPI from '@/api/organisations';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import ColorPicker from '@/components/ColorPicker';
import { InfoPanelContainer } from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import {
  ProfilePreview,
  ProfilePreviewModal,
} from '@/components/ProfilePreview';
import { UnsavedChangesModal } from '@/components/UnsavedChangesPrompt';
import UploadPhoto from '@/components/UploadPhoto';
import COLORS from '@/constants/colors';
import { COVER_IMAGE } from '@/constants/files';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import { FileSchema } from '@/types/IFile';
import {
  IBrandFetchOrganisation,
  IOrganisationSettings,
} from '@/types/IOrganisation';

import { DesignWithAI } from '../DesignWithAI';

const profileDesignSchema = z.object({
  bg_color: z.string(),
  text_color: z.string(),
  button_bg_color: z.string(),
  button_text_color: z.string(),
  company_logo_file: FileSchema.nullable(),
});

type ProfileDesignForm = z.infer<typeof profileDesignSchema>;

export function ProfileDesign() {
  const { orgID } = useAuth();
  const navigate = useNavigate();

  const {
    reset,
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = useForm<ProfileDesignForm>({
    // some orgs can have null colors
    defaultValues: {
      bg_color: COLORS.organisation.defaultProfile.backgroundColor,
      text_color: COLORS.organisation.defaultProfile.textColor,
      button_bg_color: COLORS.organisation.defaultProfile.buttonBackgroundColor,
      button_text_color: COLORS.organisation.defaultProfile.buttonTextColor,
    },
    resolver: zodResolver(profileDesignSchema),
  });

  const { data: organisationSettings, isFetching: isFetchingOrgSettings } =
    useQuery({
      queryKey: ['showOrganisationSettings', orgID],
      queryFn: showOrganisationSettings,
      enabled: orgID !== undefined,
      refetchOnWindowFocus: false,
    });
  const {
    mutateAsync: saveOrganisationSettings,
    isError: isSaveOrganisationError,
  } = useMutation({
    mutationFn: updateOrganisationSettings,
  });

  const { width } = useWindowSize();
  const blocker = useBlocker(isDirty);

  const [isProfilePreviewOpen, setIsProfilePreviewOpen] = useState(false);

  const {
    bg_color: bgColor,
    text_color: textColor,
    button_bg_color: buttonBgColor,
    button_text_color: buttonTextColor,
    company_logo_file: companyLogoFile,
  } = watch();

  useEffect(() => {
    if (organisationSettings) {
      reset({
        bg_color:
          organisationSettings.bg_color ??
          COLORS.organisation.defaultProfile.backgroundColor,
        text_color:
          organisationSettings.text_color ??
          COLORS.organisation.defaultProfile.textColor,
        button_bg_color:
          organisationSettings.button_bg_color ??
          COLORS.organisation.defaultProfile.buttonBackgroundColor,
        button_text_color:
          organisationSettings.button_text_color ??
          COLORS.organisation.defaultProfile.buttonTextColor,
        company_logo_file: organisationSettings.company_logo,
      });
    }
  }, [organisationSettings]);

  useEffect(() => {
    if (blocker.state === 'blocked' && isSubmitSuccessful) {
      blocker.proceed();
    }
  }, [blocker, isSubmitSuccessful]);

  function onSettingsFetched(data: IBrandFetchOrganisation) {
    setValue('company_logo_file', data.banner_image_file);
    setValue('bg_color', data.bg_color);
    setValue('text_color', data.text_color);
    setValue('button_bg_color', data.button_bg_color);
    setValue('button_text_color', data.button_text_color);
  }

  async function showOrganisationSettings() {
    if (orgID === undefined) {
      return;
    }

    const { data } = await organisationsAPI.showOrganisationSettings(orgID);

    return data.data;
  }

  async function onSubmit(data: ProfileDesignForm) {
    const payload = {
      bg_color: data.bg_color,
      text_color: data.text_color,
      button_bg_color: data.button_bg_color,
      button_text_color: data.button_text_color,
      company_logo_file_id: data.company_logo_file
        ? data.company_logo_file.id
        : null,
    };

    await saveOrganisationSettings(payload);
    reset(data, { keepValues: true });
  }

  async function updateOrganisationSettings(
    payload: Partial<IOrganisationSettings>,
  ) {
    if (orgID === undefined) {
      return;
    }

    await organisationsAPI.updateOrganisationSettings(orgID, {
      organisation_setting: payload,
    });
  }

  const colorPickers = [
    {
      title: 'Page background colour',
      color: bgColor,
      setColor: (color: string) =>
        setValue('bg_color', color, { shouldDirty: true }),
    },
    {
      title: 'Text colour',
      color: textColor,
      setColor: (color: string) =>
        setValue('text_color', color, { shouldDirty: true }),
    },
    {
      title: 'Button background colour',
      color: buttonBgColor,
      setColor: (color: string) =>
        setValue('button_bg_color', color, { shouldDirty: true }),
    },
    {
      title: 'Button text colour',
      color: buttonTextColor,
      setColor: (color: string) =>
        setValue('button_text_color', color, { shouldDirty: true }),
    },
  ];

  return (
    <div className="pt-8 pb-[33%] md:pb-[68px] space-y-4">
      {isSaveOrganisationError && (
        <ErrorAlert message={MESSAGES.error.generic} />
      )}
      {isSubmitSuccessful && (
        <SuccessAlert message={MESSAGES.organisation.settings} />
      )}
      <div className="flex flex-col-reverse xl:flex-row gap-x-8">
        <InfoPanelContainer className="flex-1">
          <DesignWithAI onSuccessCallback={onSettingsFetched} />

          <InfoPanelDivider />
          <div className="space-y-5">
            <div className="flex items-center">
              {isFetchingOrgSettings ? (
                <div className="w-full mb-6">
                  <Skeleton height={175} />
                </div>
              ) : (
                <UploadPhoto
                  title="Cover image"
                  photo={companyLogoFile || undefined}
                  setPhoto={photo =>
                    setValue('company_logo_file', photo || null, {
                      shouldDirty: true,
                    })
                  }
                  size="large"
                  aspectRatio={16 / 11}
                  fileFormatMessage="Recommended dimensions 1024px x 704px"
                  maxHeight={COVER_IMAGE.MAX_HEIGHT}
                  maxWidth={COVER_IMAGE.MAX_WIDTH}
                />
              )}
            </div>
            <div className="space-y-3">
              <span className="text-xl font-medium text-gray-900">Colours</span>
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 w-full max-w-2xl">
                {colorPickers.map((picker, index) => (
                  <div key={index} className="space-y-3">
                    <span className="block text-sm leading-5 font-medium text-gray-900">
                      {picker.title}
                    </span>
                    {isFetchingOrgSettings ? (
                      <Skeleton width={190} height={42} />
                    ) : (
                      <ColorPicker
                        color={picker.color}
                        setColor={picker.setColor}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </InfoPanelContainer>
        <div
          className="hidden xl:block w-full max-w-[352px] md:max-w-[388px] p-4 rounded-md bg-white shadow-[0_0_0_1px_theme(colors.gray.300)]"
          style={{
            zoom: 0.8,
          }}
        >
          <ProfilePreview
            settings={{
              bgColor,
              textColor,
              buttonBgColor,
              buttonTextColor,
              companyLogo: companyLogoFile ?? null,
            }}
          />
        </div>
      </div>
      <InfoPanelFooter>
        <div className="flex xl:block justify-between space-x-4">
          {width < 1320 && (
            <>
              <Button
                buttonText="Preview"
                icon={<EyeIcon />}
                kind={BUTTON_KIND.WHITE}
                onClick={() => setIsProfilePreviewOpen(true)}
              />
              <ProfilePreviewModal
                isOpen={isProfilePreviewOpen}
                setIsOpen={setIsProfilePreviewOpen}
                settings={{
                  bgColor,
                  textColor,
                  buttonBgColor,
                  buttonTextColor,
                  companyLogo: companyLogoFile ?? null,
                }}
              />
            </>
          )}
          <div className="flex justify-end space-x-4">
            <Button
              buttonText="Cancel"
              kind={BUTTON_KIND.WHITE}
              onClick={() => navigate(-1)}
            />
            <Button
              buttonText="Save changes"
              onClick={handleSubmit(onSubmit)}
              loading={isSubmitting}
            />
          </div>
        </div>
      </InfoPanelFooter>
      {blocker.state === 'blocked' && (
        <UnsavedChangesModal
          proceed={async () => {
            await handleSubmit(onSubmit);
            blocker.proceed();
          }}
          cancel={blocker.proceed}
          reset={blocker.reset}
          isLoading={isSubmitting}
        />
      )}
    </div>
  );
}
