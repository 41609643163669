import { useEffect, useState } from 'react';

import {
  ExclamationTriangleIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';

import accountsAPI from '@/api/accounts';
import Button, { BUTTON_KIND } from '@/components/Button';
import Modal from '@/components/Modals/Modal';
import FreePlanExistingOrgDetail from '@/components/SettingsPage/ManagePlan/FreePlanExistingOrgDetail';
import InactivePlanDetail from '@/components/SettingsPage/ManagePlan/InactivePlanDetail';
import PlanDetail from '@/components/SettingsPage/ManagePlan/PlanDetail';
import PrepaidPlanDetail from '@/components/SettingsPage/ManagePlan/PrepaidPlanDetail';
import {
  generateCheckoutBaseUrl,
  getTierByTotalProfiles,
} from '@/helpers/sealSubscriptions';
import useAuth from '@/hooks/useAuth';
import {
  IPlatformSubscriptionFull,
  SubscriptionTypes,
} from '@/types/IOrganisation';
import SubscriptionStatus from '@/types/SubscriptionStatus';

interface ManagePlanTabProps {
  organisationId: number | undefined;
  isPlanRequiredModalOpen: boolean;
  setIsPlanRequiredModalOpen: (value: boolean) => void;
  checkoutUrl: string;
  setCheckoutUrl: (value: string) => void;
}

export default function ManagePlanTab(props: ManagePlanTabProps) {
  const {
    organisationId,
    isPlanRequiredModalOpen,
    setIsPlanRequiredModalOpen,
    checkoutUrl,
    setCheckoutUrl,
  } = props;

  const { user, platformSubscriptionStatus } = useAuth();
  const hasFreePlanForExistingOrg =
    platformSubscriptionStatus === SubscriptionStatus.FREE;
  const hasInactivePlan =
    platformSubscriptionStatus === SubscriptionStatus.INACTIVE;

  const [subscription, setSubscription] =
    useState<IPlatformSubscriptionFull | null>(null);
  const [totalProfiles, setTotalProfiles] = useState(0);

  useEffect(() => {
    if (!organisationId) return;

    if (!hasFreePlanForExistingOrg) {
      accountsAPI.getSubscription(organisationId).then(response => {
        setSubscription(response.data.subscription);
        setTotalProfiles(response.data.total_profiles);

        if (response.data.subscription === null) {
          setIsPlanRequiredModalOpen(true);
          setCheckoutUrl(generateCheckoutUrl(response.data.total_profiles));
        }
      });
    }
  }, [organisationId, hasFreePlanForExistingOrg]);

  const generateCheckoutUrl = (totalProfiles: number) => {
    const tier = getTierByTotalProfiles(totalProfiles);
    if (!tier || !user) return '';

    const attributes = {
      'checkout[email]': user.email,
      'checkout[billing_address][first_name]': user.first_name,
      'checkout[billing_address][last_name]': user.last_name,
    };

    return generateCheckoutBaseUrl(tier.variant_id, attributes);
  };

  return (
    <div className="mt-9 max-md:max-w-full">
      {subscription && subscription.type !== SubscriptionTypes.PREPAID && (
        <PlanDetail
          organisationId={organisationId}
          subscription={subscription}
        />
      )}
      {subscription && subscription.type === SubscriptionTypes.PREPAID && (
        <PrepaidPlanDetail />
      )}

      {hasFreePlanForExistingOrg && <FreePlanExistingOrgDetail />}
      {!subscription && hasInactivePlan && (
        <InactivePlanDetail totalProfiles={totalProfiles} />
      )}
      <Modal
        isOpen={isPlanRequiredModalOpen}
        onClose={() => setIsPlanRequiredModalOpen(false)}
        cancelButtonText={'Cancel'}
        onCancel={() => setIsPlanRequiredModalOpen(false)}
        successButtonText={'Join now!'}
        successButton={
          <Button
            icon={<SparklesIcon />}
            kind={BUTTON_KIND.PRIMARY}
            buttonText={'Upgrade Now'}
            external
            externalBlankTarget
            href={checkoutUrl}
          />
        }
        cancelButton={
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText={'Support'}
            external
            externalBlankTarget
            href={'https://help.tapt.io'}
          />
        }
        onSuccess={() => setIsPlanRequiredModalOpen(false)}
      >
        <div className="text-2xl not-italic leading-8 text-red-500 flex items-center space-x-2 max-md:max-w-full">
          <ExclamationTriangleIcon className="h-5 w-5" />{' '}
          <span className="font-medium">Subscription Required</span>
        </div>
        <div className="mt-3 text-sm not-italic leading-5 text-gray-600 max-md:max-w-full">
          You don’t have an active platform access plan on your account. To
          access the dashboard, start a plan by clicking Upgrade Now below. If
          this is an error, please contact support.
        </div>
      </Modal>
    </div>
  );
}
