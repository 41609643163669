import { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';

import filesAPI from '@/api/files';
import organisationsAPI from '@/api/organisations';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import { InfoPanelContainer } from '@/components/InfoPanelContainer';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import ToggleField from '@/components/ToggleField';
import ToggleFieldWithInfo from '@/components/ToggleFieldWithInfo';
import { UnsavedChangesModal } from '@/components/UnsavedChangesPrompt';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import IFile from '@/types/IFile';

export function ContactExchange() {
  // cannot use form libraries or react query due to legacy components
  const { orgID } = useAuth();

  const [isDirty, setIsDirty] = useState(false);
  const blocker = useBlocker(isDirty);

  const [nameToggle, setNameToggle] = useState(true);
  const [emailToggle, setEmailToggle] = useState(false);
  const [mobileNumberToggle, setMobileNumberToggle] = useState(false);
  const [jobTitleToggle, setJobTitleToggle] = useState(false);
  const [companyNameToggle, setCompanyNameToggle] = useState(false);
  const [consentStatementToggle, setConsentStatementToggle] = useState(false);

  const [consentStatement, setConsentStatement] = useState<IFile | undefined>(
    undefined,
  );
  const [isConsentLoading, setIsConsentLoading] = useState(false);
  const [consentFileError, setConsentFileError] = useState(false);
  const [organisationError, setOrganisationError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    getOrganisationSettings();
  }, []);

  const makeFormDirty = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  async function getOrganisationSettings() {
    if (orgID === undefined) {
      return;
    }

    try {
      const {
        data: { data: organisation },
      } = await organisationsAPI.showOrganisationSettings(orgID);

      setNameToggle(organisation.contact_exchange.name);
      setEmailToggle(organisation.contact_exchange.email);
      setMobileNumberToggle(organisation.contact_exchange.mobile_number);
      setJobTitleToggle(organisation.contact_exchange.job_title);
      setCompanyNameToggle(organisation.contact_exchange.company_name);
      setConsentStatementToggle(organisation.contact_exchange.company_consent);
      setConsentStatement(organisation.company_consent_file);
    } catch {
      setOrganisationError(true);
    }
  }

  async function handleOrganisationSettingsSave() {
    if (orgID === undefined) {
      return;
    }

    try {
      setIsSaving(true);
      setSuccessMessage(undefined);
      setOrganisationError(false);

      await organisationsAPI.updateOrganisationSettings(orgID, {
        organisation_setting: {
          contact_exchange: {
            company_name: companyNameToggle,
            email: emailToggle,
            job_title: jobTitleToggle,
            mobile_number: mobileNumberToggle,
            name: nameToggle,
            company_consent: consentStatementToggle,
          },
          company_consent_file: consentStatement,
          company_consent_file_id: consentStatement
            ? consentStatement.id
            : null,
        },
      });

      setIsDirty(false);
      setSuccessMessage(MESSAGES.organisation.template);
    } catch {
      setOrganisationError(true);
    } finally {
      setIsSaving(false);
    }
  }

  const contactExchangeToggles = [
    {
      name: 'Full name',
      enabled: nameToggle,
      setter: setNameToggle,
      disabled: true,
      message: 'Mandatory field',
    },
    {
      name: 'Email',
      enabled: emailToggle,
      setter: setEmailToggle,
    },
    {
      name: 'Mobile number',
      enabled: mobileNumberToggle,
      setter: setMobileNumberToggle,
    },
    {
      name: 'Job Title',
      enabled: jobTitleToggle,
      setter: setJobTitleToggle,
    },
    {
      name: 'Company Name',
      enabled: companyNameToggle,
      setter: setCompanyNameToggle,
    },
  ];

  return (
    <div className="pt-8 pb-16 md:pb-0" onFocusCapture={makeFormDirty}>
      {successMessage && (
        <div className="pb-8">
          <SuccessAlert message={successMessage} />
        </div>
      )}
      <InfoPanelContainer
        title="Contact exchange form settings"
        description={MESSAGES.contact.exchange.description}
      >
        {organisationError && (
          <ErrorAlert message="Something went wrong. Please try again later" />
        )}
        <div className="grid grid-cols-1 gap-5">
          {contactExchangeToggles.map((field, index) => (
            <ToggleField
              key={index}
              label={field.name}
              enabled={field.enabled}
              setter={field.setter}
              disabled={field.disabled}
              message={field.message}
            />
          ))}
        </div>
        <div className="grid grid-cols-1 gap-5 mt-5">
          {!consentStatement && (
            <ToggleFieldWithInfo
              className="text-gray-600 text-sm font-normal leading-[150%]"
              label="Consent Statement"
              enabled={consentStatementToggle}
              setter={setConsentStatementToggle}
              disabled
              modalTitle="Consent Statement"
              modalDescription="If your company has data policies they would like future contacts to consent to, add the policy document here. We will ask contacts to approve the policies before they send their details back to you!"
            />
          )}
          {consentStatement && (
            <ToggleFieldWithInfo
              label="Consent Statement"
              enabled={consentStatementToggle}
              setter={setConsentStatementToggle}
              modalTitle="Consent Statement"
              modalDescription="If your company has data policies they would like future contacts to consent to, add the policy document here. We will ask contacts to approve the policies before they send their details back to you!"
            />
          )}
        </div>
        <div className="flex mt-0 pt-0">
          {orgID && (
            <FileUploadButtonComponent
              id="consent-statement-upload"
              filename={consentStatement?.file?.file_name}
              url={consentStatement?.original_url}
              onFileSelected={file => {
                setIsConsentLoading(true);
                filesAPI
                  .createAndUploadFile(orgID, new File([file], file.name))

                  .then(res => {
                    if (res?.data?.data.id) {
                      setConsentStatement(res.data.data);
                      setConsentStatementToggle(true);
                    }
                  })
                  .catch(() => setConsentFileError(true))
                  .finally(() => setIsConsentLoading(false));
              }}
              buttonTitle="Upload consent statement"
              fileFormat=".pdf, .jpeg, .png"
              fileFormatMessage="(.jpeg, .png or .pdf only)"
              loading={isConsentLoading}
              fileError={consentFileError}
            />
          )}
          {consentStatement?.file?.file_name && (
            <button
              type="button"
              onClick={() => {
                setConsentStatement(undefined);
                setConsentStatementToggle(false);
              }}
              className="appearance-none ml-2 text-sm leading-5 text-brand-500"
            >
              Remove
            </button>
          )}
        </div>
      </InfoPanelContainer>
      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="Cancel"
            className="flex-1 xl:flex-none"
            kind={BUTTON_KIND.WHITE}
            onClick={window.location.reload}
          />
          <Button
            buttonText="Save changes"
            className="flex-1 xl:flex-none"
            loading={isSaving}
            onClick={handleOrganisationSettingsSave}
          />
        </div>
      </InfoPanelFooter>
      {blocker.state === 'blocked' && (
        <UnsavedChangesModal
          proceed={async () => {
            await handleOrganisationSettingsSave();
            blocker.proceed();
          }}
          reset={blocker.reset}
          cancel={blocker.proceed}
          isLoading={isSaving}
        />
      )}
    </div>
  );
}
