import React from 'react';

interface BannerProps {
  imgSrc: string;
  sampleMode: boolean;
}

const DISPLAY_SIZE = { width: 262, height: 101 };

export const Banner: React.FC<BannerProps> = ({
  imgSrc,
  sampleMode = false,
}) => {
  return (
    <td
      style={{
        paddingRight: '16px',
        width: `${DISPLAY_SIZE.width}px`,
        verticalAlign: 'top',
      }}
    >
      <img
        src={imgSrc}
        alt="Banner"
        style={{
          display: 'block',
          height: `${DISPLAY_SIZE.height}px`,
          width: `${DISPLAY_SIZE.width}px`,
          borderRadius: sampleMode ? '15px' : '0',
        }}
        height={DISPLAY_SIZE.height}
        width={DISPLAY_SIZE.width}
      />{' '}
    </td>
  );
};
