import { z } from 'zod';

export function validateEmail(email: string | null) {
  const result = z.string().email().safeParse(email);
  return result.success;
}

export function validateUrl(candidateUrl: string) {
  const protocolRegex = /^[a-zA-Z]+:\/\//;
  if (!protocolRegex.test(candidateUrl)) {
    candidateUrl = `https://${candidateUrl}`;
  }

  try {
    new URL(candidateUrl);
    return true;
  } catch {
    return false;
  }
}

/*
Requires password to have:
  - lowercase letter
  - capital letter
  - a number
  - minimum eight characters
*/
export function validateComplexPassword(password: string) {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  return re.test(String(password));
}

const HASH_REGEX = /^[a-zA-Z0-9]{20}$/;

export function isValidHashId(hashId: string) {
  return HASH_REGEX.test(hashId);
}
