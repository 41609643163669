import { useState } from 'react';

import {
  ArrowRightIcon,
  ArrowTrendingUpIcon,
} from '@heroicons/react/24/outline';
import { differenceInCalendarDays, format } from 'date-fns';

import accountsAPI from '@/api/accounts';
import BaseModal from '@/components/Modals/Modal';
import ModalNoCancel from '@/components/Modals/ModalNoCancel';
import useAuth from '@/hooks/useAuth';
import { IPlatformSubscriptionUpgradePayment } from '@/types/IOrganisation';

const Banner = ({
  onOpenModal,
  payment,
}: {
  onOpenModal: () => void;
  payment: IPlatformSubscriptionUpgradePayment;
}) => {
  const daysLeftBeforeAccessLimit = differenceInCalendarDays(
    new Date(),
    new Date(payment.grace_period_end_date),
  );
  const message =
    daysLeftBeforeAccessLimit > 0
      ? 'Access is revoked for unpaid profiles.'
      : `${Math.abs(daysLeftBeforeAccessLimit)} Days until access is limited.`;

  return (
    <div className="flex p-3 bg-red-50 rounded-tl-2xl rounded-br-2xl rounded-tr-sm rounded-bl-sm space-x-3">
      <ArrowTrendingUpIcon className="w-6 h-6 text-red-400" strokeWidth={2} />
      <div className="flex flex-col">
        <div className="text-sm md:text-xs font-medium text-red-800">
          Platform fee overdue.
        </div>
        <div className="text-sm md:text-xs text-red-800 pt-2 pb-2">
          {message}
        </div>
        <button
          className="flex text-sm md:text-xs items-center text-red-400 hover:text-red-500"
          onClick={e => {
            e.preventDefault();
            onOpenModal();
          }}
        >
          Read more&nbsp;
          <ArrowRightIcon className="w-4 h-4" strokeWidth={2} />
        </button>
      </div>
    </div>
  );
};

const Modal = ({
  open,
  setOpen,
  onSuccess,
  payment,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
  payment: IPlatformSubscriptionUpgradePayment;
}) => {
  const { orgID, totalProfiles } = useAuth();
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [
    isPaymentMethodEmailSentModalOpen,
    setIsPaymentMethodEmailSentModalOpen,
  ] = useState(false);
  const unpaidProfiles = totalProfiles - payment.existing_tier.max_profiles;
  const tierName = payment.existing_tier.name;
  const daysLeftBeforeAccessLimit = differenceInCalendarDays(
    new Date(),
    new Date(payment.grace_period_end_date),
  );
  const hasPaymentError = payment.error_code && payment.error_code !== '';
  const limitedAccessDate = format(
    new Date(payment.grace_period_end_date),
    'dd/MM/yy',
  );

  const handleEditPaymentMethod = () => {
    if (!orgID) return;

    setIsSendingEmail(true);
    accountsAPI
      .sendPaymentMethodUpdateEmail(orgID)
      .then(() => {
        setIsPaymentMethodEmailSentModalOpen(true);
        setOpen(false);
      })
      .finally(() => setIsSendingEmail(false));
  };

  return (
    <>
      <BaseModal
        isOpen={open}
        onSuccess={hasPaymentError ? handleEditPaymentMethod : onSuccess}
        onCancel={() => setOpen(false)}
        dialogTitle="Your profile limit has been exceeded"
        cancelButtonText="Close"
        successButtonText={hasPaymentError ? 'Update Payment' : 'Upgrade Now'}
        successButtonHref={payment.invoice_url ?? undefined}
        isLoading={isSendingEmail}
      >
        <div className="mt-4 space-y-4 text-gray-600">
          <p>
            <span className="font-medium">Current Total Profiles:</span>{' '}
            {totalProfiles} profiles
          </p>
          <p>
            <span className="font-medium">Profile Tier Paid For:</span>{' '}
            {payment.existing_tier.name}
          </p>
          {daysLeftBeforeAccessLimit <= 0 && hasPaymentError && (
            <>
              <p className="text-gray-600 text-sm">
                We attempted to charge your credit card, but the payment failed.
                To continue using all profiles, please update your payment
                details.
              </p>
              <p className="text-gray-600 text-sm">
                If payment is not made by {limitedAccessDate}, your account will
                have limited access until the balance is settled. Full access
                will be restored once the pro-rata payment is processed.
              </p>
            </>
          )}

          {daysLeftBeforeAccessLimit <= 0 && !hasPaymentError && (
            <p className="text-gray-600 text-sm">
              To continue using all profiles, an upgrade fee is required for the
              remainder of the year. If payment is not made by{' '}
              {limitedAccessDate}, your account will have limited access until
              the balance is settled. No profiles will be deactivated, and
              access will be fully restored once the pro-rata payment is made.
            </p>
          )}

          {daysLeftBeforeAccessLimit > 0 && (
            <p className="text-gray-600 text-sm">
              Your account has limited access due to an unpaid pro-rata upgrade
              fee. To restore full access, please make a payment below{' '}
              {hasPaymentError ? '.' : 'or download an invoice.'}
            </p>
          )}

          {payment.invoice_download_url && (
            <p className="text-sm">
              Prefer an invoice?{' '}
              <a
                href={payment.invoice_download_url}
                target="_blank"
                rel="noreferrer"
              >
                Download here
              </a>
            </p>
          )}
        </div>
      </BaseModal>
      {hasPaymentError && (
        <ModalNoCancel
          isOpen={isPaymentMethodEmailSentModalOpen}
          setIsOpen={setIsPaymentMethodEmailSentModalOpen}
          successButtonText={'OK'}
          onSuccess={() => setIsPaymentMethodEmailSentModalOpen(false)}
          dialogTitle={'Email sent!'}
          dialogDescription={
            'We have sent you an email with a secure link to the page where you can update your payment details.'
          }
        />
      )}
    </>
  );
};

interface UpgradeOverdueProps {
  payment: IPlatformSubscriptionUpgradePayment;
}

function UpgradeOverdue({ payment }: UpgradeOverdueProps) {
  const [isOverdueModalOpen, setIsOverdueModalOpen] = useState(false);

  return (
    <>
      <Banner
        payment={payment}
        onOpenModal={() => setIsOverdueModalOpen(true)}
      />
      <Modal
        open={isOverdueModalOpen}
        setOpen={setIsOverdueModalOpen}
        onSuccess={() => setIsOverdueModalOpen(false)}
        payment={payment}
      />
    </>
  );
}

export default UpgradeOverdue;
