import { useCallback, useState } from 'react';
import Iframe from 'react-iframe';
import { useNavigate } from 'react-router-dom';

import { css, cx } from '@emotion/css';

import adminAPI from '@/api/admin';
import Button, { BUTTON_KIND } from '@/components/Button';
import { InfoPanelContainer } from '@/components/InfoPanelContainer';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Layout from '@/components/Layout';
import PersonalInfoForm from '@/containers/PersonalInfoForm';
import { buildNewUrl } from '@/helpers/urlHandler';
import IFile from '@/types/IFile';

export default function ProfileGeneratorPage() {
  const navigate = useNavigate();
  const [photo, setPhoto] = useState<IFile>();
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pronouns, setPronouns] = useState('');
  const [email, setEmail] = useState('');
  const [extraEmails, setExtraEmails] = useState<string[]>([]);
  const [mobileNumber, setMobileNumber] = useState('');
  const [extraMobileNumbers, setExtraMobileNumbers] = useState<string[]>([]);
  const [jobTitle, setJobTitle] = useState('');
  const [note, setNote] = useState('');
  const [previewPageUrl, setPreviewPageUrl] = useState('');

  const desktopPreviewHeight = '787px';
  const desktopPreviewWidth = '375px';

  const phoneStyles = css`
    // TODO: Improvement - replace media query value with Tailwind config reference
    @media (min-width: 768px) {
      background: #e5e7eb;
      border-radius: 40px;
      width: ${desktopPreviewWidth};
      max-height: ${desktopPreviewHeight};
    }
  `;
  const innerPhoneStyles = css`
    // TODO: Improvement - replace media query value with Tailwind config reference
    @media (min-width: 768px) {
      border-radius: 33px;
    }
  `;

  const showSampleProfile = useCallback(() => {
    adminAPI.getBrandSettings(getDomain(email)).then(res => {
      const brand = res.data;
      const queryParams = {
        bg_color: brand.settings.bg_color,
        text_color: brand.settings.text_color,
        button_bg_color: brand.settings.button_bg_color,
        button_text_color: brand.settings.button_text_color,
        company_logo_json: JSON.stringify(brand.settings.company_logo),
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        pronouns: pronouns,
        job_title: jobTitle,
        email: email,
        mobile_number: mobileNumber,
        company_name: brand.company_name,
        company_website: brand.company_website,
        note: brand.note,
        profile_social_links_json: JSON.stringify(brand.profile_social_links),
      };
      const urlParams = buildNewUrl({
        profile_generator_params_base64: window.btoa(
          JSON.stringify(queryParams),
        ),
      });

      setPreviewPageUrl(`/view-profile-sample${urlParams}`);
    });
  }, [navigate, email]);

  const hasDomain = () => {
    if (!email) return false;

    return getDomain(email);
  };

  const getDomain = (email: string) => {
    const emailParts = email.split('@');
    return emailParts[1];
  };

  return (
    <Layout pageName="Profile Generator">
      <InfoPanelContainer title="Personal Information">
        <PersonalInfoForm
          photo={photo}
          firstName={firstName}
          middleName={middleName}
          lastName={lastName}
          pronouns={pronouns}
          email={email}
          extraEmails={extraEmails}
          mobileNumber={mobileNumber}
          extraMobileNumbers={extraMobileNumbers}
          jobTitle={jobTitle}
          note={note}
          setPhoto={setPhoto}
          setFirstName={setFirstName}
          setMiddleName={setMiddleName}
          setLastName={setLastName}
          setPronouns={setPronouns}
          setEmail={setEmail}
          setExtraEmails={setExtraEmails}
          setMobileNumber={setMobileNumber}
          setExtraMobileNumbers={setExtraMobileNumbers}
          setJobTitle={setJobTitle}
          setNote={setNote}
        />
      </InfoPanelContainer>

      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="View Sample Profile"
            onClick={showSampleProfile}
            disabled={!hasDomain()}
          />
        </div>
      </InfoPanelFooter>

      <div className="flex flex-col h-full w-full">
        <div className="w-full mx-auto px-4 sm:px-4 lg:px-6 py-4 border-b bg-white md:mb-4">
          <Button
            buttonText="Exit Preview"
            kind={BUTTON_KIND.WHITE}
            onClick={() => setPreviewPageUrl('')}
          />
        </div>

        {previewPageUrl && (
          <div
            className={cx(
              phoneStyles,
              'w-full h-full inline-flex md:p-px md:shadow-2xl flex-grow mx-auto',
            )}
          >
            <Iframe
              className={cx(
                innerPhoneStyles,
                'w-full h-full pointer-events-none',
              )}
              display="block"
              position="relative"
              url={previewPageUrl}
            />
          </div>
        )}
      </div>
    </Layout>
  );
}
