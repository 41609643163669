import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';

import profilesAPI from '@/api/profiles';
import Button, { BUTTON_KIND } from '@/components/Button';
import { InfoPanelContainer } from '@/components/InfoPanelContainer';
import ToggleField from '@/components/ToggleField';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';

import ClearProfileWarningModal from './ClearProfileWarningModal';
import DeleteProfileWarningModal from './DeleteProfileWarningModal';
import { ContactExchangeForm, WorkingProfileForm } from './schema';

export function ContactExchange() {
  const { watch, setValue } = useFormContext<ContactExchangeForm>();

  const isContactExchangeEnabled = watch('contact_exchange_enabled');

  return (
    <InfoPanelContainer
      title="Contact exchange form"
      description={MESSAGES.profile.edit.contact}
      dialog={<ContactExchangeFormAlert />}
    >
      <ToggleField
        label="Enable contact exchange form"
        enabled={isContactExchangeEnabled || false}
        setter={enabled => setValue('contact_exchange_enabled', enabled)}
        description="Choose whether you would like to receive contact details from people."
      />
    </InfoPanelContainer>
  );
}

function ContactExchangeFormAlert() {
  return (
    <div className="space-y-2">
      <h3 className="text-sm leading-5 font-medium text-gray-900">
        {MESSAGES.contact.trial.heading}
      </h3>
      <p className="text-sm leading-5 text-gray-500">
        {MESSAGES.contact.trial.description}
      </p>
      <a
        href="https://help.tapt.io/en/articles/8449601-editing-and-enabling-two-way-contact-exchange-form"
        target="_blank"
        rel="noreferrer"
        className="text-brand-500 flex w-full space-x-2"
      >
        <span className="font-medium leading-5 text-sm">Learn more</span>
        <ArrowRightIcon className="w-5 h-5" />
      </a>
    </div>
  );
}

export function ClearProfile() {
  const { orgID } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [, , profileID] = location.pathname.split('/');

  const [isClearProfileWarningOpen, setIsClearProfileWarningOpen] =
    useState(false);

  const { mutateAsync: clearProfile, isPending: isClearing } = useMutation({
    mutationFn: resetProfile,
  });

  async function resetProfile() {
    if (orgID === undefined) {
      return;
    }

    const { data: profile } = await profilesAPI.resetProfile(
      orgID,
      parseInt(profileID),
    );

    navigate(`/edit-profile/${profile.data.id}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <InfoPanelContainer
      title="Clear profile"
      description={MESSAGES.profile.edit.reset}
      className="flex items-center justify-between"
      isRadio
    >
      <Button
        buttonText="Clear profile"
        kind={BUTTON_KIND.RED}
        onClick={() => setIsClearProfileWarningOpen(true)}
        loading={isClearing}
      />
      <ClearProfileWarningModal
        isOpen={isClearProfileWarningOpen}
        setIsOpen={setIsClearProfileWarningOpen}
        onSuccess={clearProfile}
        loading={isClearing}
      />
    </InfoPanelContainer>
  );
}

export function DeleteProfile() {
  const { orgID } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [, , profileID] = location.pathname.split('/');

  const { getValues } = useFormContext<WorkingProfileForm>();

  const [isDeleteProfileWarningOpen, setIsDeleteProfileWarningOpen] =
    useState(false);

  const { mutateAsync: removeProfile, isPending: isDeleting } = useMutation({
    mutationFn: deleteProfile,
    onSuccess: () => {
      navigate('/', {
        state: {
          success: MESSAGES.profile.delete.successWithName(
            `${getValues('first_name')} ${getValues('last_name')}`,
          ),
        },
      });
    },
  });

  async function deleteProfile() {
    if (orgID === undefined) {
      return;
    }

    await profilesAPI.deleteProfile(orgID, parseInt(profileID));
  }

  return (
    <InfoPanelContainer
      title="Delete profile"
      description={MESSAGES.profile.edit.delete}
      className="flex items-center justify-between"
      isRadio
    >
      <Button
        buttonText="Delete profile"
        kind={BUTTON_KIND.RED}
        onClick={() => setIsDeleteProfileWarningOpen(true)}
        loading={isDeleting}
      />
      <DeleteProfileWarningModal
        isOpen={isDeleteProfileWarningOpen}
        setIsOpen={setIsDeleteProfileWarningOpen}
        onSuccess={removeProfile}
        loading={isDeleting}
      />
    </InfoPanelContainer>
  );
}
