import { useEffect, useState } from 'react';
import { z } from 'zod';
import useAuth from './useAuth';

const RATE_LIMIT_KEY = 'design_with_ai_credits';
const LIMIT = 3;
const TIME_WINDOW = 24 * 60 * 60 * 1000;

const rateLimitSchema = z.object({
  attemptsLeft: z.number(),
  timestamps: z.array(z.number()),
});

type RateLimit = z.infer<typeof rateLimitSchema>;

const getStoredData = () => {
  const storedData = localStorage.getItem(RATE_LIMIT_KEY);
  const now = Date.now();

  if (storedData) {
    const parsed = JSON.parse(storedData);
    const validatedData = rateLimitSchema.safeParse(parsed);
    if (validatedData.success) {
      const validTimestamps = validatedData.data.timestamps.filter(
        t => now - t < TIME_WINDOW,
      );

      return {
        attemptsLeft: LIMIT - validTimestamps.length,
        timestamps: validTimestamps,
      };
    }
  }

  const initialData: RateLimit = { attemptsLeft: LIMIT, timestamps: [] };
  localStorage.setItem(RATE_LIMIT_KEY, JSON.stringify(initialData));
  return initialData;
};

/**
 * Rate limiter for design with AI.
 * Limited to 3 attempts per 24 hours
 */
export function useRateLimit() {
  const { adminRole } = useAuth();
  const [rateLimitData, setRateLimitData] = useState(getStoredData);

  useEffect(() => {
    localStorage.setItem(RATE_LIMIT_KEY, JSON.stringify(rateLimitData));
  }, [rateLimitData]);

  // Super users bypass rate limit
  const isSuperUser = adminRole === 'super';
  const canAttempt = isSuperUser || rateLimitData.attemptsLeft > 0;

  const useAttempt = () => {
    if (!canAttempt) {
      return false;
    }

    // Super users don't consume attempts
    if (isSuperUser) {
      return true; 
    }

    const now = Date.now();
    const updatedTimestamps = [...rateLimitData.timestamps, now].slice(-LIMIT);
    const updatedAttemptsLeft = Math.max(0, LIMIT - updatedTimestamps.length);

    const updatedData = {
      attemptsLeft: updatedAttemptsLeft,
      timestamps: updatedTimestamps,
    };

    setRateLimitData(updatedData);
    return true;
  };

  return {
    attemptsLeft: isSuperUser ? LIMIT : rateLimitData.attemptsLeft,
    canAttempt,
    useAttempt
  };
}
